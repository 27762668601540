import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const UserLogoutResponseStruct = s.boolean();
export type UserLogoutResponse = s.Infer<typeof UserLogoutResponseStruct>;

export type UserLogoutMethodParams = s.Infer<typeof ApiParamStructs.body>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function userLogout(params: UserLogoutMethodParams): APIRequest {
	return createAPIRequest(
		'get',
		uri`/api/user/logout`,
		{},
		ApiParamStructs
	);
}
