import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, NoticeShopEntityStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetShopResponseStruct = s.nullable(NoticeShopEntityStruct);
export type GetShopResponse = s.Infer<typeof GetShopResponseStruct>;
export type GetShopResponseValues = s.Infer<typeof NoticeShopEntityStruct>;

export type GetShopMethodParams = {
	shopId: string;
};

export function getShop(params: GetShopMethodParams): APIRequest {
	const { shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/notice/${shopId}/shop_entity`,
		{
			path: { shopId },
		},
		ApiParamStructs
	);
}
