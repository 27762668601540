import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: s.object({
		pass: s.string()
	})
};

export const PutDisableTwoFactorAuthResponseStruct = s.boolean();
export type PutDisableTwoFactorAuthResponse = s.Infer<typeof PutDisableTwoFactorAuthResponseStruct>;
export type PutDisableTwoFactorAuthResponseValues = s.Infer<typeof UserStruct>;

export type PutDisableTwoFactorAuthMethodParams = {
	pass: string
};

export function PutDisableTwoFactorAuth(params: PutDisableTwoFactorAuthMethodParams): APIRequest {
	const { ...body } = params;
	return createAPIRequest(
		'put',
		uri`/api/user/two-factor-auth/disable`,
		{
			body: body
		},
		ApiParamStructs
	);
}
