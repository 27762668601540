import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: s.object({
		code: s.string()
	})
};

export const PutTwoFactorAuthResponseStruct = s.boolean();
export type PutTwoFactorAuthResponse = s.Infer<typeof PutTwoFactorAuthResponseStruct>;
export type PutTwoFactorAuthResponseValues = s.Infer<typeof UserStruct>;

export type PutTwoFactorAuthMethodParams = {
	code: string
};

export function putTwoFactorAuth(params: PutTwoFactorAuthMethodParams): APIRequest {
	const { ...body } = params;
	return createAPIRequest(
		'put',
		uri`/api/user/two-factor-auth`,
		{
			body: body
		},
		ApiParamStructs
	);
}
