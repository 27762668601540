import { entityIdMap } from "../../const/entity-id-map";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { BodyElement, bodyElementValidator } from "./BodyElement";

export const mailCategory = ['review_request','review_completed','application_completed'] as const;
type MailCategory = typeof mailCategory[number];
function mailCategoryValidator(val: string): val is MailCategory {
	return (mailCategory as readonly string[]).includes(val);
}

export class MailTemplate extends Entity {
	static typeId = entityIdMap['review.template'];

	name!: string;
	category!: MailCategory;
	body!: BodyElement[];
	signature!: string;
	isFavorite!: boolean;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: true, type: 'string' },
			category: { isMandatory: true, type: 'string', validator: mailCategoryValidator },
			body: { isMandatory: true, isArray: true, validator: bodyElementValidator },
			signature: { isMandatory: true, type: 'string' },
			isFavorite: { isMandatory: true, type: 'boolean' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			category: this.category,
			body: this.body,
			signature: this.signature,
			isFavorite: this.isFavorite,
		});
	}
}
