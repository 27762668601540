import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string()
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetEntityUsersResponseStruct = s.array(UserStruct);
export type GetEntityUsersResponse = s.Infer<typeof GetEntityUsersResponseStruct>;
export type GetEntityUsersResponseValues = s.Infer<typeof UserStruct>;

export type GetEntityUsersMethodParams = {
	shopId: string;
};

export function getEntityUsers(params: GetEntityUsersMethodParams): APIRequest {
	const { shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/user/entity/${shopId}/user`,
		{
			path: { shopId },
		},
		ApiParamStructs
	);
}
