import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		userId: s.string()
	}),
	query: EmptyObjectStruct,
	body: s.object({
		capabilities: s.array(s.record(s.string(), s.any()))
	})
};

export const PutEntityUserCapabilityResponseStruct = s.boolean();
export type PutEntityUserCapabilityResponse = s.Infer<typeof PutEntityUserCapabilityResponseStruct>;

export type PutEntityUserCapabilityMethodParams = {
	shopId: string;
	userId: string;
	capabilities: Record<string, any>[];
};

export function putEntityUserCapability(params: PutEntityUserCapabilityMethodParams): APIRequest {
	const { shopId, userId, capabilities } = params;
	return createAPIRequest(
		'put',
		uri`/api/user/entity/${shopId}/user/${userId}/capability`,
		{
			path: { shopId, userId },
			body: { capabilities }
		},
		ApiParamStructs
	);
}
