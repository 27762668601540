import { entityIdMap } from "../../const/entity-id-map";
import { UUID } from "../../interface";
import isUUID from "../../isUUID";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class Execution extends Entity {
	static typeId = entityIdMap['cron.execution'];

	jobId?: UUID;
	startTime?: number;
	sourceId?: string;
	name?: string;
	service?: string;
	method?: string;
	args?: unknown[];

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			jobId: { validator: isUUID },
			startTime: { type: 'number' },
			sourceId: { type: 'string' },
			name: { type: 'string' },
			service: { type: 'string' },
			method: { type: 'string' },
			args: { isArray: true },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			jobId: this.jobId,
			startTime: this.startTime,
			sourceId: this.sourceId,
			name: this.name,
			service: this.service,
			method: this.method,
			args: this.args,
		});
	}
}
export type ExecutionAttr = Omit<ExcludeMethod<Execution>, 'typeId'>;
