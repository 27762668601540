import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, TokenStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		token: s.string()
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const ActivateTokenResponseStruct = s.nullable(s.array(TokenStruct));
export type ActivateTokenResponse = s.Infer<typeof ActivateTokenResponseStruct>;

export type ActivateTokenMethodParams = {
	token: string;
};

export function activateToken(params: ActivateTokenMethodParams): APIRequest {
	const { token } = params;
	return createAPIRequest(
		'post',
		uri`/api/user/capability/${token}`,
		{
			path: { token }
		},
		ApiParamStructs
	);
}
