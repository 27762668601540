import * as ss from 'superstruct';
import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const";
import { UUID } from "../../interface";
import isUUID from "../../isUUID";
import { ExcludeMethod, validator } from "../../lib";
import Entity from "../Entity";

export const mailStatusList = ['queued', 'suspended', 'requeued'] as const;
export type MailStatus = typeof mailStatusList[number];

const structMailStatus = ss.enums(mailStatusList);
const isMailStatus = validator(structMailStatus);

export class Mail extends Entity {
	static typeId = entityIdMap['mail.mail'];

	shopId!: UUID;
	/**
	 * queued: 送信待ち - 最初にキューに入った状態、特に問題が起こらなければ速やかに送信されてキューから削除される
	 * suspended: 停止 - ショップのメール設定に由来するエラーによって送信を停止している状態
	 * requeued: 停止後の送信待ち - `suspended`だったがメール設定変更によって再送信を試みる状態
	 */
	status!: MailStatus;
	from!: string;
	fromName?: string;
	cc?: string[];
	bcc?: string[];
	to!: string;
	subject!: string;
	body!: string;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			shopId: { isMandatory: true, validator: isUUID },
			status: { isMandatory: true, validator: isMailStatus },
			from: { isMandatory: true, type: 'string' },
			fromName: { type: 'string' },
			cc: { isArray: true, type: 'string' },
			bcc: { isArray: true, type: 'string' },
			to: { isMandatory: true, type: 'string' },
			subject: { isMandatory: true, type: 'string' },
			body: { isMandatory: true, type: 'string' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			shopId: this.shopId,
			status: this.status,
			from: this.from,
			fromName: this.fromName,
			cc: this.cc,
			bcc: this.bcc,
			to: this.to,
			subject: this.subject,
			body: this.body,
		});
	}
}
export type MailAttr = Omit<ExcludeMethod<Mail>, 'id' | 'typeId' | 'shopId'>;
export type MailBaseAttr = Omit<MailAttr, 'status'>;
