import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, ShopTokenExpirationStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetShopTokenExpirationResponseStruct = ShopTokenExpirationStruct;
export type GetShopTokenExpirationResponse = s.Infer<typeof GetShopTokenExpirationResponseStruct>;
export type GetShopTokenExpirationResponseValues = s.Infer<typeof ShopTokenExpirationStruct>;

export type GetShopTokenExpirationMethodParams = {
	shopId: string;
};

export function getShopTokenExpiration(params: GetShopTokenExpirationMethodParams): APIRequest {
	const { shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/yahoo/${shopId}/shop/token`,
		{
			path: { shopId },
		},
		ApiParamStructs
	);
}
