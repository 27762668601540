import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
		campaignId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.object({
		priority: s.number()
	}),
};

export const SetCampaignPriorityResponseStruct = s.boolean();
export type SetCampaignPriorityResponse = s.Infer<typeof SetCampaignPriorityResponseStruct>;

export type SetCampaignPriorityMethodParams = {
	shopId: string;
	campaignId: string;
	priority: number;
};

export function setCampaignPriority(params: SetCampaignPriorityMethodParams): APIRequest {
	const { shopId, campaignId, priority } = params;
	return createAPIRequest(
		'put',
		uri`/api/y-review/${shopId}/campaign/${campaignId}/priority`,
		{
			path: { shopId, campaignId },
			body: { priority }
		},
		ApiParamStructs
	);
}
