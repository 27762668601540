/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from "dayjs";
import { contentType, ContentType, CONTENT_SETTINGS } from "../../const/content-template";
import { entityIdMap } from "../../const/entity-id-map";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { isRecord } from "../../isRecord";
export * from "../../const/content-template";

export function contentTypeValidator(val: string): val is ContentType {
	return (contentType as readonly string[]).includes(val);
}

export const DEFAULT_CONTENT_TYPE = contentType[0];
export const CONTENT_TYPES_OF_NOTICE = Object.entries(CONTENT_SETTINGS).filter(([_, v]) => v.level == 'NOTICE').map(([k, _]) => k);
export const CONTENT_TYPES_OF_WARNING = Object.entries(CONTENT_SETTINGS).filter(([_, v]) => v.level == 'WARNING').map(([k, _]) => k);
export const CONTENT_TYPES_OF_ALERT = Object.entries(CONTENT_SETTINGS).filter(([_, v]) => v.level == 'ALERT').map(([k, _]) => k);
export const CONTENT_TYPE_ORDER = Object.fromEntries(contentType.map((target, idx) => [target, idx]));

export class Content extends Entity {
	static typeId = entityIdMap['notice.content'];

	service!: string;
	type!: ContentType;
	sourceId?: string;
	date!: number;
	title?: string;
	content?: string;
	alert?: string;
	link?: string;
	disabled?: boolean;
	values?: Record<string, string|number>
	sendAlertDate?: number;

	constructor(obj: unknown) {
		super(obj);
		if (!isRecord(obj)) {
			throw null;
		}

		const msgType: string = String(obj.type) || '';
		if (CONTENT_SETTINGS[msgType]) {
			obj = {
				...obj,
				...CONTENT_SETTINGS[msgType],
			}
		}

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			service: { isMandatory: true, type: 'string' },
			type: { isMandatory: true, type: 'string', validator: contentTypeValidator },
			sourceId: { type: 'string' },
			date: { isMandatory: true, type: 'number' },
			title: { type: 'string' },
			content: { type: 'string' },
			alert: { type: 'string' },
			link: { type: 'string' },
			disabled: { type: 'boolean' },
			values: { type: 'object' },
			sendAlertDate: { type: 'number' },
		});
	}

	getContent(): string {
		return this.render(this.content ?? '');
	}

	getAlert(): string {
		return this.render(this.alert ?? '');
	}

	render(value: string): string {
		return value.replace(/{{([\w]*):?([^}]*)?}}/g, (match, p1, p2) => {
			const v = (this.values && this.values[p1]) ? this.values[p1] : '';
			return p2 ? dayjs(v).format(p2) :
						String(v);
		});
	}

	isSentAlert(): boolean {
		return Boolean(this.sendAlertDate);
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			service: this.service,
			type: this.type,
			sourceId: this.sourceId,
			date: this.date,
			title: this.title,
			content: this.content,
			alert: this.content,
			link: this.link,
			disabled: this.disabled,
			values: this.values,
			sendAlertDate: this.sendAlertDate,
		});
	}

}