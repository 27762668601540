import { toJapaneseDate } from "../toJapaneseDate";
import { findIllust } from "./illust";
import { KAMON_INFO, findKamon } from "./kamon";

export const CSV_VALUE_TYPES = [
	'alphanumeric', // 半角英数字
	'hyphenate numbers', // 半角数字及び半角ハイフン
	'numeric', // 半角数字
	'ascii', // 半角英数記号
	'katakana',	// 全角カタカナ
	'halfwidth katakana', // 半角カタカナ
	'any', // すべて
] as const;

export const CSV_SYSTEM_COLUMN_TYPES = [
	'id', // お客様管理番号
	'shippingDate', // 出荷予定日
	'deliveryDate', // お届け予定日
	'deliveryTimezone', // 配達時間帯
	'destPhone', // お届け先電話番号
	'destZip', // お届け先郵便番号
	'destPref', // お届け先都道府県
	'destCity', // お届け先市区郡町村
	'destAddress', // お届け先町・番地
	'destSubAddress', // お届け先アパートマンション名
	'destCompany', // お届け先会社・部門１
	'destSection', // お届け先会社・部門２
	'destName', // お届け先名
	'destNameKana', // お届け先名(カナ)
] as const;

export const CSV_CLIENT_COLUMN_TYPES = [
	'clientCode', // ご依頼主コード
	'clientPhone', // ご依頼主電話番号
	'clientPhoneId', // ご依頼主電話番号枝番
	'clientZip', // ご依頼主郵便番号
	'clientPref', // ご依頼主都道府県
	'clientCity', // ご依頼主市区郡町村
	'clientAddress', // ご依頼主町・番地
	'clientSubAddress', // ご依頼主アパートマンション
	'clientCompany',
	'clientSection',
	'clientName', // ご依頼主名
	'clientNameKana', // ご依頼主名(カナ)
] as const;

export const CSV_DEST_ADDRESS_TYPES = ['destAddress', 'destSubAddress', 'destCompany', 'destSection'] as const;
export const CSV_CLIENT_ADDRESS_TYPES = ['clientAddress', 'clientSubAddress', 'clientCompany', 'clientSection'] as const;

export type CSVValueType = typeof CSV_VALUE_TYPES[number];
export type CSVSystemColumnType = typeof CSV_SYSTEM_COLUMN_TYPES[number];
export type CSVClientColumnType = typeof CSV_CLIENT_COLUMN_TYPES[number];
export type CSVColumnType = CSVClientColumnType | CSVSystemColumnType | 'user' | 'fix' | 'option' | 'func';

type CSVColumnFuncRequest = {
	order: {
		number: string,
		orderItems: {
			itemName?: string,
		}[],
	},
};

export type CSVColumn = {
	name: string;
	header?: string;
	type: CSVColumnType;
	value?: string;
	func?: (values: Partial<Record<CSVColumnType, string>>, optionValues: Record<string, unknown>, request: CSVColumnFuncRequest) => string | undefined;
	length?: number;
	valueType?: CSVValueType;
};

export type CSVFormat = {
	id: string;
	name: string;
	type: 'csv' | 'sasagase';
	encode?: string;
	lengthEncode?: string;
	bom?: boolean;
	header?: boolean;
	quoted_string?: boolean;
	columns: readonly CSVColumn[];
};

export type CSVValueTypeDescription = {
	name: string;
	regex: RegExp;
	length: (len: number) => string;
};

export const CSV_VALUE_TYPE_DESCS: Record<CSVValueType, CSVValueTypeDescription> = {
	'alphanumeric': {
		name: '半角英数字',
		regex: /^[a-zA-Z0-9]*$/,
		length: (len: number): string => `半角${len}文字`,
	},
	'hyphenate numbers': {
		name: '半角数字ハイフン',
		regex: /^[-0-9]*$/,
		length: (len: number): string => `半角${len}文字`,
	},
	'numeric': {
		name: '半角数字',
		regex: /^[0-9]*$/,
		length: (len: number): string => `半角${len}文字`,
	},
	'ascii': {
		name: '半角英数記号',
		regex: /^[\x20-\x7e]*$/,
		length: (len: number): string => `半角${len}文字`,
	},
	'katakana': {
		name: '全角カタカナ',
		regex: /^[\u30a1-\u30fa\u30fd\u30ff]*$/,
		length: (len: number): string => `全角${len}文字`,
	},
	'halfwidth katakana': {
		name: '半角カタカナ',
		regex: /^[\uff66-\uff9f]*$/, // ｦ-ﾟ
		length: (len: number): string => `半角${len}文字`,
	},
	'any': {
		name: 'すべて',
		regex: /^.*$/,
		length: (len: number): string => `半角${len}文字、全角${len / 2}文字`,
	},
};

export const CSV_FORMAT: readonly CSVFormat[] = [
	{
		id: 'yamato',
		name: 'ヤマト運輸 宅急便（発払い）',
		type: 'csv',
		lengthEncode: 'shift_jis',
		bom: true,
		columns: [
			{ name: 'お客様管理番号', type: 'id', length: 50, valueType: 'alphanumeric' },
			{ name: '送り状種類', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: 'クール区分', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '伝票番号', type: 'fix', value: '', length: 0 },
			{ name: '出荷予定日', type: 'shippingDate', length: 10, valueType: 'ascii' },
			{ name: 'お届け予定日', type: 'deliveryDate', length: 10, valueType: 'ascii' },
			{ name: '配達時間帯', type: 'deliveryTimezone', length: 4, valueType: 'ascii' },
			{ name: 'お届け先コード', type: 'fix', value: '', length: 20, valueType: 'alphanumeric' },
			{ name: 'お届け先電話番号', type: 'destPhone', length: 15, valueType: 'hyphenate numbers' },
			{ name: 'お届け先電話番号枝番', type: 'fix', value: '', length: 2, valueType: 'numeric' },
			{ name: 'お届け先郵便番号', type: 'destZip', length: 8, valueType: 'hyphenate numbers' },
			{ name: 'お届け先住所(都道府県)', header: 'お届け先住所', type: 'destPref', length: 8 },
			{ name: 'お届け先住所(市区郡町村)', header: 'お届け先住所', type: 'destCity', length: 24 },
			{ name: 'お届け先住所(町・番地)', header: 'お届け先住所', type: 'destAddress', length: 32 },
			{ name: 'お届け先アパートマンション名', type: 'destSubAddress', length: 32 },
			{ name: 'お届け先会社・部門１', type: 'destCompany', length: 50 },
			{ name: 'お届け先会社・部門２', type: 'destSection', length: 50 },
			{ name: 'お届け先名',type: 'destName', length: 32 },
			{ name: 'お届け先名(カナ)', type: 'destNameKana', length: 50, valueType: 'halfwidth katakana' },
			{ name: '敬称', type: 'user', value: '', length: 4 },
			{ name: 'ご依頼主コード', type: 'clientCode', length: 20, valueType: 'alphanumeric' },
			{ name: 'ご依頼主電話番号', type: 'clientPhone', length: 15, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主電話番号枝番', type: 'clientPhoneId', length: 2, valueType: 'numeric' },
			{ name: 'ご依頼主郵便番号', type: 'clientZip', length: 8, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主住所(都道府県)', header: 'ご依頼主住所', type: 'clientPref', length: 8 },
			{ name: 'ご依頼主住所(市区郡町村)', header: 'ご依頼主住所', type: 'clientCity', length: 24 },
			{ name: 'ご依頼主住所(町・番地)', header: 'ご依頼主住所', type: 'clientAddress', length: 32 },
			{ name: 'ご依頼主アパートマンション', type: 'clientSubAddress', length: 32 },
			{ name: 'ご依頼主名', type: 'clientName', length: 32 },
			{ name: 'ご依頼主名(カナ)', type: 'clientNameKana', length: 50, valueType: 'halfwidth katakana' },
			{ name: '品名コード１', type: 'user', length: 30, valueType: 'alphanumeric' },
			{ name: '品名１', type: 'user', length: 50 },
			{ name: '品名コード２', type: 'user', length: 30, valueType: 'alphanumeric' },
			{ name: '品名２', type: 'user', length: 50 },
			{ name: '荷扱い１', type: 'user', length: 20 },
			{ name: '荷扱い２', type: 'user', length: 20 },
			{ name: '記事', type: 'user', length: 44 },
			{ name: 'コレクト代金引換額（税込)', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '内消費税額等', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '止置き', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '営業所コード', type: 'user', length: 6, valueType: 'numeric' },
			{ name: '発行枚数', type: 'user', length: 2, valueType: 'numeric' },
			{ name: '個数口表示フラグ', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '請求先顧客コード', type: 'user', length: 12, valueType: 'numeric' },
			{ name: '請求先分類コード', type: 'user', length: 3, valueType: 'numeric' },
			{ name: '運賃管理番号', type: 'user', length: 2, valueType: 'numeric' },
		],
	},
	{
		id: 'yamato-dm',
		name: 'ヤマト運輸 クロネコゆうメール（旧：クロネコDM便）',
		type: 'csv',
		lengthEncode: 'shift_jis',
		bom: true,
		columns: [
			{ name: 'お客様管理番号', type: 'id', length: 50, valueType: 'alphanumeric' },
			{ name: '送り状種類', type: 'fix', value: '3', length: 1, valueType: 'numeric' },
			{ name: 'クール区分', type: 'fix', value: '', length: 1, valueType: 'numeric' },
			{ name: '伝票番号', type: 'fix', value: '', length: 0 },
			{ name: '出荷予定日', type: 'shippingDate', length: 10, valueType: 'ascii' },
			{ name: 'お届け予定日', type: 'deliveryDate', length: 10, valueType: 'ascii' },
			{ name: '配達時間帯', type: 'deliveryTimezone', length: 4, valueType: 'ascii' },
			{ name: 'お届け先コード', type: 'fix', value: '', length: 20, valueType: 'alphanumeric' },
			{ name: 'お届け先電話番号', type: 'destPhone', length: 15, valueType: 'hyphenate numbers' },
			{ name: 'お届け先電話番号枝番', type: 'fix', value: '', length: 2, valueType: 'numeric' },
			{ name: 'お届け先郵便番号', type: 'destZip', length: 8, valueType: 'hyphenate numbers' },
			{ name: 'お届け先住所(都道府県)', header: 'お届け先住所', type: 'destPref', length: 8 },
			{ name: 'お届け先住所(市区郡町村)', header: 'お届け先住所', type: 'destCity', length: 24 },
			{ name: 'お届け先住所(町・番地)', header: 'お届け先住所', type: 'destAddress', length: 32 },
			{ name: 'お届け先アパートマンション名', type: 'destSubAddress', length: 32 },
			{ name: 'お届け先会社・部門１', type: 'destCompany', length: 50 },
			{ name: 'お届け先会社・部門２', type: 'destSection', length: 50 },
			{ name: 'お届け先名',type: 'destName', length: 32 },
			{ name: 'お届け先名(カナ)', type: 'destNameKana', length: 50, valueType: 'halfwidth katakana' },
			{ name: '敬称', type: 'user', length: 4 },
			{ name: 'ご依頼主コード', type: 'clientCode', length: 20, valueType: 'alphanumeric' },
			{ name: 'ご依頼主電話番号', type: 'clientPhone', length: 15, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主電話番号枝番', type: 'clientPhoneId', length: 2, valueType: 'numeric' },
			{ name: 'ご依頼主郵便番号', type: 'clientZip', length: 8, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主住所(都道府県)', header: 'ご依頼主住所', type: 'clientPref', length: 8 },
			{ name: 'ご依頼主住所(市区郡町村)', header: 'ご依頼主住所', type: 'clientCity', length: 24 },
			{ name: 'ご依頼主住所(町・番地)', header: 'ご依頼主住所', type: 'clientAddress', length: 32 },
			{ name: 'ご依頼主アパートマンション', type: 'clientSubAddress', length: 32 },
			{ name: 'ご依頼主名', type: 'clientName', length: 32 },
			{ name: 'ご依頼主名(カナ)', type: 'clientNameKana', length: 50, valueType: 'halfwidth katakana' },
			{ name: '品名コード１', type: 'user', length: 30, valueType: 'alphanumeric' },
			{ name: '品名１', type: 'user', length: 50 },
			{ name: '品名コード２', type: 'user', length: 30, valueType: 'alphanumeric' },
			{ name: '品名２', type: 'user', length: 50 },
			{ name: '荷扱い１', type: 'user', length: 20 },
			{ name: '荷扱い２', type: 'user', length: 20 },
			{ name: '記事', type: 'user', length: 44 },
			{ name: 'コレクト代金引換額（税込)', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '内消費税額等', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '止置き', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '営業所コード', type: 'user', length: 6, valueType: 'numeric' },
			{ name: '発行枚数', type: 'fix', value: '', length: 2, valueType: 'numeric' },
			{ name: '個数口表示フラグ', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '請求先顧客コード', type: 'fix', value: '', length: 12, valueType: 'numeric' },
			{ name: '請求先分類コード', type: 'fix', value: '', length: 3, valueType: 'numeric' },
			{ name: '運賃管理番号', type: 'fix', value: '', length: 2, valueType: 'numeric' },
		],
	},
	{
		id: 'yamato-packet',
		name: 'ヤマト運輸 クロネコゆうパケット',
		type: 'csv',
		lengthEncode: 'shift_jis',
		bom: true,
		columns: [
			{ name: 'お客様管理番号', type: 'id', length: 50, valueType: 'alphanumeric' },
			{ name: '送り状種類', type: 'fix', value: '7', length: 1, valueType: 'numeric' },
			{ name: 'クール区分', type: 'fix', value: '', length: 1, valueType: 'numeric' },
			{ name: '伝票番号', type: 'fix', value: '', length: 0 },
			{ name: '出荷予定日', type: 'shippingDate', length: 10, valueType: 'ascii' },
			{ name: 'お届け予定日', type: 'deliveryDate', length: 10, valueType: 'ascii' },
			{ name: '配達時間帯', type: 'deliveryTimezone', length: 4, valueType: 'ascii' },
			{ name: 'お届け先コード', type: 'fix', value: '', length: 20, valueType: 'alphanumeric' },
			{ name: 'お届け先電話番号', type: 'destPhone', length: 15, valueType: 'hyphenate numbers' },
			{ name: 'お届け先電話番号枝番', type: 'fix', value: '', length: 2, valueType: 'numeric' },
			{ name: 'お届け先郵便番号', type: 'destZip', length: 8, valueType: 'hyphenate numbers' },
			{ name: 'お届け先住所(都道府県)', header: 'お届け先住所', type: 'destPref', length: 8 },
			{ name: 'お届け先住所(市区郡町村)', header: 'お届け先住所', type: 'destCity', length: 24 },
			{ name: 'お届け先住所(町・番地)', header: 'お届け先住所', type: 'destAddress', length: 32 },
			{ name: 'お届け先アパートマンション名', type: 'destSubAddress', length: 32 },
			{ name: 'お届け先会社・部門１', type: 'destCompany', length: 50 },
			{ name: 'お届け先会社・部門２', type: 'destSection', length: 50 },
			{ name: 'お届け先名',type: 'destName', length: 32 },
			{ name: 'お届け先名(カナ)', type: 'destNameKana', length: 50, valueType: 'halfwidth katakana' },
			{ name: '敬称', type: 'user', length: 4 },
			{ name: 'ご依頼主コード', type: 'clientCode', length: 20, valueType: 'alphanumeric' },
			{ name: 'ご依頼主電話番号', type: 'clientPhone', length: 15, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主電話番号枝番', type: 'clientPhoneId', length: 2, valueType: 'numeric' },
			{ name: 'ご依頼主郵便番号', type: 'clientZip', length: 8, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主住所(都道府県)', header: 'ご依頼主住所', type: 'clientPref', length: 8 },
			{ name: 'ご依頼主住所(市区郡町村)', header: 'ご依頼主住所', type: 'clientCity', length: 24 },
			{ name: 'ご依頼主住所(町・番地)', header: 'ご依頼主住所', type: 'clientAddress', length: 32 },
			{ name: 'ご依頼主アパートマンション', type: 'clientSubAddress', length: 32 },
			{ name: 'ご依頼主名', type: 'clientName', length: 32 },
			{ name: 'ご依頼主名(カナ)', type: 'clientNameKana', length: 50, valueType: 'halfwidth katakana' },
			{ name: '品名コード１', type: 'user', length: 30, valueType: 'alphanumeric' },
			{ name: '品名１', type: 'user', length: 50 },
			{ name: '品名コード２', type: 'user', length: 30, valueType: 'alphanumeric' },
			{ name: '品名２', type: 'user', length: 50 },
			{ name: '荷扱い１', type: 'user', length: 20 },
			{ name: '荷扱い２', type: 'user', length: 20 },
			{ name: '記事', type: 'user', length: 44 },
			{ name: 'コレクト代金引換額（税込)', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '内消費税額等', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '止置き', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '営業所コード', type: 'user', length: 6, valueType: 'numeric' },
			{ name: '発行枚数', type: 'fix', value: '', length: 2, valueType: 'numeric' },
			{ name: '個数口表示フラグ', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '請求先顧客コード', type: 'user', length: 12, valueType: 'numeric' },
			{ name: '請求先分類コード', type: 'user', length: 3, valueType: 'numeric' },
			{ name: '運賃管理番号', type: 'user', length: 2, valueType: 'numeric' },
		],
	},
	{
		id: 'japanpost-web-mail',
		name: '日本郵便 ゆうメール（Webゆうパックプリント用）',
		type: 'csv',
		encode: 'shift_jis',
		lengthEncode: 'shift_jis',
		bom: false,
		header: false,
		columns: [
			{ name: '商品', type: 'fix', value: '2', length: 1, valueType: 'numeric' },
			{ name: '着払/代引', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: 'ゴルフ/スキー/空港', type: 'fix', length: 1, valueType: 'alphanumeric' },
			{ name: '往復', type: 'fix', length: 1, valueType: 'alphanumeric' },
			{ name: '書留/特定記録', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '配達方法', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '作成数', type: 'fix', value: '1', length: 2, valueType: 'numeric' },
			{ name: 'お届け先のお名前', type: 'destName', length: 50 },
			{ name: 'お届け先の敬称', type: 'user', length: 4 },
			{ name: 'お届け先のお名前（カナ）', type: 'destNameKana', length: 50, valueType: 'katakana' },
			{ name: 'お届け先の郵便番号', type: 'func', func: (v) => {
				return typeof v.destZip == 'string' ? v.destZip.replace('-', '') : v.destZip;
			}},
			{ name: 'お届け先の都道府県', type: 'destPref', length: 10 },
			{ name: 'お届け先の市区町村郡', type: 'destCity', length: 50 },
			{ name: 'お届け先の丁目番地号', type: 'destAddress', length: 50 },
			{ name: 'お届け先の建物名・部屋番号など', type: 'destSubAddress', length: 50 },
			{ name: 'お届け先の電話番号', type: 'destPhone', length: 13, valueType: 'hyphenate numbers' },
			{ name: 'お届け先の法人名', type: 'destCompany', length: 50 },
			{ name: 'お届け先の部署名', type: 'destSection', length: 50 },
			{ name: 'お届け先のメールアドレス', type: 'fix', length: 100, valueType: 'ascii' },
			{ name: '空港略称', type: 'fix', length: 60 },
			{ name: '空港コード', type: 'fix', length: 4 },
			{ name: '受取人様のお名前', type: 'fix', length: 25 },
			{ name: 'ご依頼主のお名前', type: 'clientName', length: 50 },
			{ name: 'ご依頼主の敬称', type: 'user', length: 4 },
			{ name: 'ご依頼主のお名前（カナ）', type: 'clientNameKana', length: 50, valueType: 'katakana' },
			{ name: 'ご依頼主の郵便番号', type: 'clientZip', length: 7, valueType: 'numeric' },
			{ name: 'ご依頼主の都道府県', type: 'clientPref', length: 10 },
			{ name: 'ご依頼主の市区町村郡', type: 'clientCity', length: 50 },
			{ name: 'ご依頼主の丁目番地号', type: 'clientAddress', length: 50 },
			{ name: 'ご依頼主の建物名・部屋番号など', type: 'clientSubAddress', length: 50 },
			{ name: 'ご依頼主の電話番号', type: 'clientPhone', length: 13, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主の法人名', type: 'clientCompany', length: 50 },
			{ name: 'ご依頼主の部署名', type: 'clientSection', length: 50 },
			{ name: 'ご依頼主のメールアドレス', type: 'fix', length: 100, valueType: 'ascii' },
			{ name: '品名', type: 'user', length: 40 },
			{ name: '品名番号', type: 'user', length: 20 },
			{ name: '個数', type: 'user', length: 5, valueType: 'numeric' },
			{ name: '発送予定日', type: 'fix', length: 8, valueType: 'numeric' },
			{ name: '発送予定時間帯', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: 'セキュリティ', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '重量', type: 'user', length: 4, valueType: 'numeric' },
			{ name: '損害要償額', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '保冷', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 こわれもの', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 なまもの', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 ビン類', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 逆さま厳禁', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 下積み厳禁', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '予備', type: 'fix', length: 1 },
			{ name: '差出予定日', type: 'fix', length: 8, valueType: 'numeric' },
			{ name: '差出予定時間帯', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: '配達希望日', type: 'deliveryDate', length: 8, valueType: 'numeric' },
			{ name: '配達希望時間帯', type: 'deliveryTimezone', length: 2, valueType: 'numeric' },
			{ name: 'クラブ本数', type: 'fix', length: 2 },
			{ name: 'ご使用日(プレー日)', type: 'fix', length: 8 },
			{ name: 'ご使用時間', type: 'fix', length: 2 },
			{ name: '搭乗日', type: 'fix', length: 8 },
			{ name: '搭乗時間', type: 'fix', length: 2 },
			{ name: '搭乗便名', type: 'fix', length: 20 },
			{ name: '復路発送予定日', type: 'fix', length: 8 },
			{ name: 'お支払方法', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '摘要/記事', type: 'user', length: 25 },
			{ name: 'サイズ', type: 'user', length: 3, valueType: 'numeric' },
			{ name: '差出方法', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '割引', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '代金引換金額', type: 'user', length: 7, valueType: 'numeric' },
			{ name: 'うち消費税等', type: 'user', length: 6, valueType: 'numeric' },
			{ name: '配達予定日通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '配達完了通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '不在持戻り通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '郵便局留通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '配達完了通知 (依頼主)', type: 'user', length: 1, valueType: 'numeric' },
		],
	},
	{
		id: 'japanpost-web-packet',
		name: '日本郵便 ゆうパケット（Webゆうパックプリント用）',
		type: 'csv',
		encode: 'shift_jis',
		lengthEncode: 'shift_jis',
		bom: false,
		header: false,
		columns: [
			{ name: '商品', type: 'fix', value: '6', length: 1, valueType: 'numeric' },
			{ name: '着払/代引', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: 'ゴルフ/スキー/空港', type: 'fix', length: 1, valueType: 'alphanumeric' },
			{ name: '往復', type: 'fix', length: 1, valueType: 'alphanumeric' },
			{ name: '書留/特定記録', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '配達方法', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '作成数', type: 'fix', value: '1', length: 2, valueType: 'numeric' },
			{ name: 'お届け先のお名前', type: 'destName', length: 50 },
			{ name: 'お届け先の敬称', type: 'user', length: 4 },
			{ name: 'お届け先のお名前（カナ）', type: 'destNameKana', length: 50, valueType: 'katakana' },
			{ name: 'お届け先の郵便番号', type: 'func', func: (v) => {
				return typeof v.destZip == 'string' ? v.destZip.replace('-', '') : v.destZip;
			}},
			{ name: 'お届け先の都道府県', type: 'destPref', length: 10 },
			{ name: 'お届け先の市区町村郡', type: 'destCity', length: 50 },
			{ name: 'お届け先の丁目番地号', type: 'destAddress', length: 50 },
			{ name: 'お届け先の建物名・部屋番号など', type: 'destSubAddress', length: 50 },
			{ name: 'お届け先の電話番号', type: 'destPhone', length: 13, valueType: 'hyphenate numbers' },
			{ name: 'お届け先の法人名', type: 'destCompany', length: 50 },
			{ name: 'お届け先の部署名', type: 'destSection', length: 50 },
			{ name: 'お届け先のメールアドレス', type: 'fix', length: 100, valueType: 'ascii' },
			{ name: '空港略称', type: 'fix', length: 60 },
			{ name: '空港コード', type: 'fix', length: 4 },
			{ name: '受取人様のお名前', type: 'fix', length: 25 },
			{ name: 'ご依頼主のお名前', type: 'clientName', length: 50 },
			{ name: 'ご依頼主の敬称', type: 'user', length: 4 },
			{ name: 'ご依頼主のお名前（カナ）', type: 'clientNameKana', length: 50, valueType: 'katakana' },
			{ name: 'ご依頼主の郵便番号', type: 'clientZip', length: 7, valueType: 'numeric' },
			{ name: 'ご依頼主の都道府県', type: 'clientPref', length: 10 },
			{ name: 'ご依頼主の市区町村郡', type: 'clientCity', length: 50 },
			{ name: 'ご依頼主の丁目番地号', type: 'clientAddress', length: 50 },
			{ name: 'ご依頼主の建物名・部屋番号など', type: 'clientSubAddress', length: 50 },
			{ name: 'ご依頼主の電話番号', type: 'clientPhone', length: 13, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主の法人名', type: 'clientCompany', length: 50 },
			{ name: 'ご依頼主の部署名', type: 'clientSection', length: 50 },
			{ name: 'ご依頼主のメールアドレス', type: 'fix', length: 100, valueType: 'ascii' },
			{ name: '品名', type: 'user', length: 40 },
			{ name: '品名番号', type: 'user', length: 20 },
			{ name: '個数', type: 'user', length: 5, valueType: 'numeric' },
			{ name: '発送予定日', type: 'fix', length: 8, valueType: 'numeric' },
			{ name: '発送予定時間帯', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: 'セキュリティ', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '重量', type: 'user', length: 4, valueType: 'numeric' },
			{ name: '損害要償額', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '保冷', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 こわれもの', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 なまもの', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 ビン類', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 逆さま厳禁', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 下積み厳禁', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '予備', type: 'fix', length: 1 },
			{ name: '差出予定日', type: 'fix', length: 8, valueType: 'numeric' },
			{ name: '差出予定時間帯', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: '配達希望日', type: 'deliveryDate', length: 8, valueType: 'numeric' },
			{ name: '配達希望時間帯', type: 'deliveryTimezone', length: 2, valueType: 'numeric' },
			{ name: 'クラブ本数', type: 'fix', length: 2 },
			{ name: 'ご使用日(プレー日)', type: 'fix', length: 8 },
			{ name: 'ご使用時間', type: 'fix', length: 2 },
			{ name: '搭乗日', type: 'fix', length: 8 },
			{ name: '搭乗時間', type: 'fix', length: 2 },
			{ name: '搭乗便名', type: 'fix', length: 20 },
			{ name: '復路発送予定日', type: 'fix', length: 8 },
			{ name: 'お支払方法', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '摘要/記事', type: 'user', length: 25 },
			{ name: 'サイズ', type: 'user', length: 3, valueType: 'numeric' },
			{ name: '差出方法', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '割引', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '代金引換金額', type: 'user', length: 7, valueType: 'numeric' },
			{ name: 'うち消費税等', type: 'user', length: 6, valueType: 'numeric' },
			{ name: '配達予定日通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '配達完了通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '不在持戻り通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '郵便局留通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '配達完了通知 (依頼主)', type: 'user', length: 1, valueType: 'numeric' },
		],
	},
	{
		id: 'japanpost-web-pack',
		name: '日本郵便 ゆうパック（Webゆうパックプリント用）',
		type: 'csv',
		encode: 'shift_jis',
		lengthEncode: 'shift_jis',
		bom: false,
		header: false,
		columns: [
			{ name: '商品', type: 'fix', value: '1', length: 1, valueType: 'numeric' },
			{ name: '着払/代引', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: 'ゴルフ/スキー/空港', type: 'fix', length: 1, valueType: 'alphanumeric' },
			{ name: '往復', type: 'fix', length: 1, valueType: 'alphanumeric' },
			{ name: '書留/特定記録', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '配達方法', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '作成数', type: 'fix', value: '1', length: 2, valueType: 'numeric' },
			{ name: 'お届け先のお名前', type: 'destName', length: 50 },
			{ name: 'お届け先の敬称', type: 'user', length: 4 },
			{ name: 'お届け先のお名前（カナ）', type: 'destNameKana', length: 50, valueType: 'katakana' },
			{ name: 'お届け先の郵便番号', type: 'func', func: (v) => {
				return typeof v.destZip == 'string' ? v.destZip.replace('-', '') : v.destZip;
			}},
			{ name: 'お届け先の都道府県', type: 'destPref', length: 10 },
			{ name: 'お届け先の市区町村郡', type: 'destCity', length: 50 },
			{ name: 'お届け先の丁目番地号', type: 'destAddress', length: 50 },
			{ name: 'お届け先の建物名・部屋番号など', type: 'destSubAddress', length: 50 },
			{ name: 'お届け先の電話番号', type: 'destPhone', length: 13, valueType: 'hyphenate numbers' },
			{ name: 'お届け先の法人名', type: 'destCompany', length: 50 },
			{ name: 'お届け先の部署名', type: 'destSection', length: 50 },
			{ name: 'お届け先のメールアドレス', type: 'fix', length: 100, valueType: 'ascii' },
			{ name: '空港略称', type: 'fix', length: 60 },
			{ name: '空港コード', type: 'fix', length: 4 },
			{ name: '受取人様のお名前', type: 'fix', length: 25 },
			{ name: 'ご依頼主のお名前', type: 'clientName', length: 50 },
			{ name: 'ご依頼主の敬称', type: 'user', length: 4 },
			{ name: 'ご依頼主のお名前（カナ）', type: 'clientNameKana', length: 50, valueType: 'katakana' },
			{ name: 'ご依頼主の郵便番号', type: 'clientZip', length: 7, valueType: 'numeric' },
			{ name: 'ご依頼主の都道府県', type: 'clientPref', length: 10 },
			{ name: 'ご依頼主の市区町村郡', type: 'clientCity', length: 50 },
			{ name: 'ご依頼主の丁目番地号', type: 'clientAddress', length: 50 },
			{ name: 'ご依頼主の建物名・部屋番号など', type: 'clientSubAddress', length: 50 },
			{ name: 'ご依頼主の電話番号', type: 'clientPhone', length: 13, valueType: 'hyphenate numbers' },
			{ name: 'ご依頼主の法人名', type: 'clientCompany', length: 50 },
			{ name: 'ご依頼主の部署名', type: 'clientSection', length: 50 },
			{ name: 'ご依頼主のメールアドレス', type: 'fix', length: 100, valueType: 'ascii' },
			{ name: '品名', type: 'user', length: 40 },
			{ name: '品名番号', type: 'user', length: 20 },
			{ name: '個数', type: 'user', length: 5, valueType: 'numeric' },
			{ name: '発送予定日', type: 'fix', length: 8, valueType: 'numeric' },
			{ name: '発送予定時間帯', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: 'セキュリティ', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '重量', type: 'user', length: 4, valueType: 'numeric' },
			{ name: '損害要償額', type: 'user', length: 7, valueType: 'numeric' },
			{ name: '保冷', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 こわれもの', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 なまもの', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 ビン類', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 逆さま厳禁', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '取扱上の注意 下積み厳禁', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '予備', type: 'fix', length: 1 },
			{ name: '差出予定日', type: 'fix', length: 8, valueType: 'numeric' },
			{ name: '差出予定時間帯', type: 'fix', length: 1, valueType: 'numeric' },
			{ name: '配達希望日', type: 'deliveryDate', length: 8, valueType: 'numeric' },
			{ name: '配達希望時間帯', type: 'deliveryTimezone', length: 2, valueType: 'numeric' },
			{ name: 'クラブ本数', type: 'fix', length: 2 },
			{ name: 'ご使用日(プレー日)', type: 'fix', length: 8 },
			{ name: 'ご使用時間', type: 'fix', length: 2 },
			{ name: '搭乗日', type: 'fix', length: 8 },
			{ name: '搭乗時間', type: 'fix', length: 2 },
			{ name: '搭乗便名', type: 'fix', length: 20 },
			{ name: '復路発送予定日', type: 'fix', length: 8 },
			{ name: 'お支払方法', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '摘要/記事', type: 'user', length: 25 },
			{ name: 'サイズ', type: 'user', length: 3, valueType: 'numeric' },
			{ name: '差出方法', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '割引', type: 'fix', value: '0', length: 1, valueType: 'numeric' },
			{ name: '代金引換金額', type: 'user', length: 7, valueType: 'numeric' },
			{ name: 'うち消費税等', type: 'user', length: 6, valueType: 'numeric' },
			{ name: '配達予定日通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '配達完了通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '不在持戻り通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '郵便局留通知 (お届け先)', type: 'user', length: 1, valueType: 'numeric' },
			{ name: '配達完了通知 (依頼主)', type: 'user', length: 1, valueType: 'numeric' },
		],
	},
	{
		id: 'japanpost-clickpost',
		name: '日本郵便 クリックポスト',
		type: 'csv',
		encode: 'shift_jis',
		lengthEncode: 'shift_jis',
		bom: false,
		quoted_string: false,
		columns: [
			{ name: 'お届け先郵便番号', type: 'destZip', length: 8, valueType: 'hyphenate numbers' },
			{ name: 'お届け先氏名', type: 'destName', length: 40 },
			{ name: 'お届け先敬称', type: 'user', length: 4 },
			{ name: 'お届け先住所(都道府県)', header: 'お届け先住所1行目', type: 'destPref' },
			{ name: 'お届け先住所(市区郡町村)', header: 'お届け先住所1行目', type: 'destCity' },
			{ name: 'お届け先住所(町・番地)', header: 'お届け先住所2行目', type: 'destAddress', length: 40 },
			{ name: 'お届け先住所3行目', type: 'destSubAddress', length: 40 },
			{ name: 'お届け先住所4行目', type: 'fix', length: 40 },
			{ name: '内容品', type: 'user', length: 30 },
		],
	},
	{
		id: 'general_v2',
		name: '汎用CSV',
		type: 'csv',
		columns: [
			{ name: '出荷予定日', type: 'shippingDate' },
			{ name: '郵便番号', type: 'destZip' },
			{ name: '住所(都道府県)', header: '住所1', type: 'destPref' },
			{ name: '住所(市区郡町村)', header: '住所1', type: 'destCity' },
			{ name: '住所(町・番地)', header: '住所1', type: 'destAddress' },
			{ name: '住所2', type: 'destSubAddress' },
			{ name: '氏名', type: 'destName' },
			{ name: '電話番号', type: 'destPhone' },
			{ name: '注文番号', type: 'func', func: (_, __, request) => {
				return request.order.number;
			}},
			{ name: '購入商品', type: 'func', func: (_, __, request) => {
				const orderItems = request.order.orderItems;
				const itemNames = orderItems.map(item => item.itemName).join(",");
				return itemNames;
			}},
			{ name: '品名', type: 'user', length: 50 },
		],
	},
	{
		id: 'general',
		name: '汎用CSV(旧)',
		type: 'csv',
		columns: [
			{ name: '出荷予定日', type: 'shippingDate' },
			{ name: '郵便番号', type: 'destZip' },
			{ name: '住所(都道府県)', header: '住所1', type: 'destPref' },
			{ name: '住所(市区郡町村)', header: '住所1', type: 'destCity' },
			{ name: '住所(町・番地)', header: '住所1', type: 'destAddress' },
			{ name: '住所2', type: 'destSubAddress' },
			{ name: '氏名', type: 'destName' },
			{ name: '電話番号', type: 'destPhone' },
		],
	},
	{
		id: 'komari_kifuda',
		name: '[sasagase] イラスト入り お名前木札【中】',
		type: 'sasagase',
		columns: [
			{ name: 'SKU', type: 'fix', value: '00021836' },
			{ name: '商品名', type: 'fix', value: '木札 小(サイズ2)' },
			{ name: '絵柄・家紋No', type: 'option', value: 'kifudaKamon' },
			{ name: '絵柄・家紋種別', type: 'func', func: (_, o) => {
				const type = KAMON_INFO.find((k) => k.id === o.kifudaKamon)?.type ?? '';
				return {
					illust: '絵柄',
					kamon: '家紋',
					maru: '絵柄',
					'': '',
				}[type];
			}},
			{ name: '図柄名', type: 'func', func: (_, o) => {
				return KAMON_INFO.find((k) => k.id === o.kifudaKamon)?.name ?? '';
			}},
			{ name: '名前', type: 'option', value: 'kifudaName' },
			{ name: 'ふりがな', type: 'func', func: (_, o) => {
				return typeof o.kifudaFurigana == 'string' ? o.kifudaFurigana : '';
			}},
			{ name: '生年月日', type: 'func', func: (_, o) => {
				return typeof o.kifudaBirthDay == 'string' ? toJapaneseDate(o.kifudaBirthDay) : '';
			}},
			// eslint-disable-next-line no-irregular-whitespace
			{ name: '備考', type: 'func', func: (v) => `〒${v.destZip}\n${v.destPref}${v.destCity}${v.destAddress} ${v.destSubAddress}${v.destCompany}${v.destSection}\n電話: ${v.destPhone}　${v.destName} 様　※メール便にて直送` },
		],
	},
	{
		id: 'komari_maruKifuda',
		name: '[sasagase] イラスト入り お名前丸木札',
		type: 'sasagase',
		columns: [
			{ name: 'SKU', type: 'fix', value: '00021837' },
			{ name: '商品名', type: 'fix', value: '丸' },
			{ name: '絵柄・家紋No', type: 'func', func: (_, o) => {
				const id = typeof o.kifudaKamon == 'string' ? o.kifudaKamon : '';
				return findKamon(id)?.name;
			}},
			{ name: '絵柄・家紋種別', type: 'fix', value: '絵柄' },
			{ name: '図柄名', type: 'func', func: (_, o) => {
				const id = typeof o.kifudaKamon == 'string' ? o.kifudaKamon : '';
				return findKamon(id)?.id;
			}},
			{ name: '名前', type: 'option', value: 'kifudaName' },
			{ name: 'ふりがな', type: 'func', func: (_, o) => {
				return typeof o.kifudaFurigana == 'string' ? o.kifudaFurigana : '';
			}},
			{ name: '生年月日', type: 'func', func: (_, o) => {
				return typeof o.kifudaBirthDay == 'string' ? toJapaneseDate(o.kifudaBirthDay) : '';
			}},
			{ name: '備考', type: 'fix', value: '※こまり入れでお願いします。' },
		],
	},
	{
		id: 'komari_namaebata',
		name: '[sasagase] ミニ名前旗',
		type: 'sasagase',
		columns: [
			{ name: 'SKU', type: 'user' }, // 00021838 => 赤, 00021839 => 紺
			{ name: '名前', type: 'option', value: 'namaebataName' },
			{ name: '生年月日', type: 'func', func: (_, o) => {
				return typeof o.namaebataBirthDay == 'string' ? toJapaneseDate(o.namaebataBirthDay) : '';
			}},
		],
	},
	{
		id: 'komari_konori',
		name: '[sasagase] 【MOKU五月オプション】鯉のぼり konori 名入れオプション',
		type: 'sasagase',
		columns: [
			{ name: 'SKU', type: 'fix', value: '00022456' },
			{ name: '名前', type: 'option', value: 'konoriName' },
		],
	},
	{
		id: 'komari_namaemagnet',
		name: '[sasagase] お名前マグネット',
		type: 'sasagase',
		columns: [
			{ name: 'SKU', type: 'func', func: (_, o) => {
				const id = typeof o.namaemagnetIllust == 'string' ? o.namaemagnetIllust : '';
				return findIllust(id)?.sku;
			}},
			{ name: '品名', type: 'func', func: (_, o) => {
				const id = typeof o.namaemagnetIllust == 'string' ? o.namaemagnetIllust : '';
				return findIllust(id)?.name;
			}},
			{ name: '名前', type: 'option', value: 'namaemagnetName' },
		],
	},
] as const;
/*

### ヤマト運輸(宅急便コンパクト)
### ヤマト運輸(ネコポス便)
### 佐川急便(宅配便)
### 佐川急便(飛脚メール便)
### 佐川急便(飛脚ゆうメール便)
### 日本郵便(ゆうメール)
### 日本郵便(宅配便)
### 日本郵便(レターパック)
### 西濃運輸(宅配便)
### 西濃運輸(カンガルーPostalメール便)
### 福山通運(宅配便)
### 福山通運(フクツーメール)

*/