import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, MailContentStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		styleType: s.string()
	}),
	query: EmptyObjectStruct,
	body: s.record(s.string(), s.any())
};

export const GetReviewMailResponseStruct = s.nullable(MailContentStruct);
export type GetReviewMailResponse = s.Infer<typeof GetReviewMailResponseStruct>;
export type GetReviewMailResponseValues = s.Infer<typeof MailContentStruct>;

export type GetReviewMailMethodParams = {
	shopId: string;
	styleType: string;
	opt: Record<string, any>;
};

export function getReviewMail(params: GetReviewMailMethodParams): APIRequest {
	const { shopId, styleType, opt  } = params;
	return createAPIRequest(
		'post',
		uri`/api/user/mail/yReview/${shopId}/${styleType}`,
		{
			path: { shopId, styleType },
			body: opt
		},
		ApiParamStructs
	);
}
