import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, ShopStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetShopsResponseStruct = s.record(
	s.string(),
	ShopStruct
);
export type GetShopsResponse = s.Infer<typeof GetShopsResponseStruct>;
export type GetShopsResponseValues = s.Infer<typeof ShopStruct>;

export type GetShopsMethodParams = s.Infer<typeof ApiParamStructs.body>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getShops(params: GetShopsMethodParams): APIRequest {
	return createAPIRequest(
		'get',
		uri`/api/yahoo/shops/review`,
		{},
		ApiParamStructs
	);
}
