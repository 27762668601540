import { Mail } from './Mail';
import { MailShopEntity } from './MailShopEntity';

export * from './Mail';
export * from './MailShopEntity';

export const mailConstructors = {
	Mail,
	MailShopEntity,
};
