import { YahooCampaign } from './YahooCampaign';
import { YahooMailNotCovered } from './YahooMailNotCovered';
import { YahooCouponReward, YahooItemReward } from './YahooReward';
import { YahooShopEntity } from './YahooShopEntity';

export * from './YahooCampaign';
export * from './YahooMailNotCovered';
export * from './YahooReward';
export * from './YahooShopEntity';

export const yReviewConstructors = {
	YahooCampaign,
	YahooMailNotCovered,
	YahooItemReward,
	YahooCouponReward,
	YahooShopEntity,
};
