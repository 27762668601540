import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		userId: s.string()
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const DeleteEntityUserResponseStruct = s.boolean();
export type DeleteEntityUserResponse = s.Infer<typeof DeleteEntityUserResponseStruct>;

export type DeleteEntityUserMethodParams = {
	shopId: string;
	userId: string;
};

export function deleteEntityUser(params: DeleteEntityUserMethodParams): APIRequest {
	const { shopId, userId } = params;
	return createAPIRequest(
		'delete',
		uri`/api/user/entity/${shopId}/user/${userId}`,
		{
			path: { shopId, userId },
		},
		ApiParamStructs
	);
}
