import { campaignStateList } from '@sasagase/types';
import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: s.object({
		name: s.optional(s.string()),
		state: s.optional(s.enums(campaignStateList)),
		seq: s.optional(s.string()),
	}),
	body: EmptyObjectStruct
};

const GetCampaignListResponseValueStruct = s.type({
	id: s.string(),
	name: s.string(),
	isDraft: s.boolean(),
	beginDate: s.optional(s.number()),
	endDate: s.nullable(s.number()),
	itemGroups: s.array(s.type({
		id: s.string()
	})),
	rewards: s.array(s.type({
		id: s.string(),
		name: s.string(),
	})),
	state: s.enums(campaignStateList),
	priority: s.number()
});
export const GetCampaignListResponseStruct = s.array(
	GetCampaignListResponseValueStruct
);
export type GetCampaignListResponse = s.Infer<typeof GetCampaignListResponseStruct>;
export type GetCampaignListResponseValues = s.Infer<typeof GetCampaignListResponseValueStruct>;

export type GetCampaignListMethodParams = {
	shopId: string;
	name?: string;
	state?: typeof campaignStateList[number];
	seq?: string;
};

export function getCampaignList(params: GetCampaignListMethodParams): APIRequest {
	const { shopId, name, state, seq } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/campaign`,
		{
			path: { shopId },
			query: { name, state, seq },
		},
		ApiParamStructs
	);
}
