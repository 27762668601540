import * as s from 'superstruct';

export const YahooMailNotCoveredStruct = s.type({
	id: s.string(),
	name: s.string(),
	subject: s.string(),
	body: s.string(),
	signature: s.string(),
	isEnable: s.boolean(),
	isNonYahooMemberEnabled: s.boolean(),
});