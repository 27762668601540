import { entityIdMap } from "../../const/entity-id-map";
import { UUID } from "../../interface";
import { isUUID } from "../../isUUID";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

// attributes.type 
export const TOKEN_ATTRIBUTES_TYPE_ACTIVATE = 'activate'
export const TOKEN_ATTRIBUTES_TYPE_RESET_PASSWORD = 'resetPassword'

export class Token extends Entity {
	static typeId = entityIdMap['user.token'];

	userId!: UUID;
	entityId!: UUID;
	entityTypeId!: UUID;
	capabilityId!: UUID;
	token!: string;
	type!: string;
	expire!: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			userId: { isMandatory: true, type: 'string', validator: isUUID },
			entityId: { isMandatory: true, type: 'string', validator: isUUID },
			entityTypeId: { isMandatory: true, type: 'string', validator: isUUID },
			capabilityId: { isMandatory: true, type: 'string', validator: isUUID },
			token: { isMandatory: true, type: 'string' },
			type: { isMandatory: true, type: 'string' },
			expire: { isMandatory: true, type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			userId: this.userId,
			entityId: this.entityId,
			entityTypeId: this.entityTypeId,
			capabilityId: this.capabilityId,
			token: this.token,
			type: this.type,
			expire: this.expire,
		});
	}

	isTypeActivate(): boolean {
		return this.type === TOKEN_ATTRIBUTES_TYPE_ACTIVATE;
	}

	isTypeResetPassword(): boolean {
		return this.type === TOKEN_ATTRIBUTES_TYPE_RESET_PASSWORD;
	}

	isEnabled(now: number = Date.now()): boolean {
		return (typeof this.expire != 'number' || now < this.expire);
	}
}