import { entityIdMap } from "../../const/entity-id-map";
import { isRecord } from "../../isRecord";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { DestType, destTypeValidator } from "./RewardRequest";

export class RewardRequestDetail extends Entity {
	static typeId = entityIdMap['review.rewardRequestDetail'];

	receivedMailDate?: number;
	rewardOptionValues?: Record<string, unknown>;
	destType!: DestType;
	destName?: string;
	destZip?: string;
	destAddress?: string;
	destBuilding?: string;
	destPhone?: string;
	isTransacted!: boolean;
	transactDate?: number;
	orderNumber!: string;
	ordererName!: string;
	campaignName!: string;
	rewardName!: string;
	sku!: string;
	rac!: number;
	quantityIdx!: number;
	shippingDate!: number;
	shopReviewDate?: number;
	itemReviewDate?: number;
	requestMailDate?: number;
	followMailDate?: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			receivedMailDate: { type: 'number' },
			rewardOptionValues: { validator: isRecord },
			destType: { isMandatory: true, validator: destTypeValidator },
			destName: { type: 'string' },
			destZip: { type: 'string' },
			destAddress: { type: 'string' },
			destBuilding: { type: 'string' },
			destPhone: { type: 'string' },
			isTransacted: { isMandatory: true, type: 'boolean' },
			transactDate: { type: 'number' },
			orderNumber: { isMandatory: true, type: 'string' },
			ordererName: { isMandatory: true, type: 'string' },
			campaignName: { isMandatory: true, type: 'string' },
			rewardName: { isMandatory: true, type: 'string' },
			sku: { isMandatory: true, type: 'string' },
			rac: { isMandatory: true, type: 'number' },
			quantityIdx: { isMandatory: true, type: 'number' },
			shippingDate: { isMandatory: true, type: 'number' },
			shopReviewDate: { type: 'number' },
			itemReviewDate: { type: 'number' },
			requestMailDate: { type: 'number' },
			followMailDate: { type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			receivedMailDate: this.receivedMailDate,
			rewardOptionValues: this.rewardOptionValues,
			destType: this.destType,
			destName: this.destName,
			destZip: this.destZip,
			destAddress: this.destAddress,
			destBuilding: this.destBuilding,
			destPhone: this.destPhone,
			isTransacted: this.isTransacted,
			transactDate: this.transactDate,
			orderNumber: this.orderNumber,
			ordererName: this.ordererName,
			campaignName: this.campaignName,
			rewardName: this.rewardName,
			sku: this.sku,
			rac: this.rac,
			quantityIdx: this.quantityIdx,
			shippingDate: this.shippingDate,
			shopReviewDate: this.shopReviewDate,
			itemReviewDate: this.itemReviewDate,
			requestMailDate: this.requestMailDate,
			followMailDate: this.followMailDate,
		});
	}
}
export type RewardRequestDetailAttr = Omit<ExcludeMethod<RewardRequestDetail>, 'typeId'>;
