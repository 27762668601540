import * as s from 'superstruct';

const shopStatus = ['prepare', 'use', 'suspend', 'end'] as const;

export const YReviewShopEntityStruct = s.type({
	id: s.string(),
	clientId: s.string(),
	beginDate: s.number(),
	trialEndDate: s.number(),
	status: s.enums(shopStatus),
	statusSchedule: s.optional(s.type({
		from: s.number(),
		status: s.enums(shopStatus),
	})),
	plan: s.optional(s.string()),
	signature: s.string(),
	sendFollowAfterShippingDays: s.number(),
	sendFollowHours: s.number(),
	sendFollowMinutes: s.number(),
	excludeMailAddress: s.array(s.string()),
	shopName: s.optional(s.string()),
	companyName: s.optional(s.string()),
	logoImage: s.optional(s.string()),
	shopUrl: s.optional(s.string()),
	contactType: s.optional(s.string()),
	contactPhone: s.optional(s.string()),
	contactMail: s.optional(s.string()),
});