import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string()
	}),
	query: EmptyObjectStruct,
	body: s.object({
		formType: s.string(),
		name: s.string(),
		email: s.string(),
		subject: s.string(),
		content: s.string(),
		orderNumber: s.string(),
		shopName: s.string(),
		loginUserName: s.string(),
		loginUserMail: s.string(),
		subjectTitle: s.string(),
		navigator: s.string(),
	})
};

export const PostContactResponseStruct = s.boolean();
export type PostContactResponse = s.Infer<typeof PostContactResponseStruct>;

export type PostContactMethodParams = {
	formType: string;
	shopId: string;
	name: string;
	email: string;
	subject: string;
	content: string;
	orderNumber: string;
	shopName: string;
	loginUserName?: string;
	loginUserMail?: string;
	subjectTitle: string;
	navigator: string;
};

export function postContact(params: PostContactMethodParams): APIRequest {
	const { formType, shopId, name, email, subject, content, orderNumber, shopName, loginUserName, loginUserMail, subjectTitle, navigator } = params;
	return createAPIRequest(
		'post',
		uri`/api/user/y-contact/${shopId}`,
		{
			path: { shopId },
			body: { formType, name, email, subject, content, orderNumber, shopName, loginUserName, loginUserMail, subjectTitle, navigator }
		},
		ApiParamStructs
	);
}
