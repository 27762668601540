import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, YReviewShopEntityStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: s.object({
		shopIds: s.array(s.string()),
	}),
};
export const GetShopByIdsResponseStruct = s.array(
	YReviewShopEntityStruct
);
export type GetShopByIdsResponse = s.Infer<typeof GetShopByIdsResponseStruct>;
export type GetShopByIdsResponseValues = s.Infer<typeof YReviewShopEntityStruct>;

export type GetShopByIdsMethodParams = {
	shopIds: string[];
};

export function getShopByIds(params: GetShopByIdsMethodParams): APIRequest {
	const { shopIds } = params;
	return createAPIRequest(
		'post',
		uri`/api/y-review/shop`,
		{
			body: { shopIds },
		},
		ApiParamStructs
	);
}
