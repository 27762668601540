import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from "../../lib";
import Entity from "../Entity";
import { CCType, ccTypeValidator } from "../MailContent";
import Cookie from "./Cookie";

const DUMMY_STRING = '********';

export class Shop extends Entity {
	static typeId = entityIdMap['rakuten.shop'];

	name?: string;
	mail?: string;
	serviceSecret?: string;
	licenseKey?: string;
	licenseExpiryDate?: number;
	useServiceSquare?: boolean;
	serviceSquareLicenseKey?: string;
	serviceSquareLicenseExpiryDate?: number;
	smtpAuth?: string;
	smtpPass?: string;
	mailSenderName?: string;
	ccType?: CCType;
	mailCcBcc?: string;
	/**
	 * メールの送信を保留するかどうか
	 * メール設定に起因するメール送信失敗によって`true`がセットされる
	 * メール設定の変更時に`false`をセットする
	 */
	isMailPending?: boolean;
	shopAccountId?: string;
	shopAccountPass?: string;
	userAccountId?: string;
	userAccountPass?: string;
	latestOrderDate?: string;
	cookies?: Cookie[];
	lastReviewCheckDate?: number;
	shopUrl?: string;
	cabinetFolderId?: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: false, type: 'string' },
			mail: { isMandatory: false, type: 'string' },
			serviceSecret: { isMandatory: false, type: 'string' },
			licenseKey: { isMandatory: false, type: 'string' },
			licenseExpiryDate: { isMandatory: false, type: 'number' },
			useServiceSquare: { isMandatory: false, type: 'boolean' },
			serviceSquareLicenseKey: { isMandatory: false, type: 'string' },
			serviceSquareLicenseExpiryDate: { isMandatory: false, type: 'number' },
			smtpAuth: { isMandatory: false, type: 'string' },
			smtpPass: { isMandatory: false, type: 'string' },
			mailSenderName: { isMandatory: false, type: 'string' },
			ccType: { isMandatory: false, type: 'string', validator: ccTypeValidator },
			mailCcBcc: { isMandatory: false, type: 'string' },
			isMailPending: { isMandatory: false, type: 'boolean' },
			shopAccountId: { isMandatory: false, type: 'string' },
			shopAccountPass: { isMandatory: false, type: 'string' },
			userAccountId: { isMandatory: false, type: 'string' },
			userAccountPass: { isMandatory: false, type: 'string' },
			latestOrderDate: { type: 'string' },
			cookies: { isArray: true, model: Cookie },
			lastReviewCheckDate: { type: 'number' },
			shopUrl: { type: 'string' },
			cabinetFolderId: { type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			mail: this.mail,
			// アカウント関連の情報は他のサービスには設定されているかどうかしか出力しない
			serviceSecret: this.serviceSecret,
			licenseKey: this.licenseKey,
			licenseExpiryDate: this.licenseExpiryDate,
			useServiceSquare: this.useServiceSquare,
			serviceSquareLicenseKey: this.serviceSquareLicenseKey,
			serviceSquareLicenseExpiryDate: this.serviceSquareLicenseExpiryDate,
			smtpAuth: this.smtpAuth,
			smtpPass: this.smtpPass ? DUMMY_STRING : '',
			mailSenderName: this.mailSenderName,
			ccType: this.ccType,
			mailCcBcc: this.mailCcBcc,
			isMailPending: this.isMailPending,
			shopAccountId: this.shopAccountId,
			shopAccountPass: this.shopAccountPass ? DUMMY_STRING : '',
			userAccountId: this.userAccountId,
			userAccountPass: this.userAccountPass ? DUMMY_STRING : '',
			latestOrderDate: this.latestOrderDate,
			cookies: undefined,
			lastReviewCheckDate: this.lastReviewCheckDate,
			shopUrl: this.shopUrl,
			cabinetFolderId: this.cabinetFolderId,
		});
	}

	get enabledLicenseKey(): string | undefined {
		return this.useServiceSquare ? this.serviceSquareLicenseKey : this.licenseKey;
	}

	get enabledLicenseExpiryDate(): number | undefined {
		return this.useServiceSquare ? this.serviceSquareLicenseExpiryDate : this.licenseExpiryDate;
	}
}
export type ShopAttr = Omit<ExcludeMethod<Shop>, 'typeId'>;