import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		templateId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const DeleteTemplateResponseStruct = CommandResultStruct;
export type DeleteTemplateResponse = s.Infer<typeof DeleteTemplateResponseStruct>;

export type DeleteTemplateMethodParams = {
	shopId: string;
	templateId: string;
};

export function deleteTemplate(params: DeleteTemplateMethodParams): APIRequest {
	const { shopId, templateId } = params;
	return createAPIRequest(
		'delete',
		uri`/api/y-review/${shopId}/template/${templateId}`,
		{
			path: { shopId, templateId },
		},
		ApiParamStructs
	);
}
