import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser from '../createParser';
import { CommandResultStruct, EmptyObjectStruct, YahooMailNotCoveredStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
		notCoveredId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.partial(YahooMailNotCoveredStruct)
};

export const PutNotCoveredResponseStruct = CommandResultStruct;
export type PutNotCoveredResponse = s.Infer<typeof PutNotCoveredResponseStruct>;
export const parsePutNotCovered = createParser(ApiParamStructs.body);

export type PutNotCoveredMethodParams = {
	shopId: string;
	notCoveredId: string;
	notCovered: Partial<s.Infer<typeof YahooMailNotCoveredStruct>>;
};

export function putNotCovered(params: PutNotCoveredMethodParams): APIRequest {
	const { shopId, notCoveredId, notCovered } = params;
	return createAPIRequest(
		'put',
		uri`/api/y-review/${shopId}/notCovered/${notCoveredId}`,
		{
			path: { shopId, notCoveredId },
			body: notCovered
		},
		ApiParamStructs
	);
}
