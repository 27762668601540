import dayjs from 'dayjs';
import { Notices } from "../";
import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from "../../lib";
import Entity from "../Entity";

export const INIT_DAYS_BEFORE_LICENSE_KEY_EXPIRE = 14;
export const INIT_DAYS_BEFORE_TOKEN_EXPIRE = 7;
export const INIT_DAYS_BEFORE_PUBLIC_KEY_EXPIRE = 7;
export const INIT_DAYS_NOT_UPLOADED_REVIEW_CSV = 7;
export const BUFFER_DAYS_NOT_UPLOADED_REVIEW_CSV = 7;

export class ShopEntity extends Entity {
	static typeId = entityIdMap['notice.shop_entity'];

	/** システムアラート通知可否 */
	isSendAlert!: boolean;
	/** システムアラート通知先のメールアドレス */
	alertDestMail!: string[];
	/** システムアラート通知内容 */
	alertContentType!: string[];
	/** licenseKeyの有効期限切れの何日前に通知するか（楽天版） */
	daysBeforelicenseKeyExpire?: number;
	/** 認証情報の有効期限切れの何日前に通知するか（Yahoo版） */
	daysBeforeTokenExpire?: number;
	/** 公開鍵の有効期限切れの何日前に通知するか（Yahoo版） */
	daysBeforePublicKeyExpire?: number;
	/** レビューCSVが何日間アップロードされていない場合に通知するか */
	daysNotUploadedReviewCsv?: number;
	/** レビューCSVのアップロード日時(reviewサービスで保存する値と同じ) */
	lastReviewUploadDate?: number;

	constructor(obj: Record<string, unknown>) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			isSendAlert: { isMandatory: true, type: 'boolean' },
			alertDestMail: { isMandatory: true, isArray: true,  type: 'string' },
			alertContentType: { isMandatory: true, isArray: true,  type: 'string' },
			daysBeforelicenseKeyExpire: { isMandatory: false, type: 'number' },
			daysBeforeTokenExpire: { isMandatory: false, type: 'number' },
			daysBeforePublicKeyExpire: { isMandatory: false, type: 'number' },
			daysNotUploadedReviewCsv: { isMandatory: false, type: 'number' },
			lastReviewUploadDate: { isMandatory: false, type: 'number' },
		});
	}

	isAlertTarget(type: Notices.ContentType): boolean {
		const contentTypes = this.alertContentType.flatMap(key => Notices.ALERT_CONTENT_MAP[key] ?? []);
		return contentTypes.includes(type);
	}

	isEnableUploadReviewCsv(): boolean {
		if (!this.lastReviewUploadDate) {
			return false;
		}
		const now = dayjs();
		const diffDays = now.diff(dayjs(this.lastReviewUploadDate), 'day');
		const thresholdInBuffer = (this.daysNotUploadedReviewCsv ?? INIT_DAYS_NOT_UPLOADED_REVIEW_CSV) + BUFFER_DAYS_NOT_UPLOADED_REVIEW_CSV;
		return diffDays <= thresholdInBuffer;
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			isSendAlert: this.isSendAlert,
			alertDestMail: this.alertDestMail,
			alertContentType: this.alertContentType,
			daysBeforelicenseKeyExpire: this.daysBeforelicenseKeyExpire,
			daysBeforeTokenExpire: this.daysBeforeTokenExpire,
			daysBeforePublicKeyExpire: this.daysBeforePublicKeyExpire,
			daysNotUploadedReviewCsv: this.daysNotUploadedReviewCsv,
			lastReviewUploadDate: this.lastReviewUploadDate,
		});
	}
}
export type ShopEntityAttr = Omit<ExcludeMethod<ShopEntity>, 'typeId'>;