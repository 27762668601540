import { NIL } from "uuid";

export type NormalizedData = Record<string, unknown>;

export interface NormalizedBody {
	target: unknown;
	_normalized: NormalizedData;
}

export const hasToJSON = (val: unknown): val is { toJSON(): unknown } => {
	return typeof (val as any)?.toJSON == 'function';
};

export const isObject = (obj: unknown): obj is Record<string, unknown> => {
	return typeof obj == 'object' && obj !== null && !Array.isArray(obj);
}

export const isNormalizeTarget = (val: unknown): boolean => {
	return typeof val == 'object' && val !== null && 'id' in val && val.id !== NIL;
/*
	const SHORT_LEN = 24;

	if (Array.isArray(val)) {
		return val.length >= 1;
	} else if (typeof val == 'object') {
		return val !== null && Object.keys(val).length >= 1;
	} else if (typeof val == 'string') {
		return val.length > SHORT_LEN;
	}
	return false;
*/
}

export const isNormalized = (obj: unknown): obj is NormalizedBody => {
	const body = obj as NormalizedBody;

	return (
		typeof body === 'object' &&
		obj !== null &&
		typeof body._normalized === 'object'
	);
}

/** 正規化時の代替テキストラベル */
export function toValueType(val: unknown): string {
	if (val === null) {
		return 'null';
	}
	if (val === undefined) {
		return 'undefined';
	}
	return val.constructor.name;
}

/** DeepMap用のキー導出関数(なるべく値ごとに固有の文字列を返す) */
export function toKey(val: unknown): string {
	if (val === null) {
		return 'null';
	}
	if (val === undefined) {
		return 'undefined';
	}
	if (typeof val == 'object') {
		if ('id' in val && typeof val.id == 'string') {
			return val.id;
		} else if (Array.isArray(val)) {
			return `${val.constructor.name}[${val.length}]`;
		}
	}
	return val.constructor.name;
}
