import dayjs from 'dayjs';
import arraySupport from 'dayjs/plugin/arraySupport';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import ObjectAssert from "../ObjectAssert";
import { ExcludeMethod } from "../lib";
import Entity from "./Entity";
import { Shop } from "./rakuten";
import { ShopEntity, ShopStatus, ShopStatusHistory } from "./review";
import { Client, Payment } from "./trade";
import { User } from "./user";
dayjs.extend(arraySupport);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);

const shopPaymentStatus = ['begin', 'trial', 'none', 'unclaimed', 'waiting', 'confirmed', 'no_charge', 'unused'];
export type ShopPaymentStatus = typeof shopPaymentStatus[number];

export class ShopManagement extends Entity {

	/** 店舗ID (rakuten:shop.id|review:shop_entity.id) */
	shopId!: string;
	/** 顧客ID (trade:client.id|review:client_entity.id,shop_entity.client_id) */
	clientId!: string;
	/** rakutenサービスの店舗情報 */
	shop!: Shop;
	/** reviewサービスの店舗情報 */
	shopEntity!: ShopEntity;
	shopStatusHistory!: ShopStatusHistory[];
	/** tradeサービスの顧客情報 */
	client!: Client;
	/** tradeサービスの請求・入金情報 */
	payments!: Payment[];
	/** userサービスのアカウント */
	users?: User[];

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			shopId: { isMandatory: true, type: 'string' },
			clientId: { isMandatory: true, type: 'string' },
			shop: { isMandatory: true, model: Shop },
			shopEntity: { isMandatory: true, model: ShopEntity },
			shopStatusHistory: { isMandatory: true, isArray: true, model: ShopStatusHistory },
			client: { isMandatory: true, model: Client },
			payments: { isMandatory: true, isArray: true, model: Payment },
			users: { isMandatory: false, isArray: true, model: User },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			shopId: this.shopId,
			clientId: this.clientId,
			shop: this.shop,
			shopEntity: this.shopEntity,
			shopStatusHistory: this.shopStatusHistory,
			client: this.client,
			payments: this.payments,
			users: this.users,
		});
	}

	service(): string {
		return this.shopEntity ? 'review' : '';
	}

	includeShopName(val: string): boolean {
		return (this.shop.name || '').includes(val);
	}

	includeShopMail(val: string): boolean {
		return (this.shop.mail || '').includes(val);
	}

	shopPaymentStatus(year: number, month: number): ShopPaymentStatus {
		const date = dayjs([year, month - 1]);
		const beginDate = dayjs(this.shopEntity.beginDate);
		const beginDateStartOfMonth = beginDate.startOf('month');
		const trialEndDate = dayjs(this.shopEntity.trialEndDate);
		const shopStatus = this.shopStatus(year, month);
		if (beginDateStartOfMonth.isSame(date) && shopStatus == 'prepare') {
			return 'begin';
		} else if (date.isBetween(beginDateStartOfMonth, trialEndDate) && shopStatus == 'prepare') {
			return 'trial';
		} else if (date.isSameOrAfter(trialEndDate)) {
			const payment = this.getPayment(year, month);
			if (payment) {
				return payment.status;
			}
			return 'none';
		}
		return 'unused';
	}

	shopStatus(year: number, month: number): ShopStatus | null {
		const date = dayjs([year, month - 1]).startOf('month').valueOf();
		const statusHistory = this.shopStatusHistory.find(ssh => ssh.date === date);
		return statusHistory ? statusHistory.status : null;
	}

	getPayment(year: number, month: number): Payment | null {
		const date = dayjs([year, month - 1]);
		const trialEndDate = dayjs(this.shopEntity.trialEndDate);
		if (date.isSameOrAfter(trialEndDate)) {
			for (const payment of this.payments) {
				if (payment.date === date.valueOf()) {
					return payment;
				}
			}
		}
		return null;
	}
}
export type ShopManagementAttr = ExcludeMethod<ShopManagement>;
