import { rewardTargetStatus } from '@sasagase/types';
import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser from '../createParser';
import { EmptyObjectStruct, PageInfoStruct, PageParamsMustStruct } from '../structs';
import { uri } from '../uri';

export const GetTargetsRequestQuery = s.assign(
	s.type({
		orderNumber: s.optional(s.string()),
		status: s.optional(s.string()),
	}),
	PageParamsMustStruct,
);
const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: GetTargetsRequestQuery,
	body: EmptyObjectStruct
};

const GetTargetsResponseValueStruct = s.type({
	id: s.string(),
	shopId: s.string(),
	orders: s.array(s.type({
		number: s.string(),
		shippingDate: s.optional(s.number()),
		hasSentFollowMail: s.boolean(),
		canSendingMail: s.boolean(),
	})),
	sku: s.string(),
	campaigns: s.array(s.type({
		name: s.string(),
	})),
	hasShopReview: s.boolean(),
	hasItemReview: s.boolean(),
	status: s.enums(rewardTargetStatus)
});
export const GetTargetsResponseStruct = s.object({
	pageInfo: PageInfoStruct,
	data: s.array(
		GetTargetsResponseValueStruct
	),
});
export type GetTargetsResponse = s.Infer<typeof GetTargetsResponseStruct>;
export type GetTargetsResponseValues = s.Infer<typeof GetTargetsResponseValueStruct>;
export const parseGetTargetsQuery = createParser(ApiParamStructs.query);

export type GetTargetsMethodParams = s.Infer<typeof PageParamsMustStruct> & {
	shopId: string;
    orderNumber?: string;
    status?: 'unshipped' | 'waitingReviewing' | 'reviewExpired' | 'campaignFinished' | 'pending' | 'waitingAppling' | 'applingExpired' | 'done'
	campaignId?: string;
};

export function getTargets(params: GetTargetsMethodParams): APIRequest {
	const { shopId, ...query } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/target`,
		{
			path: { shopId },
			query: Object.fromEntries(Object.entries(query || {}).filter(([,v]) => v !== undefined)),
		},
		ApiParamStructs
	);
}
