import Cookie from './Cookie';
import { RakutenMail } from './RakutenMail';
import { Shop } from './Shop';

export * from './Cookie';
export * as Rakuten from './OrderModel';
export * from './RakutenMail';
export * from './Shop';

export const rakutenConstructors = {
	Cookie,
	RakutenMail,
	Shop,
};
