import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { UUID } from "../..";
import ObjectAssert from "../../ObjectAssert";
import { PAYMENT_DEFAULT_PLANS } from "../../const";
import { entityIdMap } from "../../const/entity-id-map";
import isUUID from "../../isUUID";
import { ExcludeMethod } from "../../lib";
import Entity from "../Entity";
dayjs.extend(isSameOrAfter);

const shopStatus = ['prepare', 'use', 'suspend', 'end'] as const;
export type ShopStatus = typeof shopStatus[number];

export const INIT_SHOP_STATUS = 'prepare';
export const USE_SHOP_STATUS = 'use';
export const ENABLED_SHOP_STATUS = ['prepare', 'use'];
export const DISABLED_SHOP_STATUS = ['suspend', 'end'];
export const END_SHOP_STATUS = 'end';

export function shopStatusValidator(val: string): val is ShopStatus {
	return (shopStatus as readonly string[]).includes(val);
}

export type StatusSchedule = {
	from: number;
	status: ShopStatus;
}

export function statusScheduleValidator(statusSchedule: Record<string, unknown>): statusSchedule is StatusSchedule {
	const from = typeof statusSchedule.from == "number";
	const status = typeof statusSchedule.status == 'string' && shopStatusValidator(statusSchedule.status)
	return from && status;
}

const contactType = ['phone', 'mail'] as const;
export type ContactType = typeof contactType[number];

export function contactTypeValidator(val: string): val is ContactType {
	return (contactType as readonly string[]).includes(val);
}

export class ShopEntity extends Entity {
	static typeId = entityIdMap['review.shop_entity'];

	clientId!: UUID;
	beginDate!: number;
	trialEndDate!: number;
	status!: ShopStatus;
	statusSchedule?: StatusSchedule
	plan?: string;
	signature!: string;
	sendFollowAfterShippingDays!: number;
	sendFollowHours!: number;
	sendFollowMinutes!: number;
	excludeMailAddress!: string[];
	shopName?: string;
	companyName?: string;
	logoImage?: string;
	shopUrl?: string;
	contactType?: string;
	contactPhone?: string;
	contactMail?: string;
	lastReviewUploadDate?: number;

	constructor(obj: unknown) {
		
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			clientId: { isMandatory: true, validator: isUUID },
			beginDate: { isMandatory: true, type: 'number' },
			trialEndDate: { isMandatory: true, type: 'number' },
			status: { isMandatory: true, type: 'string', validator: shopStatusValidator },
			statusSchedule: { isMandatory: false, validator: statusScheduleValidator },
			plan: { isMandatory: false, type: 'string' },
			signature: { isMandatory: true, type: 'string' },
			sendFollowAfterShippingDays: { isMandatory: true, type: 'number' },
			sendFollowHours: { isMandatory: true, type: 'number' },
			sendFollowMinutes: { isMandatory: true, type: 'number' },
			excludeMailAddress: { isMandatory: true, isArray: true,  type: 'string' },
			shopName: { isMandatory: false, type: 'string' },
			companyName: { isMandatory: false, type: 'string' },
			logoImage: { isMandatory: false, type: 'string' },
			shopUrl: { isMandatory: false, type: 'string' },
			contactType: { isMandatory: false, type: 'string', validator: contactTypeValidator },
			contactPhone: { isMandatory: false, type: 'string' },
			contactMail: { isMandatory: false, type: 'string' },
			lastReviewUploadDate: { type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			clientId: this.clientId,
			beginDate: this.beginDate,
			trialEndDate: this.trialEndDate,
			status: this.status,
			statusSchedule: this.statusSchedule,
			plan: this.plan,
			signature: this.signature,
			sendFollowAfterShippingDays: this.sendFollowAfterShippingDays,
			sendFollowHours: this.sendFollowHours,
			sendFollowMinutes: this.sendFollowMinutes,
			excludeMailAddress: this.excludeMailAddress,
			shopName: this.shopName,
			companyName: this.companyName,
			logoImage: this.logoImage,
			shopUrl: this.shopUrl,
			contactType: this.contactType,
			contactPhone: this.contactPhone,
			contactMail: this.contactMail,
			lastReviewUploadDate: this.lastReviewUploadDate,
		});
	}

	isEnable(date = Date.now()): boolean {
		return ENABLED_SHOP_STATUS.includes(this.getStatus(date));
	}

	getStatus(date = Date.now()): ShopStatus {
		if (this.statusSchedule && dayjs(date).isSameOrAfter(dayjs(this.statusSchedule.from))) {
			return this.statusSchedule.status;
		}
		return this.status;
	}

	getPlan(): string {
		return this.plan || PAYMENT_DEFAULT_PLANS['review'];
	}
}
export type ShopEntityAttr = Omit<ExcludeMethod<ShopEntity>, 'typeId'>;
export type AddShopEntityAttr = Omit<ExcludeMethod<ShopEntity>, 'id' | 'typeId' | 'plan' | 'signature' | 'sendFollowAfterShippingDays' | 'sendFollowHours' | 'sendFollowMinutes' | 'excludeMailAddress'>