import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetTwoFactorAuthCodeResponseStruct = 	s.object({
	qrCodeUrl: s.string(),
	secretKey: s.string(),
});
export type GetTwoFactorAuthCodeResponse = s.Infer<typeof GetTwoFactorAuthCodeResponseStruct>;
export type GetTwoFactorAuthCodeResponseValues = s.Infer<typeof UserStruct>;

export type GetTwoFactorAuthCodeMethodParams = null;

export function getTwoFactorAuthCode(): APIRequest {
	return createAPIRequest(
		'get',
		uri`/api/user/two-factor-auth/code`,
		{},
		ApiParamStructs
	);
}
