import SasagaseError from "@sasagase/error";
import dayjs from "dayjs";
import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from "../../lib";
import Entity from "../Entity";
import { BodyElement, bodyElementValidator } from "./BodyElement";
import { ItemGroup } from './ItemGroup';
import { Reward } from './Reward';
import { DestType, destTypeValidator } from "./RewardRequest";

export const campaignStateList = ['draft', 'ready', 'inProgress', 'waitingReview', 'finished', 'cancelled'] as const;
export type CampaignState = typeof campaignStateList[number];

const enCollator = new Intl.Collator('en');

interface StateInfo {
	name: string;
	level: number;
}
export const campaignStateInfos: Record<CampaignState, StateInfo> = {
	'draft': { name: '実施不可', level: 0 },
	'ready': { name: '実施前', level: 1 },
	'inProgress': { name: '実施中', level: 2 },
	'waitingReview': { name: '終了(レビュー待ち)', level: 3 },
	'finished': { name: '終了', level: 4 },
	'cancelled': { name: '終了(キャンセル)', level: 4 },
} as const;

export type CampaignMail = {
	disable?: boolean;
	subject: string;
	body: BodyElement[];
	signature: string;
};

export function mailValidator(mail: Record<string, unknown>): mail is CampaignMail {
	const subject = typeof mail.subject == 'string';
	const signature = typeof mail.signature == 'string';
	const body = Array.isArray(mail.body) && mail.body.every(bodyElementValidator);
	return subject && signature && body;
}

export class Campaign extends Entity {
	static typeId = entityIdMap['review.campaign'];

	/** 対象商品 */
	itemGroup!: ItemGroup;
	/** 特典 */
	rewards!: Reward[];

	/** キャンペーン優先値(大きいものほど優先される) */
	priority!: number;

	/** キャンペーン名 */
	name!: string;
	/** キャンペーン開始日時(epoc ms) */
	beginDate?: number;
	/** キャンペーン終了日時(epoc ms) */
	endDate?: number;
	/** レビュー受付終了日時(epoc ms) */
	reviewDeadlineDate?: number;
	/** レビュー受付終了までの日数 */
	reviewDeadlineDays?: number;
	/** キャンペーン無期限 */
	isNeverEnd!: boolean;
	/** 期限を設定する（対象日時まで）が設定されているか */
	isCutDate!: boolean;
	/** 期限を設定する（商品発送後の指定日数期間）が設定されているか */
	isCutDays!: boolean;
	/** 商品レビューが必要か */
	isItemReviewRequired!: boolean;
	/** ショップレビューが必要か */
	isShopReviewRequired!: boolean;
	/** 商品レビュー・ショップレビューのどちらかが必要か */
	isAnyReviewRequired!: boolean;
	/** 複数購入時に特典も複数にするか */
	canManyRewards!: boolean;
	/** 特典の配送先を選ばせるか */
	canChooseDest!: boolean;
	/** 配送先の初期値 */
	defaultDestType!: DestType;
	/** レビュー確認から申請締め切りまでの日数 */
	applicationClosingDays?: number;
	/** レビュー確認後に申請締め切りを設けるか */
	shouldCloseApplication!: boolean;
	/** フォローメール */
	followMail!: CampaignMail;
	/** フォーム案内メール */
	requestMail!: CampaignMail;
	/** 特典受付メール */
	receivedMail!: CampaignMail;
	/** 下書きかどうか */
	isDraft!: boolean;

	/** ステータスが'inProgress'になった日時 */
	inProgressDate?: number;
	/** ステータスが'waitingReview'になった日時 */
	waitingReviewDate?: number;
	/** ステータスが'finished'になった日時 */
	finishedDate?: number;
	/** ステータスが'cancelled'になった日時 */
	cancelledDate?: number;

	#targetSkus?: Set<string>;
	
	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			itemGroup: { isMandatory: true, model: ItemGroup },
			rewards: { isMandatory: true, isArray: true, model: Reward },
			priority: { isMandatory: true, type: 'number' },
			name: { isMandatory: true, type: 'string' },
			beginDate: { type: 'number' },
			endDate: { type: 'number' },
			reviewDeadlineDate: { type: 'number' },
			reviewDeadlineDays: { type: 'number' },
			isNeverEnd: { isMandatory: true, type: 'boolean' },
			isCutDate: { isMandatory: true, type: 'boolean' },
			isCutDays: { isMandatory: true, type: 'boolean' },
			isItemReviewRequired: { isMandatory: true, type: 'boolean' },
			isShopReviewRequired: { isMandatory: true, type: 'boolean' },
			isAnyReviewRequired: { isMandatory: true, type: 'boolean' },
			canManyRewards: { isMandatory: true, type: 'boolean' },
			canChooseDest: { isMandatory: true, type: 'boolean' },
			defaultDestType: { isMandatory: true, validator: destTypeValidator },
			applicationClosingDays: { type: 'number' },
			shouldCloseApplication: { isMandatory: true, type: 'boolean' },
			followMail: { isMandatory: true, validator: mailValidator },
			requestMail: { isMandatory: true, validator: mailValidator },
			receivedMail: { isMandatory: true, validator: mailValidator },
			isDraft: { isMandatory: true, type: 'boolean' },
			inProgressDate: { type: 'number' },
			waitingReviewDate: { type: 'number' },
			finishedDate: { type: 'number' },
			cancelledDate: { type: 'number' },
		});

		// isDraftが設定されていない場合は日付の関連を検査する
		if (!this.isDraft && !this.isValidDate()) {
			throw new SasagaseError('INVALID_PROPERTY_VALUE', '不正な日付です');
		}
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			itemGroup: this.itemGroup,
			rewards: this.rewards,
			priority: this.priority,
			name: this.name,
			beginDate: this.beginDate,
			endDate: this.endDate,
			reviewDeadlineDate: this.reviewDeadlineDate,
			reviewDeadlineDays: this.reviewDeadlineDays,
			isNeverEnd: this.isNeverEnd,
			isCutDate: this.isCutDate,
			isCutDays: this.isCutDays,
			isItemReviewRequired: this.isItemReviewRequired,
			isShopReviewRequired: this.isShopReviewRequired,
			isAnyReviewRequired: this.isAnyReviewRequired,
			canManyRewards: this.canManyRewards,
			canChooseDest: this.canChooseDest,
			defaultDestType: this.defaultDestType,
			applicationClosingDays: this.applicationClosingDays,
			shouldCloseApplication: this.shouldCloseApplication,
			followMail: this.followMail,
			requestMail: this.requestMail,
			receivedMail: this.receivedMail,
			isDraft: this.isDraft,
			inProgressDate: this.inProgressDate,
			waitingReviewDate: this.waitingReviewDate,
			finishedDate: this.finishedDate,
			cancelledDate: this.cancelledDate,
		});
	}

	getState(): CampaignState {
		if (this.cancelledDate) {
			return 'cancelled';
		} else if (this.finishedDate) {
			return 'finished';
		} else if (this.waitingReviewDate) {
			return 'waitingReview';
		} else if (this.inProgressDate) {
			return 'inProgress';
		} else if (!this.isDraft) {
			return 'ready';
		} else {
			return 'draft';
		}
	}

	isStateOnAfter(state: CampaignState): boolean {
		return campaignStateInfos[state].level <= campaignStateInfos[this.getState()].level;
	}

	isStateOnBefore(state: CampaignState): boolean {
		return campaignStateInfos[state].level >= campaignStateInfos[this.getState()].level;
	}

	isAlive(): boolean {
		return ['inProgress', 'waitingReview'].includes(this.getState());
	}

	/** 申込期限を取得 */
	getApplicationClosingDate(begin?: Date | number): number {
		if (!this.shouldCloseApplication) {
			return Infinity;
		}
		return dayjs(begin).add(this.applicationClosingDays ?? 0, 'day').valueOf();
	}

	getReviewDateExpiration(shippingDate: number): number {
		if (isNaN(shippingDate)) {
			return NaN;
		} else if (this.cancelledDate) {
			return 0;
		} else if (this.isCutDate && this.reviewDeadlineDate) {
			return this.reviewDeadlineDate;
		} else if (this.isCutDays && this.reviewDeadlineDays) {
			return dayjs(shippingDate).endOf('day').add(this.reviewDeadlineDays - 1, 'day').valueOf();
		} else {
			return Infinity;
		}
	}

	isValidDate(): boolean {
		const begin = this.beginDate ?? 0;
		const end = this.isNeverEnd ? Infinity : (this.endDate ?? 0);
		const dead = this.isCutDate ? (this.reviewDeadlineDate ?? 0) : Infinity;
		return begin < end && end <= dead;
	}

	hasTargetSku(skus: string[]): boolean {
		return this.itemGroup.isTarget(skus);
	}

	comparePriority(campaign: Campaign): number {
		return campaign.priority - this.priority || enCollator.compare(this.id, campaign.id);
	}
}

export type CampaignAttr = ExcludeMethod<Campaign>;
