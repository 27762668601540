import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		notCoveredId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const DeleteNotCoveredResponseStruct = CommandResultStruct;
export type DeleteNotCoveredResponse = s.Infer<typeof DeleteNotCoveredResponseStruct>;

export type DeleteNotCoveredMethodParams = {
	shopId: string;
	notCoveredId: string;
};

export function deleteNotCovered(params: DeleteNotCoveredMethodParams): APIRequest {
	const { shopId, notCoveredId } = params;
	return createAPIRequest(
		'delete',
		uri`/api/y-review/${shopId}/notCovered/${notCoveredId}`,
		{
			path: { shopId, notCoveredId },
		},
		ApiParamStructs
	);
}
