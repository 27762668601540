import { Notices } from '@sasagase/types';
import * as s from 'superstruct';

const contentLevel = ['NOTICE', 'WARNING', 'ALERT'] as const;
export const ContentLevelStruct = s.enums(contentLevel);

export const ContentStruct = s.type({
	id: s.string(),
	service: s.string(),
	type: s.enums(Notices.contentType),
	sourceId: s.optional(s.string()),
	date: s.number(),
	title: s.optional(s.string()),
	content: s.optional(s.string()),
	link: s.optional(s.string()),
	disabled: s.optional(s.boolean()),
	values: s.optional(s.record(s.string(), s.union([s.string(), s.number()])))
});

export const NoticeShopEntityStruct = s.type({
	id: s.string(),
	isSendAlert: s.boolean(),
	alertDestMail: s.array(s.string()),
	alertContentType: s.array(s.string()),
	daysBeforelicenseKeyExpire: s.optional(s.number()),
	daysBeforeTokenExpire: s.optional(s.number()),
	daysBeforePublicKeyExpire: s.optional(s.number()),
	daysNotUploadedReviewCsv: s.optional(s.number()),
	lastReviewUploadDate: s.optional(s.number()),
});