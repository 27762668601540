import { AdminShop } from './AdminShop';
import { AdminShopEntity } from './AdminShopEntity';
import { AdminShopManagement } from './AdminShopManagement';

export * from './AdminShop';
export * from './AdminShopEntity';
export * from './AdminShopManagement';

export const AdminConstructors = {
	AdminShop,
	AdminShopEntity,
	AdminShopManagement,
};
