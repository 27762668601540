import * as s from 'superstruct';

export const ShopStruct = s.type({
	id: s.string(),
	name: s.optional(s.string()),
	yahooId: s.optional(s.string()),
	yahooPass: s.optional(s.string()),
	yahooStoreAccount: s.optional(s.string()),
	publicKey: s.optional(s.string()),
	publicKeyVersion: s.optional(s.string()),
	publicKeyExpiration: s.optional(s.number()),
	latestOrderDate: s.optional(s.string()),
	lastReviewCheckDate: s.optional(s.number()),
});

export const ShopTokenExpirationStruct = s.type({
	accessToken: s.optional(s.number()),
	refreshToken: s.optional(s.number()),
});