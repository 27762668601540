import SasagaseError from '@sasagase/error';
import { isRecord } from './isRecord';

type AnyObject = Record<string | number, unknown>;
type AnyKey = keyof AnyObject;

interface TypenameMap {
	'string': string;
	'number': number;
	'bigint': bigint;
	'boolean': boolean;
	'symbol': symbol;
	'undefined': undefined;
	'object': AnyObject;
	'function': () => unknown;
}
type Typename = keyof TypenameMap;

type TypeValidator<T extends U, U = unknown> = (value: U, obj: AnyObject) => value is T;

/**
 * 値をどのように検証するか設定する
 *
 * @template T 戻り値の型
 * @template K type指定する場合のtypeof文字列
 */
type ValidateOption<T extends TypenameMap[K], K extends Typename> = {
	isMandatory?: boolean;
	isArray?: boolean;
	isNullable?: boolean;
	type?: K;
	validator?: K extends Typename ? TypeValidator<T, TypenameMap[K]> : TypeValidator<T, unknown>;
	model?: {
		new(obj: Record<string, unknown>): T;
		create?: undefined;
	} | {
		name: string;
		create(obj: Record<string, unknown>): T;
	};
};

type ValidatorRecord = Record<string, ValidateOption<any, Typename>>;

type ValidatorReturnType<V extends ValidateOption<any, any>> =
	V extends {
			validator: TypeValidator<infer R, any>;
		}
	? R
	: V extends {
			type: infer K;
		}
	? (K extends Typename
		? TypenameMap[K]
		: never)
	: never;

type ValidValues<O extends ValidatorRecord> = {
	[P in keyof O]: ValidatorReturnType<O[P]>;
};

export default class ObjectAssert {
	#obj: AnyObject;

	constructor(obj: unknown) {
		if (!isRecord(obj)) {
			const jsonValue = JSON.stringify(obj);
			throw new SasagaseError('INVALID_VALUE', `オブジェクトではない値 ${jsonValue} が指定されました。`);
		}
		this.#obj = obj;
	}

	private assertExistance(key: AnyKey): void {
		if (!(key in this.#obj)) {
			throw new SasagaseError('NOT_EXIST_PROPERTY', `${key}プロパティがありません`);
		}
	}

	private isType<K extends keyof TypenameMap>(value: unknown, type: K): value is TypenameMap[K] {
		return typeof value == type;
	}

	private assertType<K extends keyof TypenameMap>(key: AnyKey, expectType: K, value: unknown): void {
		if (!this.isType(value, expectType)) {
			const actuallyType = typeof value;
			const jsonValue = JSON.stringify(value);
			throw new SasagaseError('INVALID_PROPERTY_TYPE', `${key}プロパティに${actuallyType}型の値 ${jsonValue} が指定されました。${expectType}型の値を指定する必要があります`);
		}
	}

	private assertObject(key: AnyKey, expectObjectType: string, value: unknown): asserts value is AnyObject {
		if (!this.isType(value, 'object')) {
			const actuallyType = typeof value;
			const jsonValue = JSON.stringify(value);
			throw new SasagaseError('INVALID_PROPERTY_TYPE', `${key}プロパティに${actuallyType}型の値 ${jsonValue} が指定されました。${expectObjectType}型の値を指定する必要があります`);
		}
	}

	private assertValid(key: AnyKey, validator: TypeValidator<any, any>, value: unknown): void {
		if (!validator(value, this.#obj)) {
			const jsonValue = JSON.stringify(value);
			throw new SasagaseError('INVALID_PROPERTY_VALUE', `${key}プロパティに不正な形式の値 ${jsonValue} が指定されました`);
		}
	}

	get<T extends TypenameMap[K], K extends Typename>(key: AnyKey, option: ValidateOption<T, K> & {isMandatory:true}): T;
	get<T extends TypenameMap[K], K extends Typename>(key: AnyKey, option: ValidateOption<T, K> & {isNullable:true}): T | null;
	get<T extends TypenameMap[K], K extends Typename>(key: AnyKey, option: ValidateOption<T, K> & {isArray:true}): T[] | undefined;
	get<T extends TypenameMap[K], K extends Typename>(key: AnyKey, option: ValidateOption<T, K> & {isMandatory:true; isArray:true}): T[];
	get<T extends TypenameMap[K], K extends Typename>(key: AnyKey, option: ValidateOption<T, K>): T | undefined;
	get<T extends TypenameMap[K], K extends Typename>(key: AnyKey, option: ValidateOption<T, K> & {isMandatory?:boolean; isArray?:boolean}): T | T[] | undefined | null {
		const asserts = (val: unknown): T => {
			if (option.type) {
				this.assertType(key, option.type, val);
			}
			if (option.validator) {
				this.assertValid(key, option.validator, val);
			}
			if (option.model) {
				this.assertObject(key, option.model.name, val);

				if (val instanceof (option.model as any)) {
					return val as T;
				}
				if (option.model.create) {
					return option.model.create(val);
				}
				return new option.model(val);
			}
			return val as T;
		};
		const val = this.#obj[key];

		if (!option.isMandatory && val === undefined) {
			return undefined;
		}
		if (option.isNullable && val === null) {
			return null;
		}
		this.assertExistance(key);

		if (option.isArray) {
			this.assertValid(key, Array.isArray, val);
			return (val as unknown[]).map(item => asserts(item));
		} else {
			return asserts(val);
		}
	}

	assign<O extends ValidatorRecord>(targetObject: Record<string, any>, validators: O): typeof targetObject {
		for (const [key, validatorOpt] of Object.entries(validators)) {
			targetObject[key] = this.get(key, validatorOpt);
		}
		return targetObject;
	}

	/* TODO:
		gets({
			abc: {
				type: 'number',
				validator: function (v: **string**): v is 'abc' {
					return v == 'abc';
				}
			}
		})
		この例のように type と validator の引数の型が違う場合はTypeScriptでエラーにしたい
		また、 type を指定しない場合、 validator の引数の型は unknown のみ受け付けるようにしたい
	*/
	gets<O extends ValidatorRecord>(validators: O): ValidValues<O> {
		return this.assign({}, validators) as ValidValues<O>;
	}
}
