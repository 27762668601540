import { ccTypes } from '@sasagase/types';
import * as s from 'superstruct';
export * from './mail/structs';
export * from './notice/structs';
export * from './user/structs';
export * from './y-review/structs';
export * from './yahoo/structs';

export const EmptyObjectStruct = s.optional(s.nullable(s.object({})));
export const EventSequenceQueryStruct = s.object({ seq: s.optional(s.string()) });

export const EventSequenceStruct = s.string();
export const CommandResultStruct = s.type({
	seq: EventSequenceStruct,
});

export const PageParamsStruct = s.object({
	pagination: s.optional(s.string()),
	page: s.optional(s.string()),
	perPage: s.optional(s.string()),
});
// ページング必須の場合は下記を指定（page,perPageはバックエンド側で補完）
export const PageParamsMustStruct = s.object({
	pagination: s.string(),
	page: s.optional(s.string()),
	perPage: s.optional(s.string()),
});

export const PageInfoStruct = s.object({
	page: s.number(),
	pages: s.number(),
	perPage: s.number(),
	recordsTotal: s.number(),
	hasNextPage: s.boolean(),
	hasPrevPage: s.boolean()
});

export const MailContentStruct = s.type({
	from: s.string(),
	fromName: s.optional(s.string()),
	ccType: s.optional(s.enums(ccTypes)),
	to: s.string(),
	subject: s.string(),
	body: s.string(),
	replaceTag: s.record(s.string(), s.string())
});

export const queryNumber = s.coerce(s.number(), s.string(), (val) => Number(val));
export const queryBoolean = s.coerce(s.boolean(), s.string(), (val) => val === 'false' ? false : Boolean(val));
