import { entityIdMap } from "../../const/entity-id-map";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export const termTypes = ['category', 'tag'] as const;
export type TermTypes = typeof termTypes[number];

export function termTypeValidator(val: string): val is TermTypes {
	return (termTypes as readonly string[]).includes(val);
}

export class Term extends Entity {
	static typeId = entityIdMap['help.term'];

	/** 種別（category:カテゴリー, tag:タグ） */
	type!: TermTypes
	/** カテゴリー・タグ名 */
	name!: string;
	/** スラッグ */
	slug?: string;
	/** 並び順 */
	sort?: number;
	/** 説明 */
	description?: string;
	/** 親 */
	parent?: Term;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			type: { isMandatory: true, type: 'string', validator: termTypeValidator },
			name: { isMandatory: true, type: 'string' },
			slug: { type: 'string' },
			sort: { type: 'number' },
			description: { type: 'string' },
			parent: { model: Term }
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			type: this.type,
			name: this.name,
			slug: this.slug,
			sort: this.sort,
			description: this.description,
			parent: this.parent,
		});
	}

	isParent(): boolean {
		return !this.parent;
	}

	isCategory(): boolean {
		return this.type === 'category';
	}

	isTag(): boolean {
		return this.type === 'tag';
	}

	getSlug(): string {
		return this.slug || this.id;
	}
}
