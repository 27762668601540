import { Infer, literal, record, string } from "superstruct";
import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const/entity-id-map";
import { isRecord } from "../../isRecord";
import keyValidator from "../../keyValidator";
import { ExcludeMethod, validator } from '../../lib';
import Entity from "../Entity";

export const IssueFreqType = ['individual', 'daily', 'weekly', 'monthly'] as const;
export type IssueFreqType = typeof IssueFreqType[number];
function IssueFreqTypeValidator(val: string): val is IssueFreqType {
	return (IssueFreqType as readonly string[]).includes(val);
}
export const couponCategory = {
	ladiesfashion: "レディースファッション",
	mensfashion: "メンズファッション",
	sports: "スポーツ、アウトドア",
	baby: "ベビー、キッズ",
	interior: "インテリア、生活雑貨",
	beauty: "コスメ、香水",
	health: "ダイエット、健康",
	food: "食品、ドリンク、お酒",
	kaden: "家電",
	hobby: "趣味",
	autogoods: "自転車、車、バイク",
	pet_diy_flower: "ペット、DIY、花",
	watch: "腕時計、アクセサリー",
	others: "その他",
};
const couponCategoryIds = Object.keys(couponCategory);
export type CouponCategory = typeof couponCategoryIds[number];
function couponCategoryValidator(val: string): val is CouponCategory {
	return (couponCategoryIds as readonly string[]).includes(val);
}
export const couponDiscountType = {
	'1': "定額値引き",
	'2': "定率値引き",
	'3': "送料無料",
};
const CouponDiscountType = Object.keys(couponDiscountType);
export type CouponDiscountType = typeof CouponDiscountType[number];
function couponDiscountTypeValidator(val: string): val is CouponDiscountType {
	return (CouponDiscountType as readonly string[]).includes(val);
}
export const couponImageType = ['input','template','upload'] as const;
type CouponImageType = typeof couponImageType[number];
function couponImageTypeValidator(val: string): val is CouponImageType {
	return (couponImageType as readonly string[]).includes(val);
}
export const couponOrderType = {
	'0': "指定しない",
	'1': "注文金額指定",
	'2': "注文個数指定",
};
const CouponOrderType = Object.keys(couponOrderType);
export type CouponOrderType = typeof CouponOrderType[number];
function couponOrderTypeValidator(val: string): val is CouponOrderType {
	return (CouponOrderType as readonly string[]).includes(val);
}
export const couponItemDesignation = {
	'3': "ストア内全商品",
	'1': "クーポン適用商品（最大1,000商品）を指定",
	'4': "商品タグ（最大10件）を指定",
};
const CouponItemDesignation = Object.keys(couponItemDesignation);
export type CouponItemDesignation = typeof CouponItemDesignation[number];
function couponItemDesignationValidator(val: string): val is CouponItemDesignation {
	return (CouponItemDesignation as readonly string[]).includes(val);
}

export const ValidPeriodType = ['days', 'nextMonth', 'secondNextMonth'] as const;
export type ValidPeriodType = typeof ValidPeriodType[number];
function ValidPeriodTypeValidator(val: string): val is ValidPeriodType {
	return (ValidPeriodType as readonly string[]).includes(val);
}
export abstract class YahooRewardBase extends Entity {
	static typeId = entityIdMap['yReview.reward'];

	static create(obj: { isCoupon: true }): YahooCouponReward;
	static create(obj: { isCoupon?: false }): YahooItemReward;
	static create(obj: Record<string, unknown>): YahooCouponReward | YahooItemReward {
		if (obj.isCoupon) {
			return new YahooCouponReward(obj);
		} else {
			return new YahooItemReward(obj);
		}
	}

	name!: string;
	description!: string;
	isCoupon!: boolean;

	constructor(obj: Record<string, unknown>) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: true, type: 'string' },
			description: { isMandatory: true, type: 'string' },
			isCoupon: { isMandatory: true, type: 'boolean' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			description: this.description,
			isCoupon: this.isCoupon,
		});
	}

	isAvailable(): boolean {
		return true;
	}
}

const csvUserSettingsStruct = record(string(), record(string(), string()));
const isCsvClientType = keyValidator(['orderer', 'user']);

export class YahooItemReward extends YahooRewardBase {
	isCoupon = false as const;
	image!: string;
	quantity?: number;
	isUnlimited!: boolean;
	csvFormat!: string;
	/**
	 * 元の注文で注文者と送り先が異なるとき、送り状CSVの発送元に記載する内容
	 * orderer => 元の注文の注文者
	 * user => ユーザ設定値(店舗住所等を設定しているはず)
	 */
	csvClientType!: string;
	csvUserSettings!: Infer<typeof csvUserSettingsStruct>;
	optionAttributes?: Record<string, unknown>;

	constructor(obj: Record<string, unknown>) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			isCoupon: { isMandatory: true, validator: validator(literal(false)) },
			image: { isMandatory: true, type: 'string' },
			quantity: { isMandatory: false, type: 'number' },
			isUnlimited: { isMandatory: true, type: 'boolean' },
			csvFormat: { isMandatory: true, type: 'string' },
			csvClientType: {isMandatory: true, validator: isCsvClientType },
			csvUserSettings: { isMandatory: true, validator: validator(csvUserSettingsStruct) },
			optionAttributes: { validator: isRecord },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			isCoupon: this.isCoupon,
			image: this.image,
			quantity: this.quantity,
			isUnlimited: this.isUnlimited,
			csvFormat: this.csvFormat,
			csvClientType: this.csvClientType,
			csvUserSettings: this.csvUserSettings,
			optionAttributes: this.optionAttributes,
		});
	}

	isAvailable(): boolean {
		return this.isUnlimited || (this.quantity ?? 0) > 0;
	}
}

export class YahooCouponReward extends YahooRewardBase {
	isCoupon = true as const;
	issueFreq!: IssueFreqType;
	categoryId!: CouponCategory;
	discountType!: CouponDiscountType;
	discountPrice?: number;
	discountRate?: number;
	validPeriod!: ValidPeriodType;
	validDays!: number;
	dispFlg!: boolean;
	couponImageType!: CouponImageType;
	couponImageInput?: string;	// ファイル名指定
	couponImageTemplate?: string;	// テンプレート指定
	couponImageUpload?: string;	// アップロード
	couponImageName!: string;
	linkUrl?: string;
	canCombineUse!: boolean;
	countUserUseLimit?: number;
	countAllUseLimit?: number;
	orderType!: CouponOrderType;
	orderPrice?: number;
	orderCount?: number;
	itemDesignation!: CouponItemDesignation;
	targetItemIdStrings?: string;
	targetItemTagStrings?: string;

	constructor(obj: Record<string, unknown>) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			isCoupon: { isMandatory: true, validator: validator(literal(true)) },
			issueFreq: { isMandatory: true, validator: IssueFreqTypeValidator },
			categoryId: { isMandatory: true, type: 'string', validator: couponCategoryValidator },
			discountType: { isMandatory: true, type: 'string', validator: couponDiscountTypeValidator },
			discountPrice: { isMandatory: false, type: 'number' },
			discountRate: { isMandatory: false, type: 'number' },
			validPeriod: { isMandatory: true, validator: ValidPeriodTypeValidator },
			validDays: { isMandatory: true, type: 'number' },
			dispFlg: { isMandatory: true, type: 'boolean' },
			couponImageType: { isMandatory: true, type: 'string', validator: couponImageTypeValidator },
			couponImageInput: { isMandatory: false, type: 'string' },
			couponImageTemplate: { isMandatory: false, type: 'string' },
			couponImageUpload: { isMandatory: false, type: 'string' },
			couponImageName: { isMandatory: true, type: 'string' },
			linkUrl: { isMandatory: false, type: 'string' },
			canCombineUse: { isMandatory: true, type: 'boolean' },
			countUserUseLimit: { isMandatory: false, type: 'number' },
			countAllUseLimit: { isMandatory: false, type: 'number' },
			orderType: { isMandatory: true, type: 'string', validator: couponOrderTypeValidator },
			orderPrice: { isMandatory: false, type: 'number' },
			orderCount: { isMandatory: false, type: 'number' },
			itemDesignation: { isMandatory: true, type: 'string', validator: couponItemDesignationValidator },
			targetItemIdStrings: { isMandatory: false, type: 'string' },
			targetItemTagStrings: { isMandatory: false, type: 'string' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			isCoupon: this.isCoupon,
			issueFreq: this.issueFreq,
			categoryId: this.categoryId,
			discountType: this.discountType,
			discountPrice: this.discountPrice,
			discountRate: this.discountRate,
			validPeriod: this.validPeriod,
			validDays: this.validDays,
			dispFlg: this.dispFlg,
			couponImageType: this.couponImageType,
			couponImageInput: this.couponImageInput,
			couponImageTemplate: this.couponImageTemplate,
			couponImageUpload: this.couponImageUpload,
			couponImageName: this.couponImageName,
			linkUrl: this.linkUrl,
			canCombineUse: this.canCombineUse,
			countUserUseLimit: this.countUserUseLimit,
			countAllUseLimit: this.countAllUseLimit,
			orderType: this.orderType,
			orderPrice: this.orderPrice,
			orderCount: this.orderCount,
			itemDesignation: this.itemDesignation,
			targetItemIdStrings: this.targetItemIdStrings,
			targetItemTagStrings: this.targetItemTagStrings,
		});
	}
}

export type YahooRewardAttr = ExcludeMethod<YahooReward>;
export type YahooReward = YahooCouponReward | YahooItemReward;
