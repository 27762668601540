import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: s.object({
		mail: s.string(),
		service: s.optional(s.string()),
	})
};

export const RecoverPasswordResponseStruct = s.boolean();
export type RecoverPasswordResponse = s.Infer<typeof RecoverPasswordResponseStruct>;

export type RecoverPasswordMethodParams = s.Infer<typeof ApiParamStructs.body>;

export function recoverPassword(params: RecoverPasswordMethodParams): APIRequest {
	return createAPIRequest(
		'post',
		uri`/api/user/password/recover`,
		{
			body: params,
		},
		ApiParamStructs
	);
}
