import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: s.object({
		mail: s.string(),
		pass: s.string()
	})
};

export const UserLoginResponseStruct = s.object({
	user: s.nullable(UserStruct),
	expire: s.optional(s.number()),
	progressTwoFactorAuth: s.optional(s.boolean()),
	sessionId: s.optional(s.string()),
});
export type UserLoginResponse = s.Infer<typeof UserLoginResponseStruct>;

export type UserLoginMethodParams = s.Infer<typeof ApiParamStructs.body>;

export function userLogin(params: UserLoginMethodParams): APIRequest {
	return createAPIRequest(
		'post',
		uri`/api/user/login`,
		{
			body: params,
		},
		ApiParamStructs
	);
}
