import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser from '../createParser';
import { EmptyObjectStruct, SendTestMailOptionStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: SendTestMailOptionStruct
};

export const SendTestMailResponseStruct = s.object({
	result: s.boolean(),
	error: s.optional(s.string()),
});
export type SendTestMailResponse = s.Infer<typeof SendTestMailResponseStruct>;
export const parseSendTestMail = createParser(ApiParamStructs.body);

export type SendTestMailMethodParams = {
	shopId: string;
	to: string;
	subject: string;
	body: string;
};

export function sendTestMail(params: SendTestMailMethodParams): APIRequest {
	const { shopId, to, subject, body } = params;
	return createAPIRequest(
		'post',
		uri`/api/mail/${shopId}/test`,
		{
			path: { shopId },
			body: { to, subject, body },
		},
		ApiParamStructs
	);
}
