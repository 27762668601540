import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser from '../createParser';
import { CommandResultStruct, EmptyObjectStruct, YahooMailNotCoveredStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: YahooMailNotCoveredStruct
};

export const PostNotCoveredResponseStruct = CommandResultStruct;
export type PostNotCoveredResponse = s.Infer<typeof PostNotCoveredResponseStruct>;
export const parsePostNotCovered = createParser(ApiParamStructs.body);

export type PostNotCoveredMethodParams = {
	shopId: string;
	notCovered: s.Infer<typeof YahooMailNotCoveredStruct>;
};

export function postNotCovered(params: PostNotCoveredMethodParams): APIRequest {
	const { shopId, notCovered } = params;
	return createAPIRequest(
		'post',
		uri`/api/y-review/${shopId}/notCovered`,
		{
			path: { shopId },
			body: notCovered
		},
		ApiParamStructs
	);
}
