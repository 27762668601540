import { entityIdMap } from "../../const/entity-id-map";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class UserMailTemplate extends Entity {
	static typeId = entityIdMap['user.template'];

	sendType!: string;
	subject!: string;
	body!: string;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			sendType: { isMandatory: true, type: 'string' },
			subject: { isMandatory: true, type: 'string' },
			body: { isMandatory: true, type: 'string' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			sendType: this.sendType,
			subject: this.subject,
			body: this.body,
		});
	}
}