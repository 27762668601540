import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod, NeverProp } from '../../lib';
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { Orderer } from "./Orderer";
import { OrderItem } from './OrderItem';

export class RakutenOrder extends Entity {
	static typeId = entityIdMap['review.order'];

	orderItems!: OrderItem[];
	orderer!: Orderer;
	number!: string;
	/** 注文日(YYYY-MM-DD) */
	orderDate!: string;
	shippingDate?: number;
	followMailDate?: number;
	hasSentFollowMail!: boolean;
	canSendingMail!: boolean;
	isRakutenMember!: boolean;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			orderItems: { isMandatory: true, isArray: true, model: OrderItem },
			orderer: { isMandatory: true, model: Orderer },
			number: { isMandatory: true, type: 'string' },
			orderDate: { isMandatory: true, type: 'string' },
			shippingDate: { type: 'number' },
			followMailDate: { type: 'number' },
			hasSentFollowMail: { isMandatory: true, type: 'boolean' },
			canSendingMail: { isMandatory: true, type: 'boolean' },
			isRakutenMember: { isMandatory: true, type: 'boolean' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			orderItems: this.orderItems,
			orderer: this.orderer,
			number: this.number,
			orderDate: this.orderDate,
			shippingDate: this.shippingDate,
			followMailDate: this.followMailDate,
			hasSentFollowMail: this.hasSentFollowMail,
			canSendingMail: this.canSendingMail,
			isRakutenMember: this.isRakutenMember,
		});
	}
}
export type RakutenOrderAttr = ExcludeMethod<RakutenOrder>;
export type OrderAttributes = NeverProp<RakutenOrderAttr, 'number'|'id'|'typeId'|'orderItems'|'orderer'>;
