import { entityIdMap, isValidPurchaseOrderValue, PURCHASE_ORDER_METHODS } from "../../const";
import { UUID } from "../../interface";
import { isRecord } from "../../isRecord";
import isUUID from "../../isUUID";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export const purchaseOrderItemStatus = {
	'unconfirmed': '未確認',
	'confirmed': '未発注',
	'ordered': '発注済み',
	'orderedDirect': '発注済み(直送)',
	'received': '入荷済み',
	'cancelled': 'キャンセル',
} as const;
export type PurchaseOrderItemStatus = keyof typeof purchaseOrderItemStatus;

export class PurchaseOrderItem extends Entity {
	static typeId = entityIdMap['sasagase.purchaseOrderItem'];

	method!: string;
	purchaseOrderId?: UUID;
	skuId?: number;
	shippingSkuIds?: number[];
	shippingIds?: number[];

	values?: Record<string, unknown>;
	quantity!: number;
	isDirect?: boolean;
	isReOrder?: boolean;
	confirmedDate?: number;
	orderedDate?: number;
	receivedDate?: number;
	cancelledDate?: number;
	expectedReceivingDate?: number;
	reOrderNum?: number;
	reOrderedDate?: number[];
	reExpectedReceivingDate?: number[];
	reReceivedDate?: number[];

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			method: { isMandatory: true, type: 'string' },
			purchaseOrderId: { isNullable: true, validator: isUUID },
			skuId: { isNullable: true, type: 'number' },
			shippingSkuIds: { isArray: true, type: 'number' },
			shippingIds: { isArray: true, type: 'number' },
			values: { validator: isRecord },
			quantity: { isMandatory: true, type: 'number' },
			isDirect: { isNullable: true, type: 'boolean' },
			isReOrder: { isNullable: true, type: 'boolean' },
			confirmedDate: { isNullable: true, type: 'number' },
			orderedDate: { isNullable: true, type: 'number' },
			receivedDate: { isNullable: true, type: 'number' },
			cancelledDate: { isNullable: true, type: 'number' },
			expectedReceivingDate: { isNullable: true, type: 'number' },
			reOrderNum: { isNullable: true, type: 'number' },
			reOrderedDate: { isNullable: true, isArray: true, type: 'number' },
			reExpectedReceivingDate: { isNullable: true, isArray: true, type: 'number' },
			reReceivedDate: { isNullable: true, isArray: true, type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			method: this.method,
			purchaseOrderId: this.purchaseOrderId,
			skuId: this.skuId,
			shippingSkuIds: this.shippingSkuIds,
			shippingIds: this.shippingIds,
			values: this.values,
			quantity: this.quantity,
			isDirect: this.isDirect,
			isReOrder: this.isReOrder,
			confirmedDate: this.confirmedDate,
			orderedDate: this.orderedDate,
			receivedDate: this.receivedDate,
			cancelledDate: this.cancelledDate,
			expectedReceivingDate: this.expectedReceivingDate,
			reOrderNum: this.reOrderNum,
			reOrderedDate: this.reOrderedDate,
			reExpectedReceivingDate: this.reExpectedReceivingDate,
			reReceivedDate: this.reReceivedDate,
		});
	}

	getStatus(): PurchaseOrderItemStatus {
		const reOrderNum = this.reOrderNum ?? 0
		if (this.cancelledDate) {
			return 'cancelled';
		} else if (reOrderNum > 0 && this.reReceivedDate?.[reOrderNum - 1]) {
			return 'received';
		} else if (reOrderNum > 0 && this.reOrderedDate?.[reOrderNum - 1]) {
			return this.isDirect ? 'orderedDirect' : 'ordered';
		} else if (this.receivedDate) {
			return 'received';
		} else if (this.orderedDate) {
			return this.isDirect ? 'orderedDirect' : 'ordered';
		} else if (this.confirmedDate) {
			return 'confirmed';
		} else {
			return 'unconfirmed';
		}
	}

	isValidValues(): boolean {
		const method = PURCHASE_ORDER_METHODS[this.method];
		if (!method) {
			return false;
		}
		return method.itemValues.every((format) => isValidPurchaseOrderValue(this.values?.[format.key], format));
	}
}
export type PurchaseOrderItemAttr = Omit<ExcludeMethod<PurchaseOrderItem>, 'typeId'>;
