import * as s from 'superstruct';

export const ReviewStruct = s.type({
	date: s.string(),
	point: s.number(),
	name: s.string(),
	/** sku or nullの時はショップレビュー */
	sku: s.nullable(s.string()),
	orderNumber: s.string(),
	title: s.string(),
	content: s.string(),
});