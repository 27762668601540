import { z } from "zod";

export const schemaCreateShopProps = z.object({
	name: z.string(),
	clientId: z.string(),
	beginDate: z.number(),
	trialEndDate: z.number(),
	status: z.enum(['prepare', 'use', 'suspend', 'end']),
	signature: z.string(),
	sendFollowAfterShippingDays: z.number(),
	sendFollowHours: z.number(),
	sendFollowMinutes: z.number(),
	drafts: z.any().array(),
	excludeMailAddress: z.string().array(),
	shopName: z.string().optional(),
	companyName: z.string().optional(),
	shopUrl: z.string().url().optional(),
	plan: z.string().optional(),
});
export type CreateShopProps = z.infer<typeof schemaCreateShopProps>;

export const schemaModifyShopProps = z.object({
	name: z.string(),
	clientId: z.string(),
	beginDate: z.number(),
	trialEndDate: z.number(),
	status: z.string(),
	signature: z.string(),
	sendFollowAfterShippingDays: z.number(),
	sendFollowHours: z.number(),
	sendFollowMinutes: z.number(),
	shopName: z.string().optional(),
	companyName: z.string().optional(),
	logoImage: z.string().optional(),
	shopUrl: z.string().url().optional(),
	contactType: z.enum(['phone', 'mail']).optional(),
	contactPhone: z.string().optional(),
	contactMail: z.string().email().optional(),
	plan: z.string().optional(),
	statusSchedule: z.object({
		status: z.string(),
		from: z.number(),
	}).optional(),
	excludeMailAddress: z.string().array(),
}).partial();
export type ModifyShopProps = z.infer<typeof schemaModifyShopProps>;

/** イベントのシーケンス番号 */
export const schemaEventSequence = z.string();
export type EventSequence = z.infer<typeof schemaEventSequence>;

/**
 * クエリにイベントのシーケンス番号を指定する時の型
 * シーケンス番号そのものに加えて`null`(先頭の前)を指定可能
 */
export const schemaEventSequenceQuery = schemaEventSequence.nullable();
export type EventSequenceQuery = z.infer<typeof schemaEventSequenceQuery>;

export const schemaGetDomainEventsOptions = z.object({
	/** 取得の開始位置を表すシーケンス番号 */
	startSeq: schemaEventSequenceQuery.optional(),
	/** 取得の除外位置を表すシーケンス番号(指定したシーケンス番号は含まない) */
	endSeq: schemaEventSequenceQuery.optional(),
	/** 取得するイベント数 */
	limit: z.number().optional(),
});
export type GetDomainEventOptions = z.infer<typeof schemaGetDomainEventsOptions>;

export const schemaContainsDomainEventOptions = z.object({
	aggId: z.string().uuid().optional(),
	version: z.number().int().optional(),
	eventType: z.union([z.string(), z.string().array()]).optional(),
});
export type ContainsDomainEventOptions = z.infer<typeof schemaContainsDomainEventOptions>;

const schemaSKUList = z.object({
	skus: z.string().array(),
	itemGroupIds: z.string().array(),
});
export const schemaCreateItemGroupRequest = z.object({
	id: z.string(),
	shopId: z.string(),
	name: z.string(),
	isRef: z.boolean(),
	isAll: z.boolean(),
	include: schemaSKUList,
	exclude: schemaSKUList,
});
export type CreateItemGroupRequest = z.infer<typeof schemaCreateItemGroupRequest>;

export const schemaModifyItemGroupRequest = schemaCreateItemGroupRequest;
export type ModifyItemGroupRequest = z.infer<typeof schemaModifyItemGroupRequest>;

const schemaItemGroupInCampaign = schemaCreateItemGroupRequest.omit({
	shopId: true,
});

const schemaDeadlineValue = z.object({
	cutDate: z.union([z.number(), z.date(), z.string()]).optional(),
	cutDays: z.number().optional(),
});
const schemaCampaignStatus = z.enum(['draft', 'ready', 'inProgress', 'waitingReview', 'finished', 'cancelled', 'deleted']);
const schemaReviewRequired = z.enum(['item', 'shop', 'both', 'any', 'none']);
const schemaDestType = z.enum(['shipping', 'orderer', 'other']);
const schemaMailTemplateBlockType = z.enum([
	'header',
	'free_text',
	'campaign_info',
	'campaign_url',
	'how_to_write_review_url',
	'apply_form_url',
	'apply_deadline_date',
	'coupon_url',
	'review_period',
]);
const schemaTemplateBodyElement = z.object({
	type: schemaMailTemplateBlockType,
	content: z.string(),
});
const schemaMail = z.object({
	body: schemaTemplateBodyElement.array(),
	isDisabled: z.boolean(),
	subject: z.string(),
	signature: z.string(),
});

export const schemaCampaignWithGroup = z.object({
	id: z.string(),
	shopId: z.string(),
	name: z.string(),
	priority: z.number(),
	status: schemaCampaignStatus,
	beginDate: z.union([z.number(), z.date(), z.string()]),
	endDate: z.union([z.string(), z.number(), z.date()]).nullable(),
	reviewDeadline: schemaDeadlineValue,
	reviewRequired: schemaReviewRequired,
	canManyRewards: z.boolean(),
	canChooseDest: z.boolean(),
	defaultDestType: schemaDestType,
	applicationDeadline: schemaDeadlineValue,
	rewardIds: z.string().array(),
	itemGroup: schemaItemGroupInCampaign,
	followMail: schemaMail,
	requestMail: schemaMail,
	receivedMail: schemaMail,
});

const schemaDraftDeadline = z.object({
	cutDate: z.number().optional(),
	cutDays: z.number().optional(),
});

const schemaDraftMailTemplate = z.object({
	body: z.object({
		type: z.string(),
		content: z.string(),
	}).array(),
	isDisabled: z.boolean().optional(),
	subject: z.string(),
	signature: z.string(),
});
export const schemaDraftCampaignWithGroup = z.object({
	id: z.string(),
	shopId: z.string(),
	name: z.string(),
	priority: z.number(),
	status: z.string(),
	beginDate: z.number().optional(),
	endDate: z.number().nullable(),
	reviewDeadline: schemaDraftDeadline,
	reviewRequired: z.string(),
	canManyRewards: z.boolean(),
	canChooseDest: z.boolean(),
	defaultDestType: z.string(),
	applicationDeadline: schemaDraftDeadline,
	rewardIds: z.string().array(),
	itemGroup: schemaItemGroupInCampaign,
	followMail: schemaDraftMailTemplate,
	requestMail: schemaDraftMailTemplate,
	receivedMail: schemaDraftMailTemplate,
});
export const schemaCreateCampaignRequest = z.union([schemaCampaignWithGroup, schemaDraftCampaignWithGroup]);
export type CreateCampaignRequest = z.infer<typeof schemaCreateCampaignRequest>;

export const schemaModifyCampaignRequest = schemaCreateCampaignRequest;
export type ModifyCampaignRequest = z.infer<typeof schemaModifyCampaignRequest>;

export const schemaCreateDraftRequest = z.object({
	id: z.string(),
	shopId: z.string(),
	type: z.enum(['campaign']),
	data: z.unknown(),
});
export type CreateDraftRequest = z.infer<typeof schemaCreateDraftRequest>;

const schemaItemReward = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string(),
	isCoupon: z.literal(false),
	image: z.string(),
	isUnlimited: z.boolean(),
	quantity: z.number().optional(),
	csvFormat: z.string(),
	csvClientType: z.string(),
	csvUserSettings: z.record(z.string(), z.unknown()),
	optionAttributes: z.record(z.string(), z.unknown()).optional(),
});
const schemaCouponReward = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string(),
	isCoupon: z.literal(true),
	issueFreq: z.string(),
	categoryId: z.string(),
	discountType: z.string(),
	discountPrice: z.number().optional(),
	discountRate: z.number().optional(),
	validPeriod: z.string(),
	validDays: z.number(),
	dispFlg: z.boolean(),
	couponImageType: z.string(),
	couponImageInput: z.string().optional(),
	couponImageTemplate: z.string().optional(),
	couponImageUpload: z.string().optional(),
	couponImageName: z.string(),
	linkUrl: z.string().optional(),
	canCombineUse: z.boolean(),
	countUserUseLimit: z.number().optional(),
	countAllUseLimit: z.number().optional(),
	orderType: z.string(),
	orderPrice: z.number().optional(),
	orderCount: z.number().optional(),
	itemDesignation: z.string(),
	targetItemIdStrings: z.string().optional(),
	targetItemTagStrings: z.string().optional(),
});
export const schemaReward = z.union([schemaItemReward, schemaCouponReward]);
export type Reward = z.infer<typeof schemaReward>;

// TODO: api-callerの型定義を使用する
export const schemaSetCampaignPriorityRequest = z.object({
	priority: z.number(),
});
export const schemaPutOrderCanSendingMailRequest = z.object({
	canSendingMail: z.boolean(),
});

export const schemaReview = z.object({
	date: z.string(),
	point: z.number(),
	name: z.string(),
	/** sku or nullの時はショップレビュー */
	sku: z.string().nullable(),
	orderNumber: z.string(),
	title: z.string(),
	content: z.string(),
});
export type Review = z.infer<typeof schemaReview>;

export const schemaPostReviewsRequest = schemaReview.array();

export const schemaTransactRewardRequestsParams = z.object({
	targetIds: z.string().uuid().array(),
	format: z.string().optional(),
	isFormatGroup: z.boolean().optional(),
});
export type TransactRewardRequestsParams = z.infer<typeof schemaTransactRewardRequestsParams>;

export const schemaTransactCSV = z.object({
	filename: z.string(),
	charsaet: z.string(),
	content: z.string(),
});

const schemaMailCategory = z.enum(['review_request', 'review_completed', 'application_completed']);
export const schemaTemplate = z.object({
	id: z.string(),
	name: z.string(),
	category: schemaMailCategory,
	body: schemaTemplateBodyElement.array(),
	signature: z.string(),
	isFavorite: z.boolean(),
});
export type Template = z.infer<typeof schemaTemplate>;

export const schemaModifyTemplate = schemaTemplate.partial().omit({
	id: true,
});
export type ModifyTemplate = z.infer<typeof schemaModifyTemplate>;

export const schemaNotCovered = z.object({
	id: z.string(),
	name: z.string(),
	subject: z.string(),
	body: z.string(),
	signature: z.string(),
	isEnable: z.boolean(),
	isNonYahooMemberEnabled: z.boolean(),
});
export type NotCovered = z.infer<typeof schemaNotCovered>;

export const schemaModifyNotCovered = schemaNotCovered.partial().omit({
	id: true,
});
export type ModifyNotCovered = z.infer<typeof schemaModifyNotCovered>;

export const schemaReviewForm = z.object({
	token: z.string(),
	shopId: z.string(),
	targetId: z.string(),
	rewardId: z.string(),
	rewardOptionValues: z.object({
		kifudaKamon: z.optional(z.string()),
		kifudaName: z.optional(z.string()),
		kifudaFurigana: z.optional(z.union([z.string(), z.literal(false)])),
		kifudaBirthDay: z.optional(z.string()),
		namaebataName: z.optional(z.string()),
		namaebataBirthDay: z.optional(z.string()),
		konoriName: z.optional(z.string()),
		namaemagnetIllust: z.optional(z.string()),
		namaemagnetName: z.optional(z.string()),
	}),
	destType: z.enum(['shipping', 'orderer', 'other']),
	destName: z.string(),
	destZip: z.string(),
	destAddress: z.string(),
	destBuilding: z.string(),
	destPhone: z.string(),
});
export const schemaReviewFormRequest = schemaReviewForm.array();
export type ReviewFormRequest = z.infer<typeof schemaReviewFormRequest>;

export const schemaProcessFulfilledRewardTargetOptions = z.object({
	//
});
export type ProcessFulfilledRewardTargetOptions = z.infer<typeof schemaProcessFulfilledRewardTargetOptions>;

export const schemaSendFollowMailOptions = z.object({
	shopIds: z.string().array().optional(),
});
export type SendFollowMailOptions = z.infer<typeof schemaSendFollowMailOptions>;

export const schemaCampaignStatusSchedule = z.object({
	status: schemaCampaignStatus,
	date: z.union([z.number(), z.date(), z.string()]),
});
export type CampaignStatusSchedule = z.infer<typeof schemaCampaignStatusSchedule>;
