import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetTargetSummaryResponseStruct = s.assign(
	s.object({
		unshipped: s.number(),
		waitingReviewing: s.number(),
		reviewExpired: s.number(),
		campaignFinished: s.number(),
		pending: s.number(),
		waitingAppling: s.number(),
		applingExpired: s.number(),
		done: s.number(),
	}),
	s.object({
		anyReviewed: s.number(),
		bothReviewed: s.number(),
		singleReviewed: s.number(),
		targetProducts: s.number(),
		total: s.number(),
		anyReviewRate: s.number(),
		appliedRate: s.number(),
	})
);
export type GetTargetSummaryResponse = s.Infer<typeof GetTargetSummaryResponseStruct>;

export type GetTargetSummaryMethodParams = {
	shopId: string;
};

export function getTargetSummary(params: GetTargetSummaryMethodParams): APIRequest {
	const { shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/target/summary`,
		{
			path: { shopId },
		},
		ApiParamStructs
	);
}
