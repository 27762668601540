import { entityIdMap } from "../../const/entity-id-map";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class YahooMailNotCovered extends Entity {
	static typeId = entityIdMap['review.not_covered'];

	name!: string;
	subject!: string;
	body!: string;
	signature!: string;
	isEnable!: boolean;
	isNonYahooMemberEnabled!: boolean;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: true, type: 'string' },
			subject: { isMandatory: true, type: 'string' },
			body: { isMandatory: true, type: 'string' },
			signature: { isMandatory: true, type: 'string' },
			isEnable: { isMandatory: true, type: 'boolean' },
			isNonYahooMemberEnabled: { isMandatory: true, type: 'boolean' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			subject: this.subject,
			body: this.body,
			signature: this.signature,
			isEnable: this.isEnable,
			isNonYahooMemberEnabled: this.isNonYahooMemberEnabled,
		});
	}
}
