import { Infer, is, Struct } from "superstruct";

export { isZodValid } from './isZodValid';

type NoneFunctionProps<T> = {
	[K in keyof T]: T[K] extends (...arg: any) => any ? never : K;
}[keyof T];

export type ExcludeMethod<T> = Pick<T, NoneFunctionProps<T>>;

export type NeverProp<T, NK extends keyof T> = Omit<T, NK> & {
	[K in NK]?: undefined;
};

export const isTruthy: <T>(value?: T|undefined|null) => value is T = Boolean as any;
export const throwExp = (val?: unknown): never => {
	throw val;
};

export const validator = <T>(struct: Struct<T>): (val: unknown) => val is Infer<typeof struct> => {
	return (val: unknown): val is Infer<typeof struct> => is(val, struct);
};
