import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, NoticeShopEntityStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.partial(s.omit(NoticeShopEntityStruct, ['id']))
};

export const PutShopResponseStruct = s.boolean();
export type PutShopResponse = s.Infer<typeof PutShopResponseStruct>;

export type PutShopMethodParams = {
	shopId: string;
	shop: s.Infer<typeof ApiParamStructs.body>;
};

export function putShop(params: PutShopMethodParams): APIRequest {
	const { shopId, shop } = params;
	return createAPIRequest(
		'put',
		uri`/api/notice/${shopId}/shop_entity`,
		{
			path: { shopId },
			body: shop
		},
		ApiParamStructs
	);
}
