import { isUUID } from "../../isUUID";
import { entityIdMap } from "../../const";
import { UUID } from "../../interface";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class PurchaseOrder extends Entity {
	static typeId = entityIdMap['sasagase.purchaseOrder'];

	poNumber!: string;
	date!: number;
	itemOrder?: UUID[];

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			poNumber: { isMandatory: true, type: 'string' },
			date: { isMandatory: true, type: 'number' },
			itemOrder: { isArray: true, validator: isUUID },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			poNumber: this.poNumber,
			date: this.date,
			itemOrder: this.itemOrder,
		});
	}
}
export type PurchaseOrderAttr = Omit<ExcludeMethod<PurchaseOrder>, 'typeId'>;
