import { ExcludeMethod } from "../lib";
import ObjectAssert from "../ObjectAssert";
import Entity from "./Entity";

export const ccTypes = ['CC', 'BCC', 'none'] as const;
export type CCType = typeof ccTypes[number];

export function ccTypeValidator(val: string): val is CCType {
	return (ccTypes as readonly string[]).includes(val);
}

export class MailContent extends Entity {

	from!: string;
	fromName?: string;
	ccType?: CCType;
	ccBcc?: string;
	to!: string;
	subject!: string;
	body!: string;
	replaceTag!: Record<string, string>;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			from: { isMandatory: true, type: 'string' },
			fromName: { isMandatory: false, type: 'string' },
			ccType: { isMandatory: false, type: 'string', validator: ccTypeValidator },
			ccBcc: { isMandatory: false, type: 'string' },
			to: { isMandatory: true, type: 'string' },
			subject: { isMandatory: true, type: 'string' },
			body: { isMandatory: true, type: 'string' },
			replaceTag: { isMandatory: true, type: 'object' },
		});
	}

	addReplaceTag(add: Record<string, string>): void {
		this.replaceTag = {
			...this.replaceTag,
			...add,
		};
	}

	getSubject(): string {
		return this.render(this.subject);
	}

	getBody(): string {
		return this.render(this.body);
	}

	needCoupon(): boolean {
		return this.body.match(/{{coupon_url}}/) !== null
	}

	render(template: string): string {
		let buf = template;
		Object.keys(this.replaceTag).map((tag) => {
			buf = buf.replace(new RegExp(`{{${tag}}}`,"g"), this.replaceTag[tag]);
		});
		return buf;
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			from: this.from,
			fromName: this.fromName,
			ccType: this.ccType,
			ccBcc: this.ccBcc,
			to: this.to,
			subject: this.subject,
			body: this.body,
			replaceTag: this.replaceTag,
		});
	}
}
export type MailContentAttr = Omit<ExcludeMethod<MailContent>, 'typeId'>;