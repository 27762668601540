import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const CheckLoginResponseStruct = s.string();
export type CheckLoginResponse = s.Infer<typeof CheckLoginResponseStruct>;

export type CheckLoginMethodParams = {
	shopId: string;
};

export function checkLogin(params: CheckLoginMethodParams): APIRequest {
	const { shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/yahoo/${shopId}/checkLogin`,
		{
			path: { shopId },
		},
		ApiParamStructs
	);
}
