import * as s from 'superstruct';

const CouponCategoryIdMap = new Map([
	["ladiesfashion", "レディースファッション"],
	["mensfashion", "メンズファッション"],
	["sports", "スポーツ、アウトドア"],
	["baby", "ベビー、キッズ"],
	["interior", "インテリア、生活雑貨"],
	["beauty", "コスメ、香水"],
	["health", "ダイエット、健康"],
	["food", "食品、ドリンク、お酒"],
	["kaden", "家電"],
	["hobby", "趣味"],
	["autogoods", "自転車、車、バイク"],
	["pet_diy_flower", "ペット、DIY、花"],
	["watch", "腕時計、アクセサリー"],
	["others", "その他"],
]);
const CouponDiscountTypeMap = new Map([
	["1", "定額値引き"],
	["2", "定率値引き"],
	["3", "送料無料"],
]);
const CouponImageType = ['input', 'template', 'upload'] as const;
const CouponOrderTypeMap = new Map([
	["0", "指定しない"],
	["1", "注文金額指定"],
	["2", "注文個数指定"],
]);
const CouponItemDesignationMap = new Map([
	["3", "ストア内全商品"],
	["1", "クーポン適用商品（最大1,000商品）を指定"],
	["4", "商品タグ（最大10件）を指定"],
]);
const issueFreq = ['individual', 'daily', 'weekly', 'monthly'] as const;
const validPeriod = ['days', 'nextMonth', 'secondNextMonth'] as const;

export const ItemRewardStruct = s.type({
	id: s.string(),
	name: s.string(),
	description: s.string(),
	isCoupon: s.literal(false),
	image: s.string(),
	quantity: s.optional(s.number()),
	isUnlimited: s.boolean(),
	csvFormat: s.string(),
	csvClientType: s.string(),
	csvUserSettings: s.record(s.string(), s.record(s.string(), s.string())),
	optionAttributes: s.optional(s.record(s.string(), s.unknown())),
});

export const CouponRewardStruct = s.type({
	id: s.string(),
	name: s.string(),
	description: s.string(),
	isCoupon: s.literal(true),
	issueFreq: s.enums(issueFreq),
	categoryId: s.enums([...CouponCategoryIdMap.keys()]),
	discountType: s.enums([...CouponDiscountTypeMap.keys()]),
	discountPrice: s.optional(s.number()),
	discountRate: s.optional(s.number()),
	validPeriod: s.enums(validPeriod),
	validDays: s.number(),
	dispFlg: s.boolean(),
	couponImageType: s.enums(CouponImageType),
	couponImageInput: s.optional(s.string()),
	couponImageTemplate: s.optional(s.string()),
	couponImageUpload: s.optional(s.string()),
	couponImageName: s.string(),
	linkUrl: s.optional(s.string()),
	canCombineUse: s.boolean(),
	countUserUseLimit: s.optional(s.number()),
	countAllUseLimit: s.optional(s.number()),
	orderType: s.enums([...CouponOrderTypeMap.keys()]),
	orderPrice: s.optional(s.number()),
	orderCount: s.optional(s.number()),
	itemDesignation: s.enums([...CouponItemDesignationMap.keys()]),
	targetItemIdStrings: s.optional(s.string()),
	targetItemTagStrings: s.optional(s.string()),
});

export const RewardStruct = s.union([ItemRewardStruct, CouponRewardStruct]);