import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		groupId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const DeleteGroupResponseStruct = CommandResultStruct;
export type DeleteGroupResponse = s.Infer<typeof DeleteGroupResponseStruct>;

export type DeleteGroupMethodParams = {
	shopId: string;
	groupId: string;
};

export function deleteGroup(params: DeleteGroupMethodParams): APIRequest {
	const { shopId, groupId } = params;
	return createAPIRequest(
		'delete',
		uri`/api/y-review/${shopId}/group/${groupId}`,
		{
			path: { shopId, groupId },
		},
		ApiParamStructs
	);
}
