import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string()
	}),
	query: EmptyObjectStruct,
	body: s.object({
		mail: s.string(),
		capabilities: s.array(s.string()),
		mailOption: s.record(s.string(), s.string()),
	})
};

export const PostEntityUserResponseStruct = s.boolean();
export type PostEntityUserResponse = s.Infer<typeof PostEntityUserResponseStruct>;

export type PostEntityUserMethodParams = {
	shopId: string;
	mail: string;
	capabilities: string[];
	mailOption: Record<string, string>;
};

export function postEntityUser(params: PostEntityUserMethodParams): APIRequest {
	const { shopId, mail, capabilities, mailOption } = params;
	return createAPIRequest(
		'post',
		uri`/api/user/entity/${shopId}/user`,
		{
			path: { shopId },
			body: { mail, capabilities, mailOption }
		},
		ApiParamStructs
	);
}
