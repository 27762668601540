import * as s from 'superstruct';

export function createParser<T>(struct: s.Struct<T>): (val: unknown) => T {
	return (val: unknown) => {
		// jsonedの`coerce`を実行させる
		const [err, res] = s.validate(val, struct, { coerce: true });
		if (err) {
			throw err;
		}
		return res;
	};
}
export default createParser;

export function jsoned<T>(struct: s.Struct<T>): s.Struct<T> {
	return s.coerce(struct, s.string(), (val) => JSON.parse(val));
}
