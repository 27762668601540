import ReferencePointer, { POINTER_KEY } from "./ReferencePointer";
import { isNormalized, isObject } from "./utils";

export function denormalize(target: unknown, reviver: (key: string, val: unknown) => unknown = (_, val) => val): unknown {
	if (!isNormalized(target)) {
		return reviver('', target);
	}

	const normalized = target._normalized;
	const loopMap = new Map();

	const _denormalize = (target: unknown): unknown => {
		if (ReferencePointer.of(target)) {
			return _denormalize(normalized[target[POINTER_KEY]]);
		} else if (isObject(target)) {
			const loopRes = loopMap.get(target);
			if (loopRes) {
				return loopRes;
			}

			const res = {} as typeof target;
			loopMap.set(target, res);
			for (const [escKey, value] of Object.entries(target)) {
				const key = escKey.startsWith(POINTER_KEY) ? escKey.slice(POINTER_KEY.length) : escKey;
				res[key] = reviver(key, _denormalize(value));
			}
			return res;
		} else if (Array.isArray(target)) {
			return target.map((child, idx) => reviver(idx.toString(), _denormalize(child)));
		}
		return target;
	};
	return reviver('', _denormalize(target.target));
}
export default denormalize;
