interface DateNum {
	year: number;
	month: number;
	day: number;
}
const eraYear: Record<string, DateNum> = {
	'明治': { year: 1868, month:  1, day:  1 },
	'大正': { year: 1912, month:  7, day: 30 },
	'昭和': { year: 1926, month: 12, day: 25 },
	'平成': { year: 1989, month:  1, day:  8 },
	'令和': { year: 2019, month:  5, day:  1 },
};

export function parseJapaneseDate(jdate: string): Date | null {
	const [match, era, jYear, jMonth, jDay] = jdate.replace(/\s/g, '').match(/^\s*(明治|大正|昭和|平成|令和)?\s*(.+|元)\s*年\s*(.+)\s*月\s*(.+)\s*日/) || [];
	if (!match) {
		return null;
	}
	const year = (jYear === '元' ? 1 : parseJInt(jYear)) + (era ? eraYear[era]?.year : 1) - 1;
	const month = parseJInt(jMonth);
	const day = parseJInt(jDay);
	return new Date(year, month - 1, day);
}

interface JapaneseNumberKanji {
	num: number | null;
	offset: number;
	fix: number | null;
}
const jc: Record<string, JapaneseNumberKanji> = {
	'一': { num: 1, offset: 1, fix: null },
	'1': { num: 1, offset: 1, fix: null },
	'１': { num: 1, offset: 1, fix: null },
	'壱': { num: 1, offset: 1, fix: null },
	'壹': { num: 1, offset: 1, fix: null },
	'二': { num: 2, offset: 1, fix: null },
	'2': { num: 2, offset: 1, fix: null },
	'２': { num: 2, offset: 1, fix: null },
	'弐': { num: 2, offset: 1, fix: null },
	'貳': { num: 2, offset: 1, fix: null },
	'貮': { num: 2, offset: 1, fix: null },
	'弍': { num: 2, offset: 1, fix: null },
	'三': { num: 3, offset: 1, fix: null },
	'3': { num: 3, offset: 1, fix: null },
	'３': { num: 3, offset: 1, fix: null },
	'参': { num: 3, offset: 1, fix: null },
	'參': { num: 3, offset: 1, fix: null },
	'四': { num: 4, offset: 1, fix: null },
	'4': { num: 4, offset: 1, fix: null },
	'４': { num: 4, offset: 1, fix: null },
	'肆': { num: 4, offset: 1, fix: null },
	'五': { num: 5, offset: 1, fix: null },
	'5': { num: 5, offset: 1, fix: null },
	'５': { num: 5, offset: 1, fix: null },
	'伍': { num: 5, offset: 1, fix: null },
	'六': { num: 6, offset: 1, fix: null },
	'6': { num: 6, offset: 1, fix: null },
	'６': { num: 6, offset: 1, fix: null },
	'陸': { num: 6, offset: 1, fix: null },
	'七': { num: 7, offset: 1, fix: null },
	'7': { num: 7, offset: 1, fix: null },
	'７': { num: 7, offset: 1, fix: null },
	'漆': { num: 7, offset: 1, fix: null },
	'柒': { num: 7, offset: 1, fix: null },
	'八': { num: 8, offset: 1, fix: null },
	'8': { num: 8, offset: 1, fix: null },
	'８': { num: 8, offset: 1, fix: null },
	'捌': { num: 8, offset: 1, fix: null },
	'九': { num: 9, offset: 1, fix: null },
	'9': { num: 9, offset: 1, fix: null },
	'９': { num: 9, offset: 1, fix: null },
	'玖': { num: 9, offset: 1, fix: null },
	'零': { num: 0, offset: 1, fix: null },
	'〇': { num: 0, offset: 1, fix: null },
	'0': { num: 0, offset: 1, fix: null },
	'０': { num: 0, offset: 1, fix: null },
	'十': { num: null, offset: 1e1, fix: null },
	'拾': { num: null, offset: 1e1, fix: null },
	'什': { num: null, offset: 1e1, fix: null },
	'廿': { num: null, offset: 2e1, fix: null },
	'卅': { num: null, offset: 3e1, fix: null },
	'丗': { num: null, offset: 3e1, fix: null },
	'卌': { num: null, offset: 4e1, fix: null },
	'百': { num: null, offset: 1e2, fix: null },
	'佰': { num: null, offset: 1e2, fix: null },
	'陌': { num: null, offset: 1e2, fix: null },
	'千': { num: null, offset: 1e3, fix: null },
	'仟': { num: null, offset: 1e3, fix: null },
	'阡': { num: null, offset: 1e3, fix: null },
	'万': { num: null, offset: 1, fix: 1e4 },
	'萬': { num: null, offset: 1, fix: 1e4 },
	'億': { num: null, offset: 1, fix: 1e8 },
	'兆': { num: null, offset: 1, fix: 1e12 },
	'京': { num: null, offset: 1, fix: 1e16 },
};

export function parseJInt(jnum: string): number {
	// 半角数字(0-9)だったらそのままパースした値を返す
	const int = parseInt(jnum, 10);
	if (int.toString() === jnum) {
		return int;
	}

	let ret = 0;
	let n = 0;
	let base = 0;
	for (const char of jnum) {
		const k = jc[char];
		if (!k) {
			return NaN;
		}

		if (k.num !== null) {
			base = base * 10 + k.num;
			continue;
		}
		n += (base || 1) * k.offset;
		base = 0;
		if (k.fix) {
			ret += n * k.fix;
			n = 0;
		}
	}
	ret += n + base;
	return ret;
}