import ObjectAssert from "../../ObjectAssert";
import { ExcludeMethod } from "../../lib";
import Entity from "../Entity";
import { CCType, ccTypeValidator } from "../MailContent";

const DUMMY_STRING = '********';

export class AdminShop extends Entity {

	// 共通
	name?: string;
	lastReviewCheckDate?: number;
	// rakuten
	mail?: string;
	serviceSecret?: string;
	licenseKey?: string;
	licenseExpiryDate?: number;
	useServiceSquare?: boolean;
	serviceSquareLicenseKey?: string;
	serviceSquareLicenseExpiryDate?: number;
	smtpAuth?: string;
	smtpPass?: string;
	mailSenderName?: string;
	ccType?: CCType;
	mailCcBcc?: string;
	isMailPending?: boolean;
	shopAccountId?: string;
	shopAccountPass?: string;
	userAccountId?: string;
	userAccountPass?: string;
	latestOrderDate?: string;
	shopUrl?: string;
	// yahoo
	yahooId?: string;
	yahooPass?: string;
	yahooStoreAccount?: string;
	publicKey?: string;
	publicKeyVersion?: string;
	publicKeyExpiration?: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: false, type: 'string' },
			lastReviewCheckDate: { type: 'number' },

			mail: { isMandatory: false, type: 'string' },
			serviceSecret: { isMandatory: false, type: 'string' },
			licenseKey: { isMandatory: false, type: 'string' },
			licenseExpiryDate: { isMandatory: false, type: 'number' },
			useServiceSquare: { isMandatory: false, type: 'boolean' },
			serviceSquareLicenseKey: { isMandatory: false, type: 'string' },
			serviceSquareLicenseExpiryDate: { isMandatory: false, type: 'number' },
			smtpAuth: { isMandatory: false, type: 'string' },
			smtpPass: { isMandatory: false, type: 'string' },
			mailSenderName: { isMandatory: false, type: 'string' },
			ccType: { isMandatory: false, type: 'string', validator: ccTypeValidator },
			mailCcBcc: { isMandatory: false, type: 'string' },
			isMailPending: { isMandatory: false, type: 'boolean' },
			shopAccountId: { isMandatory: false, type: 'string' },
			shopAccountPass: { isMandatory: false, type: 'string' },
			userAccountId: { isMandatory: false, type: 'string' },
			userAccountPass: { isMandatory: false, type: 'string' },
			latestOrderDate: { type: 'string' },
			shopUrl: { isMandatory: false, type: 'string' },

			yahooId: { isMandatory: false, type: 'string' },
			yahooPass: { isMandatory: false, type: 'string' },
			yahooStoreAccount: { isMandatory: false, type: 'string' },
			publicKey: { isMandatory: false, type: 'string' },
			publicKeyVersion: { isMandatory: false, type: 'string' },
			publicKeyExpiration: { isMandatory: false, type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			lastReviewCheckDate: this.lastReviewCheckDate,

			mail: this.mail,
			serviceSecret: this.serviceSecret,
			licenseKey: this.licenseKey,
			licenseExpiryDate: this.licenseExpiryDate,
			useServiceSquare: this.useServiceSquare,
			serviceSquareLicenseKey: this.serviceSquareLicenseKey,
			serviceSquareLicenseExpiryDate: this.serviceSquareLicenseExpiryDate,
			smtpAuth: this.smtpAuth,
			smtpPass: this.smtpPass ? DUMMY_STRING : '',
			mailSenderName: this.mailSenderName,
			ccType: this.ccType,
			mailCcBcc: this.mailCcBcc,
			isMailPending: this.isMailPending,
			shopAccountId: this.shopAccountId,
			shopAccountPass: this.shopAccountPass ? DUMMY_STRING : '',
			userAccountId: this.userAccountId,
			userAccountPass: this.userAccountPass ? DUMMY_STRING : '',
			latestOrderDate: this.latestOrderDate,
			shopUrl: this.shopUrl,

			yahooId: this.yahooId,
			yahooPass: this.yahooPass,
			yahooStoreAccount: this.yahooStoreAccount,
			publicKey: this.publicKey,
			publicKeyVersion: this.publicKeyVersion,
			publicKeyExpiration: this.publicKeyExpiration,
		});
	}

	get enabledLicenseKey(): string | undefined {
		return this.useServiceSquare ? this.serviceSquareLicenseKey : this.licenseKey;
	}

	get enabledLicenseExpiryDate(): number | undefined {
		return this.useServiceSquare ? this.serviceSquareLicenseExpiryDate : this.licenseExpiryDate;
	}
}
export type AdminShopAttr = ExcludeMethod<AdminShop>;