import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export const reviewType = ['item', 'shop'] as const;
export type ReviewType = typeof reviewType[number];

function reviewTypeValidator(val: unknown): val is ReviewType {
	return typeof val == 'string' && (reviewType as ReadonlyArray<string>).includes(val);
}

function racValidator(val: unknown): val is number | null {
	return typeof val == 'number' || val === null;
}

export class Review extends Entity {
	static typeId = entityIdMap['review.review'];

	type!: ReviewType;
	/** レビューの対象商品ID(`null`はショップレビュー) */
	rac!: number | null;
	targetName!: string;
	url!: string;
	points!: number;
	date!: number;
	title!: string;
	content!: string;
	orderNumber!: string;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			type: { isMandatory: true, validator: reviewTypeValidator },
			rac: { isMandatory: true, validator: racValidator },
			targetName: { isMandatory: true, type: 'string' },
			url: { isMandatory: true, type: 'string' },
			points: { isMandatory: true, type: 'number' },
			date: { isMandatory: true, type: 'number' },
			title: { isMandatory: true, type: 'string' },
			content: { isMandatory: true, type: 'string' },
			orderNumber: { isMandatory: true, type: 'string' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			type: this.type,
			rac: this.rac,
			targetName: this.targetName,
			url: this.url,
			points: this.points,
			date: this.date,
			title: this.title,
			content: this.content,
			orderNumber: this.orderNumber,
		});
	}
}
export type ReviewAttr = Omit<ExcludeMethod<Review>, 'typeId'>;
