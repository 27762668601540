import { smtpAuth, smtpEncrypt } from '@sasagase/types';
import * as s from 'superstruct';

export const MailShopEntityStruct = s.object({
	id: s.string(),
	mail: s.optional(s.string()),
	mailSenderName: s.optional(s.string()),
	smtpHost: s.optional(s.string()),
	smtpPort: s.optional(s.number()),
	smtpAuth: s.optional(s.enums(smtpAuth)),
	smtpEncrypt: s.optional(s.enums(smtpEncrypt)),
	smtpUser: s.optional(s.string()),
	smtpPass: s.optional(s.string()),
	mailCc: s.optional(s.array(s.string())),
	mailBcc: s.optional(s.array(s.string())),
	isMailPending: s.optional(s.boolean()),
});

export const SendTestMailOptionStruct = s.object({
	to: s.string(),
	subject: s.string(),
	body: s.string(),
});
