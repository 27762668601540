export const POINTER_KEY = '*' as const;

interface Pointer {
	[POINTER_KEY]: string;
}

export class ReferencePointer implements Pointer {
	[POINTER_KEY]: string;

	constructor(key: string) {
		this[POINTER_KEY] = key;
	}

	static of(val: unknown): val is Pointer {
		return val instanceof this || (
				val != null &&
				typeof (val as any)[POINTER_KEY] == 'string' &&
				Object.keys(val).length === 1
			);
	}
}
export default ReferencePointer;
