import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, MailTemplateStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: s.type({
		category: s.optional(s.string())
	}),
	body: EmptyObjectStruct
};

export const GetTemplatesResponseStruct = s.array(MailTemplateStruct);
export type GetTemplatesResponse = s.Infer<typeof GetTemplatesResponseStruct>;
export type GetTemplatesResponseValues = s.Infer<typeof MailTemplateStruct>;

export type GetTemplatesMethodParams = {
	shopId: string;
	category?: string;
};

export function getTemplates(params: GetTemplatesMethodParams): APIRequest {
	const { shopId, ...query } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/template`,
		{
			path: { shopId },
			query: Object.fromEntries(Object.entries(query || {}).filter(([,v]) => v !== undefined)),
		},
		ApiParamStructs
	);
}
