import * as ss from 'superstruct';
import { entityIdMap } from "../../const";
import { UUID } from "../../interface";
import isUUID from "../../isUUID";
import { ExcludeMethod, validator } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { MailContent } from "../MailContent";

export const reviewMailStatusList = ['pending', 'sending', 'sent', 'suspended'] as const;
export type ReviewMailStatus = typeof reviewMailStatusList[number];

const structReviewMailStatus = ss.enums(reviewMailStatusList);
const structReviewMailStatusHistory = ss.object({
	status: structReviewMailStatus,
	date: ss.number(),
});
const isReviewMailStatus = validator(structReviewMailStatus);
const isReviewMailStatusHistory = validator(structReviewMailStatusHistory);

export class ReviewMail extends Entity {
	static typeId = entityIdMap['review.reviewMail'];

	shopId!: UUID;
	orderNumber?: string | null;
	rewardTargetId?: UUID | null;
	rewardRequestId?: UUID | null;

	/**
	 * pending: 保留中 - メール作成後の最初の状態、速やかにrakutenサービスに送信される
	 * sending: 送信中 - rakutenサービスに送信後のレスポンス待ち状態
	 * sent: 送信済み - rakutenサービスから送信成功のレスポンス(`sent`イベント)を受け取った状態
	 * suspended: 停止 - rakutenサービスから送信停止のレスポンス(`suspended`イベント)を受け取った状態
	 */
	status!: ReviewMailStatus;
	date!: number;
	history!: {
		status: ReviewMailStatus;
		date: number;
	}[];
	content!: MailContent;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			shopId: { isMandatory: true, validator: isUUID },
			orderNumber: { type: 'string', isNullable: true },
			rewardTargetId: { validator: isUUID, isNullable: true },
			rewardRequestId: { validator: isUUID, isNullable: true },
			status: { isMandatory: true, validator: isReviewMailStatus },
			date: { isMandatory: true, type: 'number' },
			history: { isMandatory: true, isArray: true, validator: isReviewMailStatusHistory },
			content: { isMandatory: true, model: MailContent },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			shopId: this.shopId,
			orderNumber: this.orderNumber,
			rewardTargetId: this.rewardTargetId,
			rewardRequestId: this.rewardRequestId,
			status: this.status,
			date: this.date,
			history: this.history,
			content: this.content,
		});
	}
}
export type ReviewMailAttr = Omit<ExcludeMethod<ReviewMail>, 'typeId'>;
