import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		campaignId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const DeleteCampaignResponseStruct = CommandResultStruct;
export type DeleteCampaignResponse = s.Infer<typeof DeleteCampaignResponseStruct>;

export type DeleteCampaignMethodParams = {
	shopId: string;
	campaignId: string;
};

export function deleteCampaign(params: DeleteCampaignMethodParams): APIRequest {
	const { shopId, campaignId } = params;
	return createAPIRequest(
		'delete',
		uri`/api/y-review/${shopId}/campaign/${campaignId}`,
		{
			path: { shopId, campaignId },
		},
		ApiParamStructs
	);
}
