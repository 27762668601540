/* eslint-disable no-irregular-whitespace */
export const contentType = [
	'NOTIFICATION',
	'RAKUTEN_API_NOT_SET',
	'RAKUTEN_API_SETTING_ERROR',
	'RAKUTEN_API_EXPIRY_NOTICE',
	'RAKUTEN_API_EXPIRED',
	'RAKUTEN_API_MAINTENANCE',
	'RAKUTEN_API_ERROR',
	'RAKUTEN_SHOP_ACCOUNT_NOT_SET',
	'RAKUTEN_SHOP_ACCOUNT_SETTING_ERROR',
	'RAKUTEN_SHOP_ACCOUNT_EXPIRED_ERROR',
	'RAKUTEN_USER_ACCOUNT_NOT_SET',
	'RAKUTEN_USER_ACCOUNT_SETTING_ERROR',
	'RAKUTEN_USER_REVIEW_PRIVILEGE_ERROR',
	'RAKUTEN_ACCOUNT_MAINTENANCE',
	'RAKUTEN_ACCOUNT_ERROR',
	'RAKUTEN_MAIL_NOT_SET',
	'RAKUTEN_MAIL_SETTING_ERROR',
	'RAKUTEN_MAIL_ADDRESS_ERROR',
	'RAKUTEN_MAIL_ERROR',
	'REVIEW_NOT_UPLOADED_REVIEW_CSV',
	'YAHOO_API_NOT_LINKED',
	'YAHOO_API_NOT_SET',
	'YAHOO_API_SETTING_ERROR',
	'YAHOO_API_PUBLICKEY_EXPIRY_NOTICE',
	'YAHOO_API_PUBLICKEY_EXPIRED',
	'YAHOO_API_TOKEN_EXPIRY_NOTICE',
	'YAHOO_API_TOKEN_EXPIRED',
	'YAHOO_API_MAINTENANCE',
	'YAHOO_API_ERROR',
	'YAHOO_ACCOUNT_NOT_SET',
	'YAHOO_ACCOUNT_SETTING_ERROR',
	'YAHOO_ACCOUNT_MAINTENANCE',
	'YAHOO_ACCOUNT_ERROR',
	'YAHOO_MAIL_NOT_SET',
	'YAHOO_MAIL_SETTING_ERROR',
	'YAHOO_MAIL_ERROR',
	'YREVIEW_NOT_UPLOADED_REVIEW_CSV',
] as const;
export type ContentType = typeof contentType[number];

const contentLevel = [
	'NOTICE',
	'WARNING',
	'ALERT'
] as const;
type ContentLevel = typeof contentLevel[number];

const contentGroup = [
	'API',
	'ACCOUNT',
	'MAIL',
	'REVIEW'
] as const;
type ContentGroup = typeof contentGroup[number];

export interface ContentSetting {
	/** お知らせレベル (NOTICE:お知らせ,WARNING:設定不備お知らせ,ALERT:外部起因エラー) */
	level: ContentLevel;

	/** サービス */
	service?: 'review' | 'y-review';

	/** お知らせグループ */
	group?: ContentGroup;

	/** お知らせタイトル */
	title?: string;

	/** お知らせ内容 */
	content?: string;

	/** システムアラート内容 */
	alert?: string;
}

export const CONTENT_SETTINGS: Record<string, ContentSetting> = {
	'NOTIFICATION': {
		level: 'NOTICE',
	},
	'RAKUTEN_API_NOT_SET': {
		level: 'WARNING',
		service: 'review',
		group: 'API',
		title: `RMS WEB SERVICE 利用情報`,
		content: `楽天システム設定の「RMS WEB SERVICE 利用情報」が未入力です。楽天RMS上のRMS WEB SERVICEの利用設定で「serviceSecret」と「licenseKey」を確認の上、設定してください。`
	},
	'RAKUTEN_API_SETTING_ERROR': {
		level: 'WARNING',
		service: 'review',
		group: 'API',
		title: `RMS WEB SERVICE 利用情報`,
		content: `楽天システム設定の「RMS WEB SERVICE 利用情報」が適切ではありません。再度ご確認の上、設定の変更をお願いします。`
	},
	'RAKUTEN_API_EXPIRY_NOTICE': {
		level: 'WARNING',
		service: 'review',
		group: 'API',
		title: `RMS WEB SERVICE 利用情報`,
		content: `楽天システム設定の「RMS WEB SERVICE 利用情報」の「licenseKey」の有効期限が {{expiry:YYYY年MM月DD日}} に切れます。事前に更新をお願いします。`,
		alert: `■RMS WEB SERVICE 利用情報
　「licenseKey」の有効期限が {{expiry:YYYY年MM月DD日}} に切れます。
　事前に更新をお願いします。`
	},
	'RAKUTEN_API_EXPIRED': {
		level: 'WARNING',
		service: 'review',
		group: 'API',
		title: `RMS WEB SERVICE 利用情報`,
		content: `楽天システム設定の「RMS WEB SERVICE 利用情報」の「licenseKey」の有効期限が切れています。新しいlicenseKeyの発行後、設定の変更をお願いします。`,
		alert: `■RMS WEB SERVICE 利用情報
　「licenseKey」の有効期限が切れています。
　新しい「licenseKey」の発行後、設定の変更をお願いします。`
	},
	'RAKUTEN_API_MAINTENANCE': {
		level: 'ALERT',
		service: 'review',
		group: 'API',
		title: `楽天WEB APIサービス`,
		content: `楽天のWEB APIサービスがメンテナンス中です。しばらくお待ちください。`
	},
	'RAKUTEN_API_ERROR': {
		level: 'ALERT',
		service: 'review',
		group: 'API',
		title: `楽天WEB APIサービス`,
		content: `楽天のWEB APIサービスでシステムエラーが発生しました。しばらくお待ちください。`
	},
	'RAKUTEN_SHOP_ACCOUNT_NOT_SET': {
		level: 'WARNING',
		service: 'review',
		group: 'ACCOUNT',
		title: `楽天ショップアカウント情報`,
		content: `楽天システム設定の「楽天ショップアカウント情報」が未入力です。楽天RMSへログイン可能なアカウント情報を設定してください。`
	},
	'RAKUTEN_SHOP_ACCOUNT_SETTING_ERROR': {
		level: 'WARNING',
		service: 'review',
		group: 'ACCOUNT',
		title: `楽天ショップアカウント情報`,
		content: `楽天システム設定の「楽天ショップアカウント情報」が適切ではありません。再度ご確認の上、設定を変更してください。`,
		alert: `■楽天ショップアカウント情報
　楽天ショップアカウントでRMSへログインできません。
　再度ご確認の上、設定を変更してください。`
	},
	'RAKUTEN_SHOP_ACCOUNT_EXPIRED_ERROR': {
		level: 'WARNING',
		service: 'review',
		group: 'ACCOUNT',
		title: `楽天ショップアカウント情報`,
		content: `楽天ショップパスワードの有効期限が切れています。R-Loginでパスワードを更新後、楽天システム設定の「楽天ショップアカウント情報」を更新してください。`,
		alert: `■楽天ショップアカウント情報
　楽天ショップアカウントでRMSへログインできません。
　再度ご確認の上、設定を変更してください。`
	},
	'RAKUTEN_USER_ACCOUNT_NOT_SET': {
		level: 'WARNING',
		service: 'review',
		group: 'ACCOUNT',
		title: `楽天ユーザーアカウント情報`,
		content: `楽天システム設定の「楽天ユーザーアカウント情報」が未入力です。楽天RMSへログイン可能なアカウント情報を設定してください。`
	},
	'RAKUTEN_USER_ACCOUNT_SETTING_ERROR': {
		level: 'WARNING',
		service: 'review',
		group: 'ACCOUNT',
		title: `楽天ユーザーアカウント情報`,
		content: `楽天システム設定の「楽天ユーザーアカウント情報」が適切ではありません。再度ご確認の上、設定を変更してください。`
	},
	'RAKUTEN_USER_REVIEW_PRIVILEGE_ERROR': {
		level: 'WARNING',
		service: 'review',
		group: 'ACCOUNT',
		title: `楽天ユーザーアカウント情報`,
		content: `レビューチェックツールが開けません。レビューチェックツールを開くことができるアカウントを楽天システム設定の「楽天ユーザーアカウント情報」に設定してください。`
	},
	'RAKUTEN_ACCOUNT_MAINTENANCE': {
		level: 'ALERT',
		service: 'review',
		group: 'ACCOUNT',
		title: `楽天RMSログイン`,
		content: `楽天RMSがメンテナンス中です。しばらくお待ちください。`
	},
	'RAKUTEN_ACCOUNT_ERROR': {
		level: 'ALERT',
		service: 'review',
		group: 'ACCOUNT',
		title: `楽天RMSログイン`,
		content: `楽天RMSへのログインに失敗しました。しばらくお待ちください。`
	},
	'RAKUTEN_MAIL_NOT_SET': {
		level: 'WARNING',
		service: 'review',
		group: 'MAIL',
		title: `楽天あんしんメルアドサーバ(SMTP AUTH)情報`,
		content: `楽天システム設定の「楽天あんしんメルアドサーバ(SMTP AUTH)情報」が未入力です。利用ガイド＞登録・設定方法を確認の上、設定してください。`
	},
	'RAKUTEN_MAIL_SETTING_ERROR': {
		level: 'WARNING',
		service: 'review',
		group: 'MAIL',
		title: `楽天あんしんメルアドサーバ(SMTP AUTH)情報`,
		content: `楽天システム設定の「楽天あんしんメルアドサーバ(SMTP AUTH)情報」が適切ではありません。再度ご確認の上、設定の変更をお願いします。`
	},
	'RAKUTEN_MAIL_ADDRESS_ERROR': {
		level: 'WARNING',
		service: 'review',
		group: 'MAIL',
		title: `楽天あんしんメルアドサーバ(SMTP AUTH)情報`,
		content: `楽天システム設定の「楽天あんしんメルアドサーバ(SMTP AUTH)情報」のメールアドレスが「店舗連絡先メールアドレス」ではありません。再度ご確認の上、設定の変更をお願いします。`
	},
	'RAKUTEN_MAIL_ERROR': {
		level: 'ALERT',
		service: 'review',
		group: 'MAIL',
		title: `楽天あんしんメルアド`,
		content: `楽天あんしんメルアド経由でのメール送信に失敗しました。再度ご確認の上、設定の変更をお願いします。`
	},
	'REVIEW_NOT_UPLOADED_REVIEW_CSV': {
		level: 'WARNING',
		service: 'review',
		group: 'REVIEW',
		title: `レビューCSVアップロード`,
		content: `レビューCSVが{{notUploadedDays}}日間アップロードされていません。レビュチェックツールより最新のCSVを取得の上、アップロードをお願いします。`,
		alert: `■レビューCSVアップロード
　レビューCSVが{{notUploadedDays}}日間アップロードされていません。
　レビュチェックツールより最新のCSVを取得の上、アップロードをお願いします。`
	},
	'YAHOO_API_NOT_LINKED': {
		level: 'WARNING',
		service: 'y-review',
		group: 'API',
		title: `Yahoo! ID連携`,
		content: `Yahoo!システム設定の「Yahoo! ID連携」が未連携です。「Yahoo! ID連携を実行」から「Yahoo! ID連携」を行ってください。`
	},
	'YAHOO_API_NOT_SET': {
		level: 'WARNING',
		service: 'y-review',
		group: 'API',
		title: `公開鍵設定`,
		content: `Yahoo!システム設定の「公開鍵設定」が未入力です。Yahoo!ストアクリエイターproの設定＞暗号鍵管理で有効な「公開鍵」を確認の上、設定してください。`
	},
	'YAHOO_API_SETTING_ERROR': {
		level: 'WARNING',
		service: 'y-review',
		group: 'API',
		title: `Yahoo! ID連携 & 公開鍵設定`,
		content: `Yahoo!システム設定の「Yahoo! ID連携」,「公開鍵設定」が適切ではありません。再度ご確認の上、設定の変更をお願いします。`
	},
	'YAHOO_API_PUBLICKEY_EXPIRY_NOTICE': {
		level: 'WARNING',
		service: 'y-review',
		group: 'API',
		title: `公開鍵設定`,
		content: `Yahoo!システム設定の「公開鍵設定」の「公開鍵」の有効期限が {{expiry:YYYY年MM月DD日}} に切れます。事前に更新をお願いします。`,
		alert: `■公開鍵設定
　「公開鍵」の有効期限が {{expiry:YYYY年MM月DD日}} に切れます。
　事前に更新をお願いします。`
	},
	'YAHOO_API_PUBLICKEY_EXPIRED': {
		level: 'WARNING',
		service: 'y-review',
		group: 'API',
		title: `公開鍵設定`,
		content: `Yahoo!システム設定の「公開鍵設定」の「公開鍵」の有効期限が切れています。新しい公開鍵の発行後、設定の変更をお願いします。`,
		alert: `■公開鍵設定
　「公開鍵」の有効期限が切れています。
　新しい「公開鍵」の発行後、設定の変更をお願いします。`
	},
	'YAHOO_API_TOKEN_EXPIRY_NOTICE': {
		level: 'WARNING',
		service: 'y-review',
		group: 'API',
		title: `Yahoo! ID連携`,
		content: `Yahoo!システム設定の「Yahoo! ID連携」の「認可」の有効期限が {{expiry:YYYY年MM月DD日}} に切れます。事前にYahoo! ID連携を行い有効期限の延長をお願いします。`,
		alert: `■Yahoo! ID連携
　「認可」の有効期限が {{expiry:YYYY年MM月DD日}} に切れます。
　事前にYahoo! ID連携を行い有効期限の延長をお願いします。`
	},
	'YAHOO_API_TOKEN_EXPIRED': {
		level: 'WARNING',
		service: 'y-review',
		group: 'API',
		title: `Yahoo! ID連携`,
		content: `Yahoo!システム設定の「Yahoo! ID連携」の「認可」の有効期限が切れている、もしくは「認可」が無効になっています。Yahoo! ID連携を行い、有効期限の延長または「認可」を再取得してください。`,
		alert: `■Yahoo! ID連携
　「認可」の有効期限が切れている、もしくは「認可」が無効になっています。
Yahoo! ID連携を行い、有効期限の延長または「認可」を再取得してください。`
	},
	'YAHOO_API_MAINTENANCE': {
		level: 'ALERT',
		service: 'y-review',
		group: 'API',
		title: `Yahoo!ショッピングAPI`,
		content: `Yahoo!ショッピングAPIがメンテナンス中です。しばらくお待ちください。`
	},
	'YAHOO_API_ERROR': {
		level: 'ALERT',
		service: 'y-review',
		group: 'API',
		title: `Yahoo!ショッピングAPI`,
		content: `Yahoo!ショッピングAPIでシステムエラーが発生しました。しばらくお待ちください。`
	},
	'YAHOO_ACCOUNT_NOT_SET': {
		level: 'WARNING',
		service: 'y-review',
		group: 'ACCOUNT',
		title: `Yahoo!ストアクリエイターpro連携設定`,
		content: `Yahoo!システム設定の「Yahoo!ストアクリエイターpro連携設定」が未入力です。Yahoo!ストアクリエイターproへログイン可能なアカウント情報を設定してください。`
	},
	'YAHOO_ACCOUNT_SETTING_ERROR': {
		level: 'WARNING',
		service: 'y-review',
		group: 'ACCOUNT',
		title: `Yahoo!ストアクリエイターpro連携設定`,
		content: `Yahoo!システム設定の「Yahoo!ストアクリエイターpro連携設定」が適切ではありません。再度ご確認の上、設定を変更してください。`,
		alert: `■Yahoo!ストアクリエイターpro連携設定
　「Yahoo!JAPAN ID」「Yahoo!JAPANパスワード」でストアクリエイターproへログインできません。
　再度ご確認の上、設定を変更してください。`
	},
	'YAHOO_ACCOUNT_MAINTENANCE': {
		level: 'ALERT',
		service: 'y-review',
		group: 'ACCOUNT',
		title: `Yahoo!ストアクリエイターproログイン`,
		content: `Yahoo!ストアクリエイターproがメンテナンス中です。しばらくお待ちください。`
	},
	'YAHOO_ACCOUNT_ERROR': {
		level: 'ALERT',
		service: 'y-review',
		group: 'ACCOUNT',
		title: `Yahoo!ストアクリエイターproログイン`,
		content: `Yahoo!ストアクリエイターproへのログインに失敗しました。しばらくお待ちください。`
	},
	'YAHOO_MAIL_NOT_SET': {
		level: 'WARNING',
		service: 'y-review',
		group: 'MAIL',
		title: `SMTPサーバ設定`,
		content: `SMTPサーバ設定が未入力です。利用ガイド＞登録・設定方法を確認の上、設定してください。`
	},
	'YAHOO_MAIL_SETTING_ERROR': {
		level: 'WARNING',
		service: 'y-review',
		group: 'MAIL',
		title: `SMTPサーバ設定`,
		content: `SMTPサーバ設定が適切ではありません。再度ご確認の上、設定の変更をお願いします。`
	},
	'YAHOO_MAIL_ERROR': {
		level: 'ALERT',
		service: 'y-review',
		group: 'MAIL',
		title: `SMTPサーバ設定`,
		content: `SMTPサーバ設定でのメール送信に失敗しました。再度ご確認の上、設定の変更をお願いします。`
	},
	'YREVIEW_NOT_UPLOADED_REVIEW_CSV': {
		level: 'WARNING',
		service: 'y-review',
		group: 'REVIEW',
		title: `レビューCSVアップロード`,
		content: `レビューCSVが{{notUploadedDays}}日間アップロードされていません。レビュチェックツールより最新のCSVを取得の上、アップロードをお願いします。`,
		alert: `■レビューCSVアップロード
　レビューCSVが{{notUploadedDays}}日間アップロードされていません。
　レビュチェックツールより最新のCSVを取得の上、アップロードをお願いします。`
	},
}

export const ALERT_CONTENT_MAP: Record<string, ContentType[]> = {
	apiExpiryNotice: [
		'RAKUTEN_API_EXPIRY_NOTICE'
	],
	apiExpired: [
		'RAKUTEN_API_EXPIRED'
	],
	shopAccountExpired: [
		'RAKUTEN_SHOP_ACCOUNT_SETTING_ERROR',
		'RAKUTEN_SHOP_ACCOUNT_EXPIRED_ERROR'
	],
	notUploadedReviweCsv: [
		'REVIEW_NOT_UPLOADED_REVIEW_CSV',
		'YREVIEW_NOT_UPLOADED_REVIEW_CSV',
	],
	tokenExpiryNotice: [
		'YAHOO_API_TOKEN_EXPIRY_NOTICE',
	],
	tokenExpired: [
		'YAHOO_API_TOKEN_EXPIRED',
	],
	publicKeyExpiryNotice: [
		'YAHOO_API_PUBLICKEY_EXPIRY_NOTICE',
	],
	publicKeyExpired: [
		'YAHOO_API_PUBLICKEY_EXPIRED',
	],
	storeAccountExpired: [
		'YAHOO_ACCOUNT_SETTING_ERROR',
		'YAHOO_ACCOUNT_ERROR',
	],
};