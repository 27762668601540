import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from "../../lib";
import Entity from "../Entity";
import { Term } from "./Term";

export const articleTypes = ['guide', 'faq'] as const;
export type ArticleTypes = typeof articleTypes[number];

export function articleTypeValidator(val: string): val is ArticleTypes {
	return (articleTypes as readonly string[]).includes(val);
}

export class Article extends Entity {
	static typeId = entityIdMap['help.article'];

	/** カテゴリー・タグ情報 */
	terms!: Term[];
	/** 種別（guide:利用ガイド,faq:よくある質問） */
	type!: ArticleTypes
	/** 表示フラグ */
	show?: boolean;
	/** 記事の並び順（昇順） */
	sort?: number;
	/** 記事タイトル(ショート) */
	title_short?: string;
	/** 記事タイトル */
	title!: string;
	/** スラッグ */
	slug?: string;
	/** 記事内容 */
	content!: string;
	/** お問い合わせページへ表示する場合の並び順（昇順） */
	sortContact?: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			terms: { isMandatory: true, isArray: true, model: Term },
			type: { isMandatory: true, type: 'string', validator: articleTypeValidator },
			show: { type: 'boolean' },
			sort: { type: 'number' },
			title_short: { type: 'string' },
			title: { isMandatory: true, type: 'string' },
			slug: { type: 'string' },
			content: { isMandatory: true, type: 'string' },
			sortContact: { type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			terms: this.terms,
			type: this.type,
			show: this.show,
			sort: this.sort,
			title_short: this.title_short,
			title: this.title,
			slug: this.slug,
			content: this.content,
			sortContact: this.sortContact,
		});
	}

	isGuide(): boolean {
		return this.type === 'guide';
	}

	isFaq(): boolean {
		return this.type === 'faq';
	}

	get titleShort(): string {
		return this.title_short || this.title;
	}

	get category(): Term | undefined {
		return this.terms.find(term => term.isCategory());
	}

	get tags(): Term[] {
		return this.terms.filter(term => term.isTag());
	}
}
export type ArticleAttr = Omit<ExcludeMethod<Article>, 'typeId'>;