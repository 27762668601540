import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: s.object({
		sessionId: s.string(),
		code: s.string(),
	})
};

export const PostUserLoginTwoFactorAuthResponseStruct = s.object({
	user: s.nullable(UserStruct),
	expire: s.optional(s.number()),
});
export type PostUserLoginTwoFactorAuthResponse = s.Infer<typeof PostUserLoginTwoFactorAuthResponseStruct>;
export type PostUserLoginTwoFactorAuthResponseValues = s.Infer<typeof UserStruct>;

export type PostUserLoginTwoFactorAuthMethodParams = {
	sessionId: string,
	code: string,
};

export function postUserLoginTwoFactorAuth(params: PostUserLoginTwoFactorAuthMethodParams): APIRequest {
	const { ...body } = params;
	return createAPIRequest(
		'post',
		uri`/api/user/login/two-factor-auth`,
		{
			body: body
		},
		ApiParamStructs
	);
}
