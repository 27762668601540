import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CouponRewardStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.omit(CouponRewardStruct, ['id'])
};

export const ValidateCouponResponseStruct = s.object({
	result: s.boolean(),
	errors: s.optional(s.array(s.object({
		code: s.string(),
		message: s.string(),
	})))
});
export type ValidateCouponResponse = s.Infer<typeof ValidateCouponResponseStruct>;

export type ValidateCouponMethodParams = {
	shopId: string;
	reward: s.Infer<typeof ApiParamStructs.body>;
};

export function validateCoupon(params: ValidateCouponMethodParams): APIRequest {
	const { shopId, reward } = params;
	return createAPIRequest(
		'post',
		uri`/api/yahoo/${shopId}/coupon/validate`,
		{
			path: { shopId },
			body: reward
		},
		ApiParamStructs
	);
}
