import equal from "fast-deep-equal";
import ReferenceObject from "./ReferenceObject";
import { toValueType } from "./utils";

type KeyFunction = (val: unknown) => string;

/**
 * keyのオブジェクトをdeep equalするMap
 * 
 * パフォーマンスは期待しないほうが良い
 */
export class DeepMap<K, V> {
	private map: Record<string, Map<K, ReferenceObject<V | undefined>>> = {};
	private toKey: KeyFunction;

	constructor(toKey?: KeyFunction) {
		this.toKey = toKey ?? toValueType;
	}

	deepGet(key: K): V | undefined {
		const ref = this.deepGetRef(key);
		return ref.val;
	}

	deepSet(key: K, val: V): this {
		const ref = this.deepGetRef(key);
		ref.val = val;

		return this;
	}

	private deepGetRef(key: K): ReferenceObject<V | undefined> {
		const type = this.toKey(key);
		const map = this.map[type] ??= new Map();

		const ref = map.get(key);
		if (ref) {
			return ref;
		}

		if (typeof key == 'object' && key !== null) {
			for (const [mapKey, ref] of map) {
				if (equal(key, mapKey)) {
					map.set(key, ref);
					return ref;
				}
			}
		}

		const undefinedRef = new ReferenceObject(undefined);
		map.set(key, undefinedRef);
		return undefinedRef;
	}
}
export default DeepMap;
