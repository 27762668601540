import * as s from 'superstruct';
import { ItemGroupStruct } from './ItemGroupStruct';
import { bodyElementStruct } from './MailTemplateStruct';
import { RewardStruct } from './RewardStruct';

export const destType = ['shipping', 'orderer', 'other'] as const;

const MailStruct = s.object({
	disable: s.optional(s.boolean()),
	subject: s.string(),
	body: s.array(bodyElementStruct),
	signature: s.string(),
});

export const CampaignStruct = s.type({
	itemGroup: ItemGroupStruct,
	rewards: s.array(RewardStruct),

	priority: s.number(),

	name: s.string(),
	beginDate: s.optional(s.number()),
	endDate: s.optional(s.number()),
	reviewDeadlineDate: s.optional(s.number()),
	reviewDeadlineDays: s.optional(s.number()),
	isNeverEnd: s.boolean(),
	isCutDate: s.boolean(),
	isCutDays: s.boolean(),
	isItemReviewRequired: s.boolean(),
	isShopReviewRequired: s.boolean(),
	isAnyReviewRequired: s.boolean(),
	canManyRewards: s.boolean(),
	canChooseDest: s.boolean(),
	defaultDestType: s.enums(destType),
	applicationClosingDays: s.optional(s.number()),
	shouldCloseApplication: s.boolean(),
	followMail: MailStruct,
	requestMail: MailStruct,
	receivedMail: MailStruct,
	isDraft: s.boolean(),

	inProgressDate: s.optional(s.number()),
	waitingReviewDate: s.optional(s.number()),
	finishedDate: s.optional(s.number()),
	cancelledDate: s.optional(s.number()),
});