export const SasagaseErrorCode = {
	'UNEXPECTED_QUERY_RESULT': '想定外のクエリ結果です',
	'EXIST_USERNAME': 'ユーザ名が存在します',
	'INVALID_ID': '無効なIDです',
	'INVALID_PROPERTY_VALUE': '無効なプロパティの値です',
	'INVALID_PROPERTY_TYPE': '無効なプロパティの型です',
	'NOT_EXIST_PROPERTY': 'プロパティがありません',
	'INVALID_SCHEDULE_EXPRESSION': '無効なスケジュール文字列です',
	'EXTERNAL': 'システム外部エラー',
	'ENTITY_NOT_EXISTS': 'エンティティが存在しません',
	'VALUES_NOT_SPECIFIED': '必要な値が指定されていません',
	'INVALID_VALUE': '無効な値です',
	'RAC_NOT_EXISTS': 'RAC番号が存在しません',
	'ID_NOT_EXISTS': 'IDが存在しません',
	'ENTITY_IS_IN_USE': '使用中のエンティティです',
	'CANNOT_MODIFY': '変更できません',
	'EXPIRED': '有効期限が切れています',
	'REQUEST_REWARD_FAILED': '特典の申込みに失敗しました',
	'ID_IS_NOT_CONSISTENT': 'IDが一致しません',
	'NEVER': '実行が想定されていないコードが実行されました',
	'AUTH_FAILURE': '認証が失敗しました',
	'CANNOT_DELETE_SHIPPED': '出荷済みの商品は削除できません',
	'CANNOT_DELETE_ORDERED': '発注済みの商品は削除できません',
	'SHIPPING_ITEM_REQUIRED': '出荷商品が必要です',
	'SAME_FILENAME_EXISTS': '同じ名前のファイルが存在します',
} as const;

type ErrorCode = keyof typeof SasagaseErrorCode;

export class SasagaseError extends Error {
	code: ErrorCode;
	opt?: string;

	constructor(code: ErrorCode, opt?: string) {
		const parenOpt = opt ? `(${opt})` : '';
		const message = SasagaseErrorCode[code] + parenOpt;

		super(message);
		this.name = this.constructor.name;
		this.code = code;
		this.opt = opt;
	}

	toJSON(): Record<string, unknown> {
		return {
			__constructor: this.constructor.name,
			__args: [this.code, this.opt],
		};
	}
}
export default SasagaseError;

export class ExternalError extends SasagaseError {
	constructor(message?: string) {
		super('EXTERNAL', message);
	}
}

export class NeverError extends SasagaseError {
	constructor(...args: never[]) {
		super('NEVER', `不明な値(${args})`);
	}
}

export class ValidateError extends SasagaseError {
	constructor(message?: string) {
		super('EXTERNAL', message);
	}
}