import ObjectAssert from "../../ObjectAssert";
import { UUID } from "../../interface";
import Entity from "../Entity";
import { isUUID } from "../../isUUID";
import { entityIdMap } from "../../const/entity-id-map";

export class Capability extends Entity {
	static typeId = entityIdMap['user.capability'];

	entityId!: UUID;
	entityTypeId!: UUID;
	name!: string;
	expire?: number;
	disabled!: boolean;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			entityId: { isMandatory: true, type: 'string', validator: isUUID },
			entityTypeId: { isMandatory: true, type: 'string', validator: isUUID },
			name: { isMandatory: true, type: 'string' },
			expire: { isMandatory: false, type: 'number' },
			disabled: { isMandatory: true, type: 'boolean' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			entityId: this.entityId,
			entityTypeId: this.entityTypeId,
			name: this.name,
			expire: this.expire,
			disabled: this.disabled,
		});
	}

	isEnabled(now: number = Date.now()): boolean {
		return !this.disabled && (typeof this.expire != 'number' || now < this.expire);
	}
}