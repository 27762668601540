import * as ss from 'superstruct';
import { entityIdMap } from "../../const";
import { UUID } from "../../interface";
import isUUID from "../../isUUID";
import { ExcludeMethod, validator } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { MailContent } from "../MailContent";

export const rakutenMailStatusList = ['queued', 'suspended', 'requeued'] as const;
export type RakutenMailStatus = typeof rakutenMailStatusList[number];

const structRakutenMailStatus = ss.enums(rakutenMailStatusList);
const isRakutenMailStatus = validator(structRakutenMailStatus);

export class RakutenMail extends Entity {
	static typeId = entityIdMap['rakuten.rakutenMail'];

	shopId!: UUID;
	/**
	 * queued: 送信待ち - 最初にキューに入った状態、特に問題が起こらなければ速やかに送信されてキューから削除される
	 * suspended: 停止 - ショップのメール設定に由来するエラーによって送信を停止している状態
	 * requeued: 停止後の送信待ち - `suspended`だったがメール設定変更によって再送信を試みる状態
	 */
	status!: RakutenMailStatus;
	mailContent!: MailContent;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			shopId: { isMandatory: true, validator: isUUID },
			status: { isMandatory: true, validator: isRakutenMailStatus },
			mailContent: { isMandatory: true, model: MailContent },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			shopId: this.shopId,
			status: this.status,
			mailContent: this.mailContent,
		});
	}
}
export type RakutenMailAttr = Omit<ExcludeMethod<RakutenMail>, 'typeId'>;
