import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser from '../createParser';
import { CommandResultStruct, EmptyObjectStruct, MailTemplateStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: MailTemplateStruct
};

export const PostTemplateResponseStruct = CommandResultStruct;
export type PostTemplateResponse = s.Infer<typeof PostTemplateResponseStruct>;
export const parsePostTemplate = createParser(ApiParamStructs.body);

export type PostTemplateMethodParams = {
	shopId: string;
	template: s.Infer<typeof MailTemplateStruct>;
};

export function postTemplate(params: PostTemplateMethodParams): APIRequest {
	const { shopId, template } = params;
	return createAPIRequest(
		'post',
		uri`/api/y-review/${shopId}/template`,
		{
			path: { shopId },
			body: template
		},
		ApiParamStructs
	);
}
