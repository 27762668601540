import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { ContentLevelStruct, ContentStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: s.object({
		level: ContentLevelStruct,
	}),
	body: EmptyObjectStruct
};

export const GetNoticesResponseStruct = s.array(
	ContentStruct
);
export type GetNoticesResponse = s.Infer<typeof GetNoticesResponseStruct>;
export type GetNoticesResponseValues = s.Infer<typeof ContentStruct>;

export type GetNoticesMethodParams = {
	shopId: string;
};

export function getNotices(params: GetNoticesMethodParams): APIRequest {
	const { shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/notice/${shopId}/y-content`,
		{
			path: { shopId },
			query: { level: 'NOTICE' },
		},
		ApiParamStructs
	);
}
