import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		userId: s.string()
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetEntityUserResponseStruct = s.nullable(UserStruct);
export type GetEntityUserResponse = s.Infer<typeof GetEntityUserResponseStruct>;
export type GetEntityUserResponseValues = s.Infer<typeof UserStruct>;

export type GetEntityUserMethodParams = {
	shopId: string;
	userId: string;
};

export function getEntityUser(params: GetEntityUserMethodParams): APIRequest {
	const { shopId, userId } = params;
	return createAPIRequest(
		'get',
		uri`/api/user/entity/${shopId}/user/${userId}`,
		{
			path: { shopId, userId },
		},
		ApiParamStructs
	);
}
