import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, EventSequenceQueryStruct, ItemGroupStruct, ItemGroupType } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EventSequenceQueryStruct,
	body: EmptyObjectStruct
};

export const GetGroupsResponseStruct = s.array(ItemGroupStruct);
export type GetGroupsResponse = s.Infer<typeof GetGroupsResponseStruct>;
export type GetGroupsResponseValues = ItemGroupType;

export type GetGroupsMethodParams = {
	shopId: string;
	seq?: string;
};

export function getGroups(params: GetGroupsMethodParams): APIRequest {
	const { shopId, seq } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/group`,
		{
			path: { shopId },
			query: { seq },
		},
		ApiParamStructs
	);
}
