import { Campaign, Review } from "..";
import { UUID } from "../..";
import ObjectAssert from "../../ObjectAssert";
import { entityIdMap } from "../../const/entity-id-map";
import isUUID from "../../isUUID";
import { ExcludeMethod, isTruthy, throwExp } from "../../lib";
import Entity from "../Entity";
import { OrderItem } from "./OrderItem";
import { Orderer } from "./Orderer";
import { RakutenOrder } from "./RakutenOrder";
import { Reward } from "./Reward";

export const rewardTargetStatus = ['unshipped', 'waitingReviewing', 'reviewExpired', 'campaignFinished', 'pending', 'waitingAppling', 'applingExpired', 'done'] as const;
export type RewardTargetStatus = typeof rewardTargetStatus[number];

interface RewardTargetStatusInfo {
	name: string;
}
export const rewardTargetStatusInfos: Record<RewardTargetStatus, RewardTargetStatusInfo> = {
	'unshipped': { name: '未出荷' },
	'waitingReviewing': { name: 'レビュー待ち' },
	'reviewExpired': { name: 'レビュー期限切れ' },
	'campaignFinished': { name: 'キャンペーン終了' },
	'pending': { name: '処理待ち' },
	'waitingAppling': { name: '特典申込待ち' },
	'applingExpired': { name: '特典申込期限切れ' },
	'done': { name: '申込済' },
} as const;

interface RewardInfo {
	order: RakutenOrder;
	item: OrderItem;
	quantityIdx: number;
	campaign: Campaign;
	rewards: Reward[];
}

export class RewardTarget extends Entity {
	static typeId = entityIdMap['review.rewardTarget'];

	shopId!: UUID;
	orders!: RakutenOrder[];
	campaigns!: Campaign[];
	shopReviews!: Review[];
	itemReviews!: Review[];
	requestIds!: UUID[];
	isItemReviewRequired!: boolean;
	isShopReviewRequired!: boolean;
	isAnyReviewRequired!: boolean;
	canApply!: boolean;
	processedDate?: number;
	formToken?: string;
	requestMailDate?: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			shopId: { isMandatory: true, validator: isUUID },
			orders: { isMandatory: true, isArray: true, model: RakutenOrder },
			campaigns: { isMandatory: true, isArray: true, model: Campaign },
			shopReviews: { isMandatory: true, isArray: true, model: Review },
			itemReviews: { isMandatory: true, isArray: true, model: Review },
			requestIds: { isMandatory: true, isArray: true, validator: isUUID },
			isItemReviewRequired: { isMandatory: true, type: 'boolean' },
			isShopReviewRequired: { isMandatory: true, type: 'boolean' },
			isAnyReviewRequired: { isMandatory: true, type: 'boolean' },
			canApply: { isMandatory: true, type: 'boolean' },
			processedDate: { type: 'number' },
			formToken: { type: 'string' },
			requestMailDate: { type: 'number' },
		});

		this.campaigns.sort((a, b) => a.comparePriority(b));
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			shopId: this.shopId,
			orders: this.orders,
			campaigns: this.campaigns,
			shopReviews: this.shopReviews,
			itemReviews: this.itemReviews,
			requestIds: this.requestIds,
			isItemReviewRequired: this.isItemReviewRequired,
			isShopReviewRequired: this.isShopReviewRequired,
			isAnyReviewRequired: this.isAnyReviewRequired,
			canApply: this.canApply,
			processedDate: this.processedDate,
			formToken: this.formToken,
			requestMailDate: this.requestMailDate,
		});
	}

	get item(): OrderItem {
		return this.orders[0]?.orderItems.find(item => item.rewardTargetId == this.id) || throwExp(false);
	}
	get rac(): number {
		return this.item.rac;
	}
	get sku(): string {
		return this.item.sku;
	}
	/** 注文日(YYYY-MM-DD) */
	get date(): string {
		return this.orders[0]?.orderDate;
	}
	get orderer(): Orderer {
		return this.orders[0]?.orderer;
	}
	get hasShopReview(): boolean {
		return this.shopReviews.length > 0;
	}
	get hasItemReview(): boolean {
		return this.itemReviews.length > 0;
	}
	get hasRequest(): boolean {
		return this.requestIds.length > 0;
	}

	getExpiredCond(): number | null {
		const applicationClosingDays = this.campaigns.filter(camp => camp.getState() !== 'cancelled' && camp.shouldCloseApplication)
			.map(camp => camp.applicationClosingDays ?? 0);
		return applicationClosingDays.length > 0 ? Math.max(0, ...applicationClosingDays) : null;
	}

	getWaitingReviewingCond(): number {
		const shippingDates = this.orders.map(order => order.shippingDate).filter(isTruthy);
		const latestShippingDate = Math.max(...shippingDates);
		const reviewExpirations = this.campaigns.map(camp => camp.getReviewDateExpiration(latestShippingDate) || 0);
		return Math.max(0, ...reviewExpirations);
	}

	/**
	 * レビュー条件を達成したレビュー日時を取得
	 * 
	 * 条件に達していない場合は`NaN`を返す
	 */
	getFulfilledReviewDate(): number {
		const itemDate = this.itemReviews.length <= 0 ? NaN : Math.min(...this.itemReviews.map((review) => review.date));
		const shopDate = this.shopReviews.length <= 0 ? NaN : Math.min(...this.shopReviews.map((review) => review.date));
		if (this.isItemReviewRequired && this.isShopReviewRequired) {
			return Math.max(itemDate, shopDate);
		} else if (this.isAnyReviewRequired) {
			return Math.min(itemDate || shopDate, shopDate || itemDate); // 両方NaNの時はどちらもNaNになる
		} else if (this.isItemReviewRequired) {
			return itemDate;
		} else if (this.isShopReviewRequired) {
			return shopDate;
		} else {
			// どちらのレビューも不要
			return 0;
		}
	}

	/** 最終(複数キャンペーンの場合)の申込期限を取得 */
	getLastApplingExpiration(): number {
		const applingBegin = this.requestMailDate ?? this.processedDate;
		return Math.max(...this.campaigns.map((camp) => camp.getApplicationClosingDate(applingBegin)));
	}

	/**
	 * 申込済み -> done
	 * 処理済みで、申請期限切れ -> applingExpired
	 * 処理済みで、申請期限が切れていない -> waitingAppling
	 * 有効なレビューが付いている -> pending
	 * キャンペーンが終了している -> campaignFinished
	 * 発送済みで、レビュー期限中 -> waitingReviewing
	 * 発送済みで、レビュー期限切れで、未発送がある -> unshipped
	 * 発送済みで、レビュー期限切れで、未発送がない -> reviewExpired
	 * 全て未発送 -> unshipped
	 */
	getStatus(): RewardTargetStatus {
		if (this.hasRequest) {
			return 'done'; // 申込済み
		} else if (this.processedDate) {
			const applingBegin = this.requestMailDate ?? this.processedDate;
			const isAllClosed = this.campaigns.every((camp) =>
				camp.getApplicationClosingDate(applingBegin) < Date.now() ||
				camp.getState() === 'cancelled'
			);
			const hasCancelled = this.campaigns.some((camp) => camp.getState() === 'cancelled');
			if (isAllClosed) {
				return hasCancelled ? 'campaignFinished' : 'applingExpired'; // 申込期限切れ
			}
			return 'waitingAppling'; // 申込待ち
		}

		const shippingDates = this.orders.map(order => order.shippingDate).filter(isTruthy);
		const latestShippingDate = Math.max(...shippingDates);
		const reviewExpirations = this.campaigns.map(camp => camp.getReviewDateExpiration(latestShippingDate) || 0);
		const expiration = Math.max(0, ...reviewExpirations);
		const hasShipped = shippingDates.length > 0;

		if (hasShipped && this.getFulfilledReviewDate() < expiration) {
			return 'pending'; // 有効なレビューがあるので処理待ち
		} else if (this.campaigns.every(camp => !camp.isAlive())) {
			return 'campaignFinished'; // キャンペーン終了
		} else if (!hasShipped) {
			return 'unshipped'; // 全て未発送
		} else if (Date.now() <= expiration) {
			return 'waitingReviewing'; // レビュー待ち
		} else if (shippingDates.length === this.orders.length) {
			return 'reviewExpired'; // レビュー期限切れ
		} else {
			return 'unshipped'; // 未発送があるので、それが発送されればレビュー期限が伸びる
		}
	}

	getReviewedOrder(): RakutenOrder | null {
		const reviews =
				this.isItemReviewRequired ? this.itemReviews :
				this.isShopReviewRequired ? this.shopReviews : [...this.itemReviews, ...this.shopReviews];
		const order = this.orders.find(order => reviews.some(review => review.orderNumber === order.number)) || null;
		return !order && !this.isItemReviewRequired && this.hasShopReview ?
				this.orders[0] :
				order;
	}

	getPossibleRewardInfos(): RewardInfo[] {
		const shippingDates = this.orders.map(order => order.shippingDate).filter(isTruthy);
		const latestShippingDate = Math.max(...shippingDates);

		const res: RewardInfo[] = [];
		for (const campaign of this.campaigns) {
			const expiration = campaign.getReviewDateExpiration(latestShippingDate);
			const reviewDate = this.getFulfilledReviewDate();
			if (isNaN(reviewDate) || expiration < reviewDate) {
				continue;
			}

			if (campaign.canManyRewards) {
				for (const order of this.orders) {
					const item = order.orderItems.find(item => item.rewardTargetId == this.id);
					if (!item) {
						continue;
					}
					for (let i = 0; i < item.quantity; i++) {
						res.push({
							order,
							item,
							quantityIdx: i + 1,
							campaign,
							rewards: campaign.rewards,
						});
					}
				}
			} else {
				// 「複数注文でも特典1つ」の場合はレビューが付いた注文を参照する
				const order = this.getReviewedOrder() || throwExp();
				const item = order.orderItems.find(item => item.rewardTargetId == this.id) || throwExp();
				res.push({
					order,
					item,
					quantityIdx: 1,
					campaign,
					rewards: campaign.rewards,
				});
			}
		}
		return res;
	}
}
export type RewardTargetAttr = Omit<ExcludeMethod<RewardTarget>, 'typeId' | 'date' | 'sku' | 'orderer' | 'rac' | 'item' | 'hasShopReview' | 'hasItemReview' | 'hasRequest'>;
