import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetTopReviewSummaryResponseStruct = s.object({
	// レビュー獲得件数
	numberOfReviews: s.object({
		yesterday: s.number(),
		thisMonth: s.number(),
	}),
	// 申込み件数(記入済み・待ち)
	reviewForm: s.object({
		completed: s.number(),
		waiting: s.number(),
	}),
	// レビュー投稿率
	reviewPostingRate: s.object({
		lastMonth: s.number(),
		thisMonth: s.number(),
	}),
	// キャンペーン獲得比率
	campaignAcquistionRate: s.object({
		campaigns: s.array(s.object({
			campaignId: s.string(),
			campaignName: s.string(),
			number: s.number(),
		}))
	}),
});
export type GetTopReviewSummaryResponse = s.Infer<typeof GetTopReviewSummaryResponseStruct>;

export type GetTopReviewSummaryMethodParams = {
	shopId: string;
};

export function getTopReviewSummary(params: GetTopReviewSummaryMethodParams): APIRequest {
	const { shopId } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/review/summary/top`,
		{
			path: { shopId },
		},
		ApiParamStructs
	);
}
