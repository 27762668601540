import keyValidator from "../../keyValidator";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Model from "../Model";

const isInteger = Number.isInteger as (val: unknown) => val is number;
const cookieSameSite: ReadonlyArray<CookieSameSite> = ['Strict', 'Lax', 'None'] as const;
const cookiePriority: ReadonlyArray<CookiePriority> = ['Low', 'Medium', 'High'] as const;
const cookieSourceScheme: ReadonlyArray<CookieSourceScheme> = ['Unset', 'NonSecure', 'Secure'] as const;
const isCookieSameSite = keyValidator(cookieSameSite);
const isCookiePriority = keyValidator(cookiePriority);
const isCookieSourceScheme = keyValidator(cookieSourceScheme);

export class Cookie extends Model implements CookieParam {
	name!: string;
	value!: string;
	url?: string;
	domain?: string;
	path?: string;
	secure?: boolean;
	httpOnly?: boolean;
	sameSite?: CookieSameSite;
	expires?: TimeSinceEpoch;
	priority?: CookiePriority;
	sameParty?: boolean;
	sourceScheme?: CookieSourceScheme;
	sourcePort?: integer;

	constructor(obj: unknown) {
		super();

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: true, type: 'string' },
			value: { isMandatory: true, type: 'string' },
			url: { type: 'string' },
			domain: { type: 'string' },
			path: { type: 'string' },
			secure: { type: 'boolean' },
			httpOnly: { type: 'boolean' },
			sameSite: { type: 'string', validator: isCookieSameSite },
			expires: { type: 'number' },
			priority: { type: 'string', validator: isCookiePriority },
			sameParty: { type: 'boolean' },
			sourceScheme: { type: 'string', validator: isCookieSourceScheme },
			sourcePort: { type: 'number', validator: isInteger },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			value: this.value,
			url: this.url,
			domain: this.domain,
			path: this.path,
			secure: this.secure,
			httpOnly: this.httpOnly,
			sameSite: this.sameSite,
			expires: this.expires,
			priority: this.priority,
			sameParty: this.sameParty,
			sourceScheme: this.sourceScheme,
			sourcePort: this.sourcePort,
		});
	}
}
export default Cookie;
export type CookieAttr = ExcludeMethod<Cookie>;

/* COPY from Puppeteer defined types */
type CookieSameSite = ('Strict' | 'Lax' | 'None');
type CookiePriority = ('Low' | 'Medium' | 'High');
type CookieSourceScheme = ('Unset' | 'NonSecure' | 'Secure');
type TimeSinceEpoch = number;
type integer = number

interface CookieParam {
	/**
	 * Cookie name.
	 */
	name: string;
	/**
	 * Cookie value.
	 */
	value: string;
	/**
	 * The request-URI to associate with the setting of the cookie. This value can affect the
	 * default domain, path, source port, and source scheme values of the created cookie.
	 */
	url?: string;
	/**
	 * Cookie domain.
	 */
	domain?: string;
	/**
	 * Cookie path.
	 */
	path?: string;
	/**
	 * True if cookie is secure.
	 */
	secure?: boolean;
	/**
	 * True if cookie is http-only.
	 */
	httpOnly?: boolean;
	/**
	 * Cookie SameSite type.
	 */
	sameSite?: CookieSameSite;
	/**
	 * Cookie expiration date, session cookie if not set
	 */
	expires?: TimeSinceEpoch;
	/**
	 * Cookie Priority.
	 */
	priority?: CookiePriority;
	/**
	 * True if cookie is SameParty.
	 */
	sameParty?: boolean;
	/**
	 * Cookie source scheme type.
	 */
	sourceScheme?: CookieSourceScheme;
	/**
	 * Cookie source port. Valid values are {-1, [1, 65535]}, -1 indicates an unspecified port.
	 * An unspecified port value allows protocol clients to emulate legacy cookie scope for the port.
	 * This is a temporary ability and it will be removed in the future.
	 */
	sourcePort?: integer;
}
