import * as s from 'superstruct';

export const UUIDStruct = s.string();

const CapabilityStruct = s.type({
	id: UUIDStruct,
	entityId: UUIDStruct,
	entityTypeId: UUIDStruct,
	name: s.string(),
	expire: s.optional(s.number()),
	disabled: s.boolean(),
});

export const TokenStruct = s.type({
	userId: UUIDStruct,
	entityId: UUIDStruct,
	entityTypeId: UUIDStruct,
	capabilityId: UUIDStruct,
	token: s.string(),
	type: s.string(),
	expire: s.number(),
})

export const UserStruct = s.type({
	id: UUIDStruct,
	name: s.optional(s.string()),
	mail: s.optional(s.string()),
	setPass: s.optional(s.boolean()),
	capabilities: s.optional(s.array(CapabilityStruct)),
	tokens: s.optional(s.array(TokenStruct)),
});

export const SessionStruct = s.type({
	id: UUIDStruct,
	user: s.optional(UserStruct),
	expire: s.optional(s.number())
})