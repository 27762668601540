import { FormData } from 'formdata-node';
import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser, { jsoned } from '../createParser';
import { CommandResultStruct, EmptyObjectStruct, RewardStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.any(),
	formData: {
		reward: jsoned(RewardStruct),
		image: s.optional(s.any()),
	},
};

export const PostRewardResponseStruct = CommandResultStruct;
export type PostRewardResponse = s.Infer<typeof PostRewardResponseStruct>;
export const parsePostRewardRequest = createParser(s.type(ApiParamStructs.formData));

export type PostRewardMethodParams = {
	shopId: string;
	reward: s.Infer<typeof RewardStruct>;
	file?: File;
};

export function postReward(params: PostRewardMethodParams): APIRequest {
	const { shopId, reward, file } = params;
	const data = new FormData();
	data.append('reward', JSON.stringify(reward));
	if (file) { data.append('image', file, file.name); }
	return createAPIRequest(
		'post',
		uri`/api/y-review/${shopId}/reward`,
		{
			path: { shopId },
			body: data
		},
		ApiParamStructs
	);
}
