import { hasToJSON } from "./utils";

/**
 * 参照オブジェクト
 * 複数箇所から参照されている値を同時に変えたり、プリミティブ値をオブジェクト参照の用に扱うために使用する
 * 
 * toJSONにより、JSON化したら透過的に設定値に置き換わる
 * 
 * ```js
 * const map = new Map();
 * const ref = new ReferenceObject(1); // プリミティブ値
 * map.set('key1', ref);
 * map.set('key2', ref);
 * 
 * ref.val = 2; // mapが'key1', 'key2'で参照している値を同時に変えられる
 * 
 * const obj = {
 *     ref,
 *     key1: map.get('key1'),
 *     key2: map.get('key2'),
 * };
 * console.log(JSON.stringify(obj)); // {"ref":2,"key1":2,"key2":2}
 * ```
 */
export class ReferenceObject<V = unknown> {
	val: V;

	constructor(val: V) {
		this.val = val;
	}

	toJSON(): unknown {
		// toJSON が返した値そのものが toJSON を持ったメソッドだと、
		// その toJSON は実行されないので実行する
		if (hasToJSON(this.val)) {
			return this.val.toJSON();
		}
		return this.val;
	}
}
export default ReferenceObject;
