import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct, ItemGroupStruct, ItemGroupType } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: ItemGroupStruct
};

export const PostGroupResponseStruct = CommandResultStruct;
export type PostGroupResponse = s.Infer<typeof PostGroupResponseStruct>;

export type PostGroupMethodParams = {
	shopId: string;
	group: ItemGroupType;
};

export function postGroup(params: PostGroupMethodParams): APIRequest {
	const { shopId, group } = params;
	return createAPIRequest(
		'post',
		uri`/api/y-review/${shopId}/group`,
		{
			path: { shopId },
			body: group
		},
		ApiParamStructs
	);
}
