import * as s from 'superstruct';

export type ItemGroupType = {
	id: string,
	name: string,
	isRef: boolean,
	isAll: boolean,
	sku: string[],
	excludeSku: string[],
	childGroups: ItemGroupType[],
	excludeChildGroups: ItemGroupType[]
};

const _ItemGroupStruct = s.type({
	id: s.string(),
	name: s.string(),
	isRef: s.boolean(),
	isAll: s.boolean(),
	sku: s.array(s.string()),
	excludeSku: s.array(s.string()),
});
type ItemGroupStruct = s.Infer<typeof _ItemGroupStruct> & {
	childGroups: ItemGroupStruct[];
	excludeChildGroups: ItemGroupStruct[];
};

export const ItemGroupStruct: s.Struct<ItemGroupStruct> = s.intersection([_ItemGroupStruct, s.type({
	childGroups: s.lazy(() => s.array(ItemGroupStruct)),
	excludeChildGroups: s.lazy(() => s.array(ItemGroupStruct)),
})]);