import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct, ItemGroupStruct, ItemGroupType } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
		groupId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: ItemGroupStruct
};

export const PutGroupResponseStruct = CommandResultStruct;
export type PutGroupResponse = s.Infer<typeof PutGroupResponseStruct>;

export type PutGroupMethodParams = {
	shopId: string;
	groupId: string;
	group: ItemGroupType;
};

export function putGroup(params: PutGroupMethodParams): APIRequest {
	const { shopId, groupId, group } = params;
	return createAPIRequest(
		'put',
		uri`/api/y-review/${shopId}/group/${groupId}`,
		{
			path: { shopId, groupId },
			body: group
		},
		ApiParamStructs
	);
}
