import { entityIdMap } from "../../const/entity-id-map";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class EntityType extends Entity {
	static typeId = entityIdMap['user.entityType'];

	name?: string;
	service!: string;
	table!: string;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { type: 'string' },
			service: { isMandatory: true, type: 'string' },
			table: { isMandatory: true, type: 'string' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			service: this.service,
			table: this.table,
		});
	}
}
