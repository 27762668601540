import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		token: s.string()
	}),
	query: EmptyObjectStruct,
	body: s.object({
		password: s.string()
	})
};

export const ResetPasswordResponseStruct = s.boolean();
export type ResetPasswordResponse = s.Infer<typeof ResetPasswordResponseStruct>;

export type ResetPasswordMethodParams = {
	token: string;
	password: string;
};

export function resetPassword(params: ResetPasswordMethodParams): APIRequest {
	const { token, password } = params;
	return createAPIRequest(
		'post',
		uri`/api/user/password/reset/${token}`,
		{
			path: { token },
			body: { password }
		},
		ApiParamStructs
	);
}
