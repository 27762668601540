import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CampaignStruct, EmptyObjectStruct, EventSequenceQueryStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		campaignId: s.string(),
	}),
	query: EventSequenceQueryStruct,
	body: EmptyObjectStruct
};

export const GetCampaignResponseStruct = s.nullable(CampaignStruct);
export type GetCampaignResponse = s.Infer<typeof GetCampaignResponseStruct>;
export type GetCampaignResponseValues = s.Infer<typeof CampaignStruct>;

export type GetCampaignMethodParams = {
	shopId: string;
	campaignId: string;
	seq?: string;
};

export function getCampaign(params: GetCampaignMethodParams): APIRequest {
	const { shopId, campaignId, seq } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/campaign/${campaignId}`,
		{
			path: { shopId, campaignId },
			query: { seq },
		},
		ApiParamStructs
	);
}
