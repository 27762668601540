import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const UserLoginBySessionResponseStruct = s.object({
	user: s.nullable(UserStruct),
	expire: s.optional(s.number()),
});
export type UserLoginBySessionResponse = s.Infer<typeof UserLoginBySessionResponseStruct>;
export type UserLoginBySessionResponseValues = s.Infer<typeof UserStruct>;

export type UserLoginBySessionMethodParams = s.Infer<typeof ApiParamStructs.body>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function userLoginBySession(params: UserLoginBySessionMethodParams): APIRequest {
	return createAPIRequest(
		'get',
		uri`/api/user/login`,
		{},
		ApiParamStructs
	);
}
