import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
		template: s.string(),
	}),
	query: s.object({
		discountType: s.string(),
		discountFactor: s.string(),
	}),
	body: EmptyObjectStruct,
};

// nodeだとBlobは`import { Blob } from 'node:buffer'`にあるが、ブラウザ側だとこのモジュールがimportできない
// 条件分岐で動的import(`await import('node:buffer')`)しようにもtsconfigのtargetや何やかんやの都合でtop level awaitができない……
export const GetRewardTemplateImageResponseStruct: s.Struct<Blob, null> =
	globalThis.Blob
		? s.instance(Blob)
		: s.define('Blob', (blob: unknown) => typeof blob == 'object' && blob !== null && blob.constructor.name === 'Blob');
export type GetRewardTemplateImageResponse = s.Infer<typeof GetRewardTemplateImageResponseStruct>;

export type GetRewardTemplateImageMethodParams = {
	shopId: string;
	template: string;
	discountType: string;
	discountFactor: string;
};

export function getRewardTemplateImage(params: GetRewardTemplateImageMethodParams): APIRequest {
	const { shopId, template, discountType, discountFactor } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/reward/image/template/${template}`,
		{
			path: { shopId, template },
			query: {
				discountType,
				discountFactor,
			},
		},
		ApiParamStructs,
		{ responseType: 'blob' },
	);
}
