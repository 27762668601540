import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		token: s.string()
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetUserByTokenResponseStruct = s.nullable(UserStruct);
export type GetUserByTokenResponse = s.Infer<typeof GetUserByTokenResponseStruct>;
export type GetUserByTokenResponseValues = s.Infer<typeof UserStruct>;

export type GetUserByTokenMethodParams = {
	token: string;
};

export function getUserByToken(params: GetUserByTokenMethodParams): APIRequest {
	const { token } = params;
	return createAPIRequest(
		'get',
		uri`/api/user/user/token/${token}`,
		{
			path: { token },
		},
		ApiParamStructs
	);
}
