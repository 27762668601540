import * as s from 'superstruct';
import { destType } from './CampaignStruct';
import { OrderItemStruct, YahooOrderStruct } from './YahooOrderStruct';

export const RewardRequestStruct = s.type({
	id: s.string(),
	order: YahooOrderStruct,
	orderItem: OrderItemStruct,
	rewardTargetId: s.string(),
	campaignId: s.string(),
	selectedRewardId: s.string(),
	rewardOptionValues: s.optional(s.record(s.string(), s.unknown())),
	receivedMailDate: s.optional(s.number()),
	destType: s.enums(destType),
	destName: s.optional(s.string()),
	destZip: s.optional(s.string()),
	destAddress: s.optional(s.string()),
	destBuilding: s.optional(s.string()),
	destPhone: s.optional(s.string()),
	isTransacted: s.boolean(),
	transactDate: s.optional(s.number()),
	createdDate: s.number(),
});

export const RewardRequestDetailStruct = s.type({
	id: s.string(),
	receivedMailDate: s.optional(s.number()),
	rewardOptionValues: s.optional(s.record(s.string(), s.unknown())),
	destType: s.enums(destType),
	destName: s.optional(s.string()),
	destZip: s.optional(s.string()),
	destAddress: s.optional(s.string()),
	destBuilding: s.optional(s.string()),
	destPhone: s.optional(s.string()),
	isTransacted: s.boolean(),
	transactDate: s.optional(s.number()),
	orderNumber: s.string(),
	ordererName: s.string(),
	campaignName: s.string(),
	rewardName: s.string(),
	sku: s.string(),
	shippingDate: s.number(),
	shopReviewDate: s.optional(s.number()),
	itemReviewDate: s.optional(s.number()),
	requestMailDate: s.optional(s.number()),
	followMailDate: s.optional(s.number()),
});