import { FormData } from 'formdata-node';
import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser, { jsoned } from '../createParser';
import { CommandResultStruct, EmptyObjectStruct, RewardStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
		rewardId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.any(),
	formData: {
		reward: jsoned(RewardStruct),
		image: s.optional(s.any()),
	},
};

export const PutRewardResponseStruct = CommandResultStruct;
export type PutRewardResponse = s.Infer<typeof PutRewardResponseStruct>;
export const parsePutRewardRequest = createParser(s.type(ApiParamStructs.formData));

export type PutRewardMethodParams = {
	shopId: string;
	rewardId: string;
	reward: s.Infer<typeof RewardStruct>;
	file?: File;
};

export function putReward(params: PutRewardMethodParams): APIRequest {
	const { shopId, rewardId, reward, file } = params;
	const data = new FormData();
	data.append('reward', JSON.stringify(reward));
	if (file) { data.append('image', file, file.name); }
	return createAPIRequest(
		'put',
		uri`/api/y-review/${shopId}/reward/${rewardId}`,
		{
			path: { shopId, rewardId },
			body: data
		},
		ApiParamStructs
	);
}
