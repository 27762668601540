import { z } from "zod";

export interface BeginOAuthResponse {
	appId: string;
	state: string;
	nonce: string;
	codeChallenge: string;
}

export const schemaAddressType = z.enum(['shipping', 'orderer']);
export type AddressType = z.infer<typeof schemaAddressType>;

export const schemaAddressRequest = z.object({
	orderNumber: z.string(),
	type: schemaAddressType,
});
export type AddressRequest = z.infer<typeof schemaAddressRequest>;

export const schemaYahooAddress = z.object({
	firstName: z.string(),
	firstNameKana: z.string(),
	lastName: z.string(),
	lastNameKana: z.string(),
	zipCode: z.string(),
	prefecture: z.string(),
	city: z.string(),
	address1: z.string(),
	address2: z.string(),
	phoneNumber: z.string(),
	section1Value: z.string(),
	section2Value: z.string(),
});
export type YahooAddress = z.infer<typeof schemaYahooAddress>;

export const schemaFetchAddressesResponse = z.object({
	orderNumber: z.string(),
	type: schemaAddressType,
	shippingAddress: schemaYahooAddress,
	ordererAddress: schemaYahooAddress,
	isSameAddress: z.boolean(),
}).array();
export type FetchAddressesResponse = z.infer<typeof schemaFetchAddressesResponse>;

export const schemaGetShopsOptions = z.object({
	ids: z.string().uuid().array(),
});
export type GetShopsOptions = z.infer<typeof schemaGetShopsOptions>;

const schemaShop = z.object({
	id: z.string().uuid(),
	name: z.string(),
	yahooId: z.string(),
	yahooPass: z.string(),
	isTestShop: z.boolean().optional(),
	yahooStoreAccount: z.string(),
	publicKey: z.string(),
	publicKeyVersion: z.string(),
	publicKeyExpiration: z.number().optional(),
	lastReviewCheckDate: z.number().optional(),
});
export const schemaGetShopResponse = schemaShop;
export type GetShopResponse = z.infer<typeof schemaGetShopResponse>;

export const schemaGetShopsResponse = schemaShop.array();
export type GetShopsResponse = z.infer<typeof schemaGetShopsResponse>;

export const schemaPutShopRequest = schemaShop.partial().omit({
	id: true,
});
export type PutShopRequest = z.infer<typeof schemaPutShopRequest>;

const schemaShopToken = z.object({
	accessToken: z.number().optional(),
	refreshToken: z.number().optional(),
});
export const schemaGetShopTokenExpirationResponse = schemaShopToken;
export type GetShopTokenExpirationResponse = z.infer<typeof schemaGetShopTokenExpirationResponse>;

export const schemaFetchOrdersOptions = z.object({
	shopIds: z.string().uuid().array().optional(),
	orderNumbers: z.string().array().optional(),
	forNewOrder: z.boolean().optional(),
	prevDays: z.number().optional(),
});
export type FetchOrdersOptions = z.infer<typeof schemaFetchOrdersOptions>;

export const schemaFetchOrdersResult = z.record(z.string(), z.object({
	newOrder: z.string().array(),
	changedOrder: z.string().array(),
	cancelledOrder: z.string().array(),
}).nullable());
export type FetchOrdersResult = z.infer<typeof schemaFetchOrdersResult>;

export const schemaYahooOrder = z.object({
	orderNumber: z.string(),
	billFirstName: z.string(),
	billFirstNameKana: z.string().optional(),
	billLastName: z.string(),
	billLastNameKana: z.string().optional(),
	billZipCode: z.string().optional(),
	billPrefecture: z.string().optional(),
	billCity: z.string().optional(),
	billAddress1: z.string().optional(),
	billAddress2: z.string().optional(),
	billPhoneNumber: z.string().optional(),
	billMailAddress: z.string(),
	shipFirstName: z.string().optional(),
	shipFirstNameKana: z.string().optional(),
	shipLastName: z.string().optional(),
	shipLastNameKana: z.string().optional(),
	shipZipCode: z.string().optional(),
	shipPrefecture: z.string().optional(),
	shipCity: z.string().optional(),
	shipAddress1: z.string().optional(),
	shipAddress2: z.string().optional(),
	shipPhoneNumber: z.string().optional(),
	orderStatus: z.string(),
	orderTime: z.string(),
	shipStatus: z.string(),
	firstOrderDoneDate: z.string(),
	item: z.object({
		lineId: z.number(),
		itemId: z.string(),
		title: z.string(),
		subCode: z.string(),
		quantity: z.number(),
	}).array(),
	lastUpdateTime: z.string(),
	isLogin: z.boolean(),
});
export type YahooOrder = z.infer<typeof schemaYahooOrder>;

export const schemaFetchReviewsOptions = z.object({
	shopIds: z.string().uuid().array().optional(),
	prevDays: z.number().optional(),
	// TODO: isFullCheck実装
	// isFullCheck: z.boolean().optional(),
});
export type FetchReviewsOptions = z.infer<typeof schemaFetchReviewsOptions>;

export const schemaFetchReviewsResult = z.record(z.string(), z.object({
	newReviews: z.number(),
	modifiedReviews: z.number(),
}).nullable());
export type FetchReviewsResult = z.infer<typeof schemaFetchReviewsResult>;

export const schemaReview = z.object({
	date: z.string(),
	point: z.number(),
	name: z.string(),
	/** sku or nullの時はショップレビュー */
	sku: z.string().nullable(),
	orderNumber: z.string(),
	title: z.string(),
	content: z.string(),
});
export type Review = z.infer<typeof schemaReview>;

export const schemaCoupon = z.object({
	id: z.string().uuid(),
	issueFreq: z.enum(['individual', 'daily', 'weekly', 'monthly']), //z.string(),
	validPeriod: z.enum(['days', 'nextMonth', 'secondNextMonth']), // z.string(),
	validDays: z.number(),

	name: z.string(),
	description: z.string(),
	categoryId: z.string(),
	discountType: z.string(), // 1, 2, 3
	discountPrice: z.number().optional(),
	discountRate: z.number().optional(),
	dispFlg: z.boolean(),
	image: z.string(),
	linkUrl: z.string().optional(),
	canCombineUse: z.boolean(),
	countUserUseLimit: z.number().optional(),
	countAllUseLimit: z.number().optional(),
	orderType: z.string(), // 0, 1, 2
	orderPrice: z.number().optional(),
	orderCount: z.number().optional(),
	itemDesignation: z.string(), // 3, 1, 4
	targetItemIdStrings: z.string().optional(),
	targetItemTagStrings: z.string().optional(),
});
export type Coupon = z.infer<typeof schemaCoupon>;

export const schemaIssueCouponOptions = z.object({
	date: z.union([z.string(), z.number(), z.date()]).optional(),
});
export type IssueCouponOptions = z.infer<typeof schemaIssueCouponOptions>;
