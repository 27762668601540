import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, MailContentStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string()
	}),
	query: EmptyObjectStruct,
	body: MailContentStruct
};

export const SendReviewMailResponseStruct = s.boolean();
export type SendReviewMailResponse = s.Infer<typeof SendReviewMailResponseStruct>;

export type SendReviewMailMethodParams = {
	shopId: string;
	mailContent: s.Infer<typeof MailContentStruct>;
};

export function sendReviewMail(params: SendReviewMailMethodParams): APIRequest {
	const { shopId, mailContent  } = params;
	return createAPIRequest(
		'put',
		uri`/api/user/mail/review/${shopId}`,
		{
			path: { shopId },
			body: mailContent
		},
		ApiParamStructs
	);
}
