import DeepMap from "./DeepMap";
import ReferenceObject from "./ReferenceObject";
import ReferencePointer, { POINTER_KEY } from "./ReferencePointer";
import { NormalizedBody, hasToJSON, isNormalizeTarget, isObject, toKey, toValueType } from "./utils";

const $$loop = Symbol('Circular reference');

type TargetValue = unknown;
type NormalizedKey = string;

export function normalize(obj: unknown, replacer: (key: string, val: unknown) => unknown = (_, val) => val): NormalizedBody {
	/** インスタンス単位での同値比較用Map */
	const instanceMap = new Map<TargetValue, ReferenceObject>();
	/** 深い同値比較用Map */
	const deepMap = new DeepMap<TargetValue, ReferenceObject>(toKey);
	const normalized: Record<NormalizedKey, unknown> = {};

	let num = 0;
	const genNormalizedKey = (val: unknown) => `${toValueType(val)}${++num}`;

	const _normalize = (obj: TargetValue): unknown => {
		const mapKey = obj;
		let insRef = instanceMap.get(mapKey);
		if (insRef) {
			// 既に処理済みのインスタンスなら既存の参照オブジェクトを返す
			if (insRef.val !== $$loop) {
				return insRef;
			}
			// 循環参照で処理中のインスタンスなら正規化対象かどうかに関わらず参照ポインタに置き換える
			const key = genNormalizedKey(obj);

			normalized[key] = insRef;
			const pointerRef = new ReferenceObject(new ReferencePointer(key));
			instanceMap.set(mapKey, pointerRef);
			return pointerRef;
		}
		insRef = new ReferenceObject($$loop);
		instanceMap.set(mapKey, insRef);

		// toJSON前と後の両方で判定する
		let isTarget = isNormalizeTarget(obj);

		if (hasToJSON(obj)) {
			obj = obj.toJSON();
			isTarget = isTarget || isNormalizeTarget(obj);
		}

		// DeepMapは処理が重いので対象外のオブジェクトはgetもsetもしたくない
		const ref = isTarget ? deepMap.deepGet(mapKey) : null;
		if (!ref) {
			// 初回参照(もしくは対象外)は値をそのまま保持(オブジェクト内部は再帰的に処理する)
			let ret;
			if (isObject(obj)) {
				ret = {} as typeof obj;
				for (const [key, value] of Object.entries(obj)) {
					const escKey = key.startsWith(POINTER_KEY) ? `${POINTER_KEY}${key}` : key;
					ret[escKey] = _normalize(replacer(escKey, value));
				}
			} else if (Array.isArray(obj)) {
				ret = obj.map((child, idx) => _normalize(replacer(idx.toString(), child)));
			} else {
				ret = obj;
			}
			if (isTarget) {
				const newDeepRef = new ReferenceObject(ret);
				deepMap.deepSet(mapKey, newDeepRef);
				insRef.val = newDeepRef;
			} else {
				insRef.val = ret;
			}
		} else if (!ReferencePointer.of(ref.val)) {
			// 2回目参照は値を参照ポインタに置き換える
			const key = genNormalizedKey(obj);

			normalized[key] = ref.val;
			ref.val = new ReferencePointer(key);
			insRef.val = ref;
		} else {
			// それ以降は何もしない
			insRef.val = ref;
		}

		// 循環参照されていたら参照ポインタに変わっているので取得しなおす
		return instanceMap.get(mapKey);
	};
	const ret = _normalize(replacer('', obj));
	return {
		target: ret,
		_normalized: normalized,
	};
}
export default normalize;
