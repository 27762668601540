import { UUID } from "../..";
import { entityIdMap } from "../../const/entity-id-map";
import { isRecord } from "../../isRecord";
import isUUID from "../../isUUID";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
import { OrderItem } from "./OrderItem";
import { RakutenOrder } from "./RakutenOrder";

export const destType = ['shipping', 'orderer', 'other'] as const;
export type DestType = typeof destType[number];

export function destTypeValidator(val: unknown): val is DestType {
	return typeof val == 'string' && (destType as ReadonlyArray<string>).includes(val);
}

export class RewardRequest<D extends DestType = DestType> extends Entity {
	static typeId = entityIdMap['review.rewardRequest'];

	order!: RakutenOrder;
	orderItem!: OrderItem;
	quantityIdx!: number;
	rewardTargetId!: UUID;
	campaignId!: UUID;
	selectedRewardId!: UUID;
	rewardOptionValues?: Record<string, unknown>;
	receivedMailDate?: number;
	destType!: D;
	destName?: string;
	destZip?: string;
	destAddress?: string;
	destBuilding?: string;
	destPhone?: string;
	isTransacted!: boolean;
	transactDate?: number;
	createdDate!: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			order: { isMandatory: true, model: RakutenOrder },
			orderItem: { isMandatory: true, model: OrderItem },
			quantityIdx: { isMandatory: true, type: 'number' },
			rewardTargetId: { isMandatory: true, validator: isUUID },
			campaignId: { isMandatory: true, validator: isUUID },
			selectedRewardId: { isMandatory: true, validator: isUUID },
			rewardOptionValues: { validator: isRecord },
			receivedMailDate: { type: 'number' },
			destType: { isMandatory: true, validator: destTypeValidator },
			destName: { type: 'string' },
			destZip: { type: 'string' },
			destAddress: { type: 'string' },
			destBuilding: { type: 'string' },
			destPhone: { type: 'string' },
			isTransacted: { isMandatory: true, type: 'boolean' },
			transactDate: { type: 'number' },
			createdDate: { isMandatory: true, type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			order: this.order,
			orderItem: this.orderItem,
			quantityIdx: this.quantityIdx,
			rewardTargetId: this.rewardTargetId,
			selectedRewardId: this.selectedRewardId,
			rewardOptionValues: this.rewardOptionValues,
			campaignId: this.campaignId,
			receivedMailDate: this.receivedMailDate,
			destType: this.destType,
			destName: this.destName,
			destZip: this.destZip,
			destAddress: this.destAddress,
			destBuilding: this.destBuilding,
			destPhone: this.destPhone,
			isTransacted: this.isTransacted,
			transactDate: this.transactDate,
			createdDate: this.createdDate,
		});
	}
}
export type RewardRequestAttr = Omit<ExcludeMethod<RewardRequest>, 'typeId'>;
