import { FormData } from 'formdata-node';
import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string()
	}),
	query: EmptyObjectStruct,
	body: s.object({
		image: s.any()
	}),
	formData: {
		image: s.any(),
	},
};

export const PostImageResponseStruct = s.string();
export type PostImageResponse = s.Infer<typeof PostImageResponseStruct>;

export type PostImageMethodParams = {
	shopId: string;
	file: File;
};

export function postImage(params: PostImageMethodParams): APIRequest {
	const { shopId, file } = params;
	const data = new FormData();
	data.append('image', file);
	return createAPIRequest(
		'post',
		uri`/api/storage/${shopId}/image`,
		{
			path: { shopId },
			body: data,
		},
		ApiParamStructs
	);
}
