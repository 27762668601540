export * from './CSVFileStruct';
export * from './CampaignStruct';
export * from './ItemGroupStruct';
export * from './MailTemplateStruct';
export * from './ReviewStruct';
export * from './RewardRequestStruct';
export * from './RewardStruct';
export * from './YReviewShopEntityStruct';
export * from './YahooMailNotCoveredStruct';
export * from './YahooOrderStruct';

