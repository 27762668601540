import { entityIdMap } from "../../const/entity-id-map";
import isUUID from "../../isUUID";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class Job extends Entity {
	static typeId = entityIdMap['cron.job'];

	/** 登録元ID */
	sourceId!: string;
	/** ジョブ名 */
	name!: string;
	/** 実行サービス名 */
	service!: string;
	/** 実行メソッド名 */
	method!: string;
	/** 実行引数 */
	args?: unknown[];
	/** 実行スケジュール文字列 */
	schedule!: string;
	/** 多重起動するかどうか */
	canMultiple?: boolean;
	/** スケジュールから遅れても起動するかどうか(trueなら実行回数を優先する) */
	canDelay?: boolean;
	/** 残り実行回数 */
	times?: number;
	/** 最終実行タイミング(条件により実行しない場合でもその時間) */
	last?: number;
	/** 最新Job終了時間(多重起動する場合は実行順に関わらず最後に終了したJobの時間) */
	lastFinish?: number;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			sourceId: { isMandatory: true, validator: isUUID },
			name: { isMandatory: true, type: 'string' },
			service: { isMandatory: true, type: 'string' },
			method: { isMandatory: true, type: 'string' },
			args: { isArray: true },
			schedule: { isMandatory: true, type: 'string' },
			canMultiple: { type: 'boolean' },
			canDelay: { type: 'boolean' },
			times: { type: 'number' },
			last: { type: 'number' },
			lastFinish: { type: 'number' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			sourceId: this.sourceId,
			name: this.name,
			service: this.service,
			method: this.method,
			args: this.args,
			schedule: this.schedule,
			canMultiple: this.canMultiple,
			canDelay: this.canDelay,
			times: this.times,
			last: this.last,
			lastFinish: this.lastFinish,
		});
	}

	includeId(val: string): boolean {
		return (this.sourceId || '').includes(val);
	}

	includeJob(val: string): boolean {
		return (this.name || '').includes(val);
	}

	includeTimes(val: string): boolean {
		return (this.times?.toString() || '').includes(val);
	}
}
export type JobAttr = Omit<ExcludeMethod<Job>, 'typeId'>;
