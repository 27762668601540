import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: s.object({
		shopId: s.string(),
		uri: s.string(),
	}),
};

export const BeginOAuthResponseStruct = s.type({
	appId: s.string(),
	state: s.string(),
	nonce: s.string(),
	codeChallenge: s.string(),
});
export type BeginOAuthResponse = s.Infer<typeof BeginOAuthResponseStruct>;

export type BeginOAuthMethodParams = s.Infer<typeof ApiParamStructs.body>;

export function beginOAuth(params: BeginOAuthMethodParams): APIRequest {
	return createAPIRequest(
		'post',
		uri`/api/yahoo/oauth`,
		{
			body: params,
		},
		ApiParamStructs,
	);
}
export default beginOAuth;
