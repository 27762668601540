import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, EventSequenceQueryStruct, RewardStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		rewardId: s.string(),
	}),
	query: EventSequenceQueryStruct,
	body: EmptyObjectStruct
};

export const GetRewardResponseStruct = s.nullable(RewardStruct);
export type GetRewardResponse = s.Infer<typeof GetRewardResponseStruct>;
export type GetRewardResponseValues = s.Infer<typeof RewardStruct>;

export type GetRewardMethodParams = {
	shopId: string;
	rewardId: string;
	seq?: string;
};

export function getReward(params: GetRewardMethodParams): APIRequest {
	const { shopId, rewardId, seq } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/reward/${rewardId}`,
		{
			path: { shopId, rewardId },
			query: { seq },
		},
		ApiParamStructs
	);
}
