import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, EventSequenceQueryStruct, RewardStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: EventSequenceQueryStruct,
	body: EmptyObjectStruct
};

export const GetRewardsResponseStruct = s.array(RewardStruct);
export type GetRewardsResponse = s.Infer<typeof GetRewardsResponseStruct>;
export type GetRewardsResponseValues = s.Infer<typeof RewardStruct>;

export type GetRewardsMethodParams = {
	shopId: string;
	seq?: string;
};

export function getRewards(params: GetRewardsMethodParams): APIRequest {
	const { shopId, seq } = params;
	return createAPIRequest(
		'get',
		uri`/api/y-review/${shopId}/reward`,
		{
			path: { shopId },
			query: { seq },
		},
		ApiParamStructs
	);
}
