import { UUID } from '../..';
import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from '../../lib';
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export interface ItemGroupRequire {
	isAll: boolean;
	sku: Set<string>;
	excludeSku: Set<string>;
	excludeRequires: ItemGroupRequire[]
}

export class ItemGroup extends Entity {
	static typeId = entityIdMap['review.itemGroup'];

	name!: string;
	isRef!: boolean;
	isAll!: boolean;
	sku!: string[];
	excludeSku!: string[];
	childGroups!: ItemGroup[];
	excludeChildGroups!: ItemGroup[];

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: true, type: 'string' },
			isRef: { isMandatory: true, type: 'boolean' },
			isAll: { isMandatory: true, type: 'boolean' },
			sku: { isMandatory: true, isArray: true, type: 'string' },
			excludeSku: { isMandatory: true, isArray: true, type: 'string' },
			childGroups: { isMandatory: true, isArray: true, model: ItemGroup },
			excludeChildGroups: { isMandatory: true, isArray: true, model: ItemGroup },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			isRef: this.isRef,
			isAll: this.isAll,
			sku: this.sku,
			excludeSku: this.excludeSku,
			childGroups: this.childGroups,
			excludeChildGroups: this.excludeChildGroups,
		});
	}

	hasDecendant(groupId?: UUID): boolean {
		if (groupId == null) {
			return false;
		} else if (this.id == groupId) {
			return true;
		} else if (this.excludeChildGroups.some(child => child.hasDecendant(groupId))) {
			return true;
		} else {
			return this.childGroups.some(child => child.hasDecendant(groupId));
		}
	}

	getDecendants(): ItemGroup[] {
		const groups = this.childGroups.flatMap(group => group.getDecendants());
		return [this, ...groups];
	}

	getRequires(): ItemGroupRequire[] {
		const requires: ItemGroupRequire[] = [
			{
				isAll: this.isAll,
				sku: new Set(this.sku),
				excludeSku: new Set(this.excludeSku),
				excludeRequires: this.excludeChildGroups.flatMap(g => g.getRequires())
			}
		];
		return requires.concat(this.childGroups.flatMap(g => g.getRequires()));
	}

	isTarget(skus: string[]): boolean {
		const hasSkus = new Set(this.sku);
		const hasExcludeSkus = new Set(this.excludeSku);
		for (const sku of skus) {
			// 対象商品に含まれるか確認
			if (
				!this.isAll &&
				!hasSkus.has(sku) &&
				this.childGroups.every(group => !group.isTarget([sku]))
			) {
				continue;
			}
			// 除外商品に含まれていないか確認
			if (hasExcludeSkus.has(sku)) continue;
			// 除外グループの判定
			if (this.excludeChildGroups.some(group => group.isTarget([sku]))) continue;
			return true;
		}
		return false;
	}
}
export type ItemGroupAttr = ExcludeMethod<ItemGroup>;
