import * as s from 'superstruct';

export const OrderItemStruct = s.type({
	sku: s.string(),
	quantity: s.number(),
	rewardTargetId: s.string()
});

const OrdererStruct = s.type({
	ordererName: s.string(),
});

export const YahooOrderStruct = s.type({
	orderItems: s.array(OrderItemStruct),
	orderer: OrdererStruct,
	number: s.string(),
	orderDate: s.string(),
	shippingDate: s.optional(s.number()),
	followMailDate: s.optional(s.number()),
	hasSentFollowMail: s.boolean(),
	canSendingMail: s.boolean(),
	isMember: s.boolean(),
});