import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CampaignStruct, CommandResultStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
		campaignId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: CampaignStruct
};

export const PutCampaignResponseStruct = CommandResultStruct;
export type PutCampaignResponse = s.Infer<typeof PutCampaignResponseStruct>;

export type PutCampaignMethodParams = {
	shopId: string;
	campaignId: string;
	campaign: s.Infer<typeof CampaignStruct>;
};

export function putCampaign(params: PutCampaignMethodParams): APIRequest {
	const { shopId, campaignId, campaign } = params;
	return createAPIRequest(
		'put',
		uri`/api/y-review/${shopId}/campaign/${campaignId}`,
		{
			path: { shopId, campaignId },
			body: campaign
		},
		ApiParamStructs
	);
}
