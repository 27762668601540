import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, ReviewStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.array(ReviewStruct)
};

export const PostReviewsResponseStruct = s.object({
	newReviews: s.number(),
	targets: s.number(),
});
export type PostReviewsResponse = s.Infer<typeof PostReviewsResponseStruct>;

export type PostReviewsMethodParams = {
	shopId: string;
	reviews: s.Infer<typeof ApiParamStructs.body>;
};

export function postReviews(params: PostReviewsMethodParams): APIRequest {
	const { shopId, reviews } = params;
	return createAPIRequest(
		'post',
		uri`/api/y-review/${shopId}/review`,
		{
			path: { shopId },
			body: reviews
		},
		ApiParamStructs
	);
}
