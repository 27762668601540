import { Article } from './Article';
import { Term } from './Term';

export * from './Article';
export * from './Term';

export const helpConstructors = {
	Article,
	Term,
};
