import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct, MailTemplateStruct } from '../structs';
import { uri } from '../uri';
import createParser from '../createParser';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		templateId: s.string()
	}),
	query: EmptyObjectStruct,
	body: s.partial(MailTemplateStruct)
};

export const PutTemplateResponseStruct = CommandResultStruct;
export type PutTemplateResponse = s.Infer<typeof PutTemplateResponseStruct>;
export const parsePutTemplate = createParser(ApiParamStructs.body);

export type PutTemplateMethodParams = {
	shopId: string;
	templateId: string;
	template: Partial<s.Infer<typeof MailTemplateStruct>>;
};

export function putTemplate(params: PutTemplateMethodParams): APIRequest {
	const { shopId, templateId, template } = params;
	return createAPIRequest(
		'put',
		uri`/api/y-review/${shopId}/template/${templateId}`,
		{
			path: { shopId, templateId },
			body: template
		},
		ApiParamStructs
	);
}
