import { entityIdMap } from "../../const/entity-id-map";
import isUUID from "../../isUUID";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class OrderItem extends Entity {
	static typeId = entityIdMap['review.orderItem'];

	sku!: string;
	rac!: number;
	quantity!: number;
	itemName?: string;
	itemNumber?: string;
	rewardTargetId?: string;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			sku: { isMandatory: true, type: 'string' },
			rac: { isMandatory: true, type: 'number' },
			quantity: { isMandatory: true, type: 'number' },
			itemName: { type: 'string' },
			itemNumber: { type: 'string' },
			rewardTargetId: { validator: isUUID },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			sku: this.sku,
			rac: this.rac,
			quantity: this.quantity,
			itemName: this.itemName,
			itemNumber: this.itemNumber,
			rewardTargetId: this.rewardTargetId,
		});
	}
}
export type OrderItemAttr = ExcludeMethod<OrderItem>;
