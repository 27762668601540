import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import createParser from '../createParser';
import { EmptyObjectStruct, MailShopEntityStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.omit(MailShopEntityStruct, ['id'])
};

export const PutShopResponseStruct = s.boolean();
export type PutShopResponse = s.Infer<typeof PutShopResponseStruct>;
export const parsePutShop = createParser(ApiParamStructs.body);

export type PutShopMethodParams = {
	shopId: string;
	shop: s.Infer<typeof ApiParamStructs.body>;
};

export function putShop(params: PutShopMethodParams): APIRequest {
	const { shopId, shop } = params;
	return createAPIRequest(
		'put',
		uri`/api/mail/${shopId}/shop`,
		{
			path: { shopId },
			body: shop
		},
		ApiParamStructs
	);
}
