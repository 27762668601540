import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ShopStatus, shopStatusValidator, UUID } from "../..";
import { entityIdMap } from "../../const/entity-id-map";
import isUUID from "../../isUUID";
import { ExcludeMethod } from "../../lib";
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";
dayjs.extend(isSameOrAfter);

export class ShopStatusHistory extends Entity {
	static typeId = entityIdMap['review.shop_status_history'];

	shopId!: UUID;
	date!: number;
	status!: ShopStatus;
	plan?: string;

	constructor(obj: unknown) {
		
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			shopId: { isMandatory: true, validator: isUUID },
			date: { isMandatory: true, type: 'number' },
			status: { isMandatory: true, type: 'string', validator: shopStatusValidator },
			plan: { isMandatory: false, type: 'string' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			shopId: this.shopId,
			date: this.date,
			status: this.status,
			plan: this.plan,
		});
	}

}
export type ShopStatusHistoryAttr = Omit<ExcludeMethod<ShopStatusHistory>, 'typeId'>;