/*
```
WITH RECURSIVE "tree" AS (
	SELECT "attributes"->>'name' AS "path", "id"
		FROM "capability"
		WHERE "id" NOT IN (SELECT "child_id" FROM "capability_group")
	UNION
	SELECT concat("tree"."path", '/', "capability"."attributes"->>'name'), "capability"."id"
		FROM "tree"
			INNER JOIN "capability_group" ON "tree"."id" = "capability_group"."parent_id"
			INNER JOIN "capability" ON "capability_group"."child_id" = "capability"."id"
)
SELECT "path", "id",
		concat(
			CASE
				WHEN row_number() OVER (PARTITION BY "id" ORDER BY "path") <= 1 THEN E'\t'
				ELSE E'//\t'
			END,
			regexp_replace("path", '.*?/', E'\t', 'g'), ': ''', "id", ''','
		)
	FROM "tree"
	ORDER BY "path"
```
*/

export const capabilityIdMap = {
	admin_login: '1a084771-41e9-4f0c-b4c5-459a21907a02',
		api_user_login: '242962f3-b4b3-4b2c-961f-c1cc75d09c4b',
		review_login: 'a0984c20-837e-4244-af29-8122cef809ab',
			api_notice_getNotices: '6636a532-8826-4948-a545-de3d319066b9',
			api_rakuten_getShops: '06275b1b-bd37-41d2-8ead-780887542c15',
			api_help_getArticles: '1965008b-e894-4b58-a8e4-f7cf96bb3b2d',
			api_help_getArticle: '089ab412-6f9c-498d-8cc1-d3da4c0dd63b',
//			api_user_login: '242962f3-b4b3-4b2c-961f-c1cc75d09c4b',
		yReview_login: 'd3d48f28-3406-4182-b45a-3e50b404a90f',
//			api_notice_getNotices: '6636a532-8826-4948-a545-de3d319066b9',
			api_notice_yReview_getContent: '56c439e8-0606-4edc-b1ac-043f1502c94c',
//			api_user_login: '242962f3-b4b3-4b2c-961f-c1cc75d09c4b',
			api_yahoo_getShops: 'b2a44192-d25f-4b78-a070-c5f147c1ea92',
	master_read: '7c56771f-6f5e-400c-810d-432ffcc4e5a2',
		api_cron_getJobs: '4988e219-786e-4509-954b-86d6b601fa77',
		api_cron_getStatus: 'cb35482b-0d5e-4162-87b0-1048822d61df',
		api_help_getStatus: 'be92b6cb-08b5-4e0d-9320-8b4e714e8a34',
		api_help_getTerm: '15a0aa7e-56db-4496-bc07-8931c22a6967',
		api_help_getTerms: 'b0925d2e-d100-41b6-bfa9-309c981e9b5a',
		api_mail_getStatus: '51a55168-fe39-4749-ad31-00fa406da372',
		api_notice_getContent: '088d12d7-24ed-4cf2-888b-e985236d31d4',
		api_notice_getContents: '399933ca-8bff-44ab-af99-a62e0d97d50d',
		api_notice_getStatus: 'c1af5325-691d-44c1-a62d-8a4245b5d483',
//		api_rakuten_getShops: '06275b1b-bd37-41d2-8ead-780887542c15',
		api_rakuten_getStatus: '15cbdd79-e2be-4245-aaf5-d76e9e76623f',
		api_review_getShopEntities: 'cf0c222b-8a33-45d5-a73e-c4199ab8c47f',
		api_review_getShopEntityIds: '1762acdd-b1a4-432b-b1ae-b34ccc2bf192',
		api_review_getShopEntitiesByIds: '4be328b2-4e70-4c84-b3ac-a8fa94a85c91',
		api_review_getShopStatusHistoriesById: 'e1f11f49-d9af-47f9-81e8-47e1fbea26dd',
		api_review_getShopStatusHistoriesByIds: '4002fd88-9939-48b0-b5c6-5af2a735878f',
		api_review_getStatus: '6fdb9d1d-3165-4fd7-bec8-c90ca74662d6',
		api_review_postCharge: '63aa445c-a373-44c8-a2e7-016596819f1b',
		api_trade_deletePayment: '2a606c2c-50bb-4c83-9a80-589f4d527a7e',
		api_trade_getClient: '99b9cc5b-fc03-456a-820d-5c769b6bcfd4',
		api_trade_getClients: '0bce66b5-8355-4dae-bfce-f45fbfe961d1',
		api_trade_getPayments: '2b739afb-00e5-4200-bf59-ec0bc3c819c6',
		api_trade_getStatus: '90b05804-872b-4f48-a9c8-9ab2750874f8',
		api_trade_putPayment: '6a95be1d-1204-488f-a97b-7fac6fb5f84d',
		api_trade_putPaymentClaim: 'b1aab548-caed-44df-bf5f-23b68677ff7d',
		api_user_getStatus: '8fbb4dd1-c342-4e41-8466-695f34fe162d',
//		api_yahoo_getShops: 'b2a44192-d25f-4b78-a070-c5f147c1ea92',
		api_yahoo_getStatus: '67b54786-e82d-4112-9cc0-eb826037db53',
		api_yReview_getStatus: 'eef12f2d-1c4e-44d4-8403-b5bcf797c10c',
		review_master_read: '1d41db5a-a663-4cfc-ad3f-4e2266ac3628',
			review_labor_read: 'eba4dbab-edbb-46da-881a-7cff61ed9a42',
				review_reviewCheckTool_read: '7c3f1e8e-acaa-41c9-9ff5-be19d2b40f4b',
				review_reviewWaiting_read: '7214d912-dffa-421f-b315-1b45b7b02c38',
					api_review_getCampaignOrderNum: 'a1bc4fb4-82df-4532-afb0-2011f25602a4',
					api_review_getOrders: '109a617d-ba79-4f16-8e20-18c648b36801',
					api_review_getReviews: 'b0e6f7ad-d9fa-4691-88c2-d60c3d51f91d',
					api_review_getTarget: '1e7f55f2-6943-4be9-a0c5-809f24c05487',
					api_review_getTargets: 'be733509-2d2a-418b-addc-3c598240dc26',
					api_review_getTargetSummary: '9857b54a-5e99-48ac-9ccd-e9f884fc9fe8',
					api_review_getTargetTopSummary: 'bf2515b7-3a9a-49b0-9632-560f1836337c',
				review_rewardApplying_read: 'a136e413-c10a-4a7f-a1cd-ea8299354e7a',
					api_review_getRequest: '95242ede-1db2-449f-b851-203f0ba5f8be',
					api_review_getRequestDetail: '39c06722-7522-4c43-a17d-de064e12f923',
					api_review_getRequests: '03a8ade4-a587-4c33-aca4-fd1622582271',
			review_management_read: '7d8a5552-a734-4eeb-9145-0146bf18d7b6',
				review_campaignRegister_read: 'f9e7fbe0-fca1-467a-9d58-f8fb0e96030f',
					api_review_getCampaign: '2b339011-c268-4808-820c-cb1c44bcbbc0',
					api_review_getCampaigns: 'ecabfc6b-c768-4703-b288-dc039e3d2256',
					api_review_getCampaignsByShopReward: 'd75e5030-4c87-4263-930d-9e40e4a780cc',
				review_itemGroup_read: '172729d7-338f-4464-92e8-7925f6f13d71',
					api_review_getGroup: '8839f2ef-7563-4557-bc95-08cb8bb5a915',
					api_review_getGroups: '7aa2399d-40f0-493f-bd74-c4fe5f0c54f5',
				review_mailExclude_read: '19c82eca-bf0e-4204-9901-b06c285054a4',
				review_mailSetting_read: 'e2edf560-4874-4460-bcbd-4b363403aa3e',
					api_review_getNotCovered: '26a2d5a3-648c-4588-97c4-409103b43754',
					api_review_getNotCovereds: 'fd001f3c-c296-4937-a406-0db814618977',
					api_review_getShopEntity: '2008b6ec-34b6-4bbd-98df-5c5e1b44250e',
					api_user_getReviewMail: 'b02e1c7b-628f-40d1-b30d-32e897de0f94',
				review_mailTemplate_read: '1ba1050b-6cfe-4f1e-867d-a9430c727cb6',
					api_review_getTemplate: 'b0e7e365-de5f-4918-97d8-5a50eaf271b4',
					api_review_getTemplates: '46e08ad9-6f16-4ca1-972a-ab5b9d242eee',
					api_review_getTemplatesFilter: 'e612955c-150d-49a3-9073-bc065997fdde',
				review_rewardRegister_read: 'd1772242-f0df-4e59-8620-0e3d5a59919a',
					api_review_getReward: '455b2c87-5c90-4b37-a8ef-24f4736ab043',
					api_review_getRewards: 'e61137c1-b9fa-486c-8ca9-c051d1c6ac14',
			review_system_read: 'e247593c-49f8-4863-8991-4d64c7d3c321',
				review_shopSetting_read: '732470e4-66c3-4882-8ab5-87b3a6d18e9a',
					api_notice_getShop: '7c904b64-d209-4f58-b2dd-0199d6880a90',
					api_rakuten_getCheckLogin: '3fc8cfad-3fa3-4d09-8a46-226c1ff63016',
					api_rakuten_getShop: 'ff6f3f8c-f6ad-44be-9a7d-d53e0e318042',
				review_userSetting_read: 'b295e9cc-1de5-48b8-9363-d6de82427565',
					api_user_getEntityUser: '41d441f0-f6ff-4e4b-86ca-a2fd7f39126e',
					api_user_getEntityUsers: '64c2226b-287f-4fec-8df4-6f1fba4cd547',
	master_write: '411e32c6-a4b2-4ebe-87e3-0ec29b829701',
		api_help_deleteArticle: 'f79af04c-d307-4547-a4ab-a269cb238d0d',
		api_help_deleteTerm: 'bd1b6d85-42a4-454d-8578-d6f8ae38cb9b',
		api_help_postArticle: '00164294-5871-457f-907e-ee67f1458a1e',
		api_help_postTerm: '5a3567c7-226d-42ea-9113-2220d8418883',
		api_help_putArticle: '808c1ad7-9be5-41df-a3e9-6c684025941b',
		api_help_putTerm: '95c46555-696a-497a-824f-8ba19914821a',
		api_notice_deleteContent: '1786ef97-2ea4-4d29-ba3a-99da7e122414',
		api_notice_postContent: '0360dd1d-f673-41b2-b1f3-9aa795f705fa',
		api_notice_putContent: '67d83081-942f-48ac-b157-5e96e68d9be6',
		api_rakuten_postShop: '0eb7e311-c946-43c6-83c6-11cc760d5ba1',
		api_review_postShopStatusHisory: '14b3481e-150d-44c7-a213-48021f0094b7',
		api_review_putShopEntityPlan: 'c0b6051d-920c-48a0-b195-1229e90cd404',
		api_review_putShopEntityStatus: '6de5035f-9416-40a4-b7a8-417fc997cf18',
		api_trade_postClient: '044fb67c-af75-4f98-b67c-4a90bfdc8942',
		api_trade_putClient: '8805b4ad-c682-47bf-b4ee-f52bb85cb485',
		api_yahoo_postShop: 'b7fbf3d7-153f-41b7-85b4-1c2844f4714d',
		review_master_write: '54962359-f8d1-4554-a13a-0fdb013abc12',
			review_labor_write: 'cc3c5e6d-d12a-4b49-83c4-d6147431a2d2',
				review_reviewWaiting_write: 'c82a1097-f332-4d7e-b2ce-67e244345c9b',
					api_review_postReviews: '0eb70bc1-bfdf-4519-938b-0f309afbfa59',
					api_review_putOrderCanSendingMail: '88137751-4c0d-42f0-9a42-ae817371747f',
				review_rewardApplying_write: 'c1de9565-3f53-4339-95af-72e877f69045',
					api_review_putRequestTransact: '3b080652-ace6-457f-948a-a39506026259',
			review_management_write: 'cab10248-39bd-4830-bc46-0065ccb1c8b1',
				api_user_sendReviewMail: 'fd55f391-9ea6-4b00-b038-c8770ea38752',
				review_campaignRegister_write: 'ee854c08-816e-4e4b-8442-32c312cca013',
					api_review_deleteCampaign: 'a229b7e3-285b-485f-a6ca-589a22e814f6',
					api_review_postCampaign: '30b34354-4d7b-4fc6-80a2-7826bc49f3b2',
					api_review_putCampaign: 'c79532e3-d0dc-4754-9c75-30c21cf0804d',
					api_review_putCampaignPriority: 'f8e08e41-dc7a-4f9c-8009-567344a76cef',
				review_itemGroup_write: '2e80cb57-294d-462f-bc3c-6ea41b5abe4e',
					api_rakuten_getItems: 'c6be01b0-9d0f-4a0e-bb21-b1d056ef63ee',
					api_review_deleteGroup: '4dbd9cd6-f7e1-4bb2-8c79-aa209ef30483',
					api_review_postGroup: '697b9686-008e-42a0-a8c2-629b5ba046d8',
					api_review_putGroup: 'e69e2953-92ef-40f4-985d-7f585ec9ccf6',
				review_mailExclude_write: '30057bb2-3d48-4f0b-b192-bd333db08873',
				review_mailSetting_write: '8240151e-4860-4f6c-b6e3-16222bf0c947',
					api_review_deleteNotCovered: '6e2ce5da-1812-4c6c-83e0-4d09df187dec',
					api_review_postNotCovered: '4f059e8c-6007-4476-95aa-c9ca5421b34f',
					api_review_putNotCovered: '4feb7af1-7bb0-47ff-b924-8acb7ead42e1',
					api_review_putNotCoveredEnable: 'f16de7e0-8dac-4f95-b85d-8f33365a16a8',
					api_review_putShopEntity: 'e8fa7ed8-6859-4d73-b14d-5ea2766ed740',
				review_mailTemplate_write: 'dae2c965-d54a-40aa-9cb8-ef7d7e3eb97d',
					api_review_deleteTemplate: 'e5d6bc70-07de-40c5-8ed9-56bb9a7d3bcd',
					api_review_postTemplate: '54156b6f-82dc-4ef6-b5a4-678773a1721c',
					api_review_putTemplate: 'e26a3a15-4dac-4f67-8775-e392f516eff1',
					api_review_putTemplateFavorite: 'db170606-6dc2-4db3-ade9-522016bd0e5e',
				review_rewardRegister_write: '4b5b903b-97b8-4aa3-a680-380c4cafff59',
					api_rakuten_postCouponValidate: '6503db29-2ccb-4737-9306-cc8f7f08c20f',
					api_review_deleteReward: 'ad8b29bf-f7ea-484e-b8c4-1452b7ff1939',
					api_review_getCoupon: '961e125d-2b42-4636-ae7a-f02ce61a42de',
					api_review_postReward: '059d764c-8dd3-4fb5-92b3-f2c22c1a0f03',
					api_review_postRewardTemplateImage: '6a7f8113-05dd-4be3-b1fc-b56d7fc0f0c9',
					api_review_putReward: 'dd3618e7-701f-47a4-959c-ac0ab0a071ce',
					api_storage_postImage: '5c61ad00-b20a-4065-b808-66084e3d39b0',
			review_system_write: '2defa639-79ca-4f1c-bc38-da96b4ec0328',
				review_shopSetting_write: 'b7b7bc48-23e3-48a0-8915-f8e50a48e596',
					api_notice_putShop: '773b1ef3-7bc3-41e7-b47b-d8407e75d549',
					api_rakuten_getLicenseExpiryDate: '74901c5c-d4bb-479e-aecd-3d116d2c719b',
					api_rakuten_putShop: 'e8b59fa4-5cca-42ad-bb25-b0137a95ba4f',
					api_review_postMailRakuten: '4178366f-c763-444f-bb47-bbdea2403156',
				review_userSetting_write: '4f0a7277-e5b6-40b5-8777-8952ae57a43a',
					api_user_deleteEntityUser: '8b125b31-840b-42f2-bdeb-ffca6247f71c',
					api_user_postEntityUser: '6e3bd2b2-5999-4647-9ae2-f6675f95f1d9',
					api_user_putEntityUserCapability: '526ca35c-0ab4-4295-875d-d8eee1b962b4',
		yReview_master: '3845f706-e61b-4fa8-ac47-2963a9b57768',
			yReview_labor_write: 'fd0b06e0-4160-4021-9c6e-76926053ba79',
				api_yahoo_searchItems: '83781371-7823-41b6-82cc-199870a8a1c5',
				api_yReview_postReviews: '68830595-d199-4f3d-a427-0135661956b1',
				api_yReview_putOrderCanSendingMail: 'f8faae34-ff63-47e1-8398-f1516d31d3a5',
				api_yReview_putRewardRequestTransact: '7714162b-3ed2-4176-8685-19824dd43ed2',
				yReview_labor_read: '788a11d0-b74d-483a-8f09-79a4c396b45f',
					api_yReview_getCampaignReviewNum: '4d2cef2a-a4da-4c36-b212-641189c28926',
					api_yReview_getRewardRequestDetail: 'ec767195-e6d9-44f6-80a0-f3fe69506e28',
					api_yReview_getRewardRequests: '676683cb-bceb-4606-91f4-d7207d800429',
					api_yReview_getTargets: 'e375124a-292d-42ae-9c5b-b1f4dc67a1b6',
					api_yReview_getTargetSummary: '240c5288-3f1e-444a-8fe3-6be505f0c434',
					api_yReview_getTopReviewSummary: '1c597718-e33e-4941-bc87-59b57fe82516',
			yReview_management_write: 'de70f276-64f0-4585-89fc-ac3f2eb10b86',
				api_mail_putShop: '35bec2ae-2ba8-478e-90b4-88db40a80c56',
				api_mail_sendTestMail: '56848605-380d-4c58-a82a-02b9381afb6d',
//				api_storage_postImage: '5c61ad00-b20a-4065-b808-66084e3d39b0',
//				api_user_sendReviewMail: 'fd55f391-9ea6-4b00-b038-c8770ea38752',
				api_yahoo_beginOAuth: 'c5e56f39-eec8-4455-be45-9b356ea3aa1a',
				api_yahoo_finishOAuth: 'f868ec52-cd03-42c5-8fff-8a99a6977c75',
				api_yahoo_validateCoupon: 'a8807c37-1384-4ba7-9b54-ea690b8eaf89',
				api_yReview_deleteCampaign: '19762c36-1ef5-4e61-9219-a41573336864',
				api_yReview_deleteGroup: 'df9ecf6b-3133-4c50-9314-2adb7db7fbb8',
				api_yReview_deleteNotCovered: '0f70e6ae-4ad0-48c7-b7f3-6d13b5a58a4b',
				api_yReview_deleteReward: '9b587faa-494b-4dd4-91e7-641939c436dd',
				api_yReview_deleteTemplate: 'fcbd4c98-00de-4a49-941d-1747883318e3',
				api_yReview_postCampaign: '8ce38029-39e1-4d8d-93f4-c4d5131a4ea2',
				api_yReview_postGroup: 'd15962c1-bab8-4c24-b17f-ae5daf1e6b97',
				api_yReview_postNotCovered: 'ff33914e-1503-4e80-9de7-3eb4390515bf',
				api_yReview_postReward: '1ef9fe41-74cd-474c-8323-62b115f8f1ed',
				api_yReview_postTemplate: 'e2a4076f-410d-4f98-a2c0-22a03ddfed26',
				api_yReview_putCampaign: 'a2430abe-f1cb-46ad-8152-89038562a7f2',
				api_yReview_putGroup: '95a0aba2-a9f5-46c2-aff6-d30d11d9fbae',
				api_yReview_putNotCovered: '4c2553e0-8953-4a79-9d97-9cf7cd9345a0',
				api_yReview_putReward: '45558ebc-e157-4d3a-add6-ff6b13c8e507',
				api_yReview_putShop: 'aafcb58e-8793-4edb-a661-10b340ebd05e',
				api_yReview_putTemplate: 'fa697fc9-941d-4dc2-b120-96c8a70802ec',
				api_yReview_setCampaignPriority: 'ee2345a1-cb0c-42bf-9877-e841c8930e30',
				yReview_management_read: '12af81b0-4434-4624-980f-ccebd7a8ea24',
					api_mail_getShop: 'ebc61dbd-99fd-45e5-80a9-165f286b53da',
//					api_user_getReviewMail: 'b02e1c7b-628f-40d1-b30d-32e897de0f94',
					api_yReview_getCampaign: '20f01d70-0306-414d-94ff-a1a90af4e2ef',
					api_yReview_getCampaignList: '71395580-a1e5-472b-91ab-9a0a88825c6e',
					api_yReview_getGroups: '6cfced94-a6fc-4cce-a984-3235b0d1ca67',
					api_yReview_getNotCovered: '08335679-dc70-4eb2-8176-4015cfd9d29d',
					api_yReview_getNotCovereds: '8cd08850-e831-4b58-bd07-dc744920391a',
					api_yReview_getReward: '639d278a-7252-4a29-893e-b1608b0ac728',
					api_yReview_getRewards: '5681c2ca-41b3-424d-a72e-0a45110e4036',
					api_yReview_getRewardTemplateImage: '8ad4979e-60f0-4107-b0ed-aa14683cecaf',
					api_yReview_getShop: '9062632f-9475-483e-9ab1-b20cf8a66dff',
					api_yReview_getShopClientToIds: 'f4b2d6c5-b825-42bf-96b8-3d096324ea1b',
					api_yReview_getShopByIds: 'bd14c93e-c0c9-4d92-8e34-f745715d7a19',
					api_yReview_getTemplate: 'acabfb09-0c12-4b23-b1cb-dfdd878ac9b7',
					api_yReview_getTemplates: 'd783332f-17b8-4f6a-b5a1-a8a66e2def9f',
			yReview_reviewCheckTool_read: '9345fe05-1edb-4a9a-9e78-1f5c46be74ca',
			yReview_system_write: '9779fa1d-2460-4d6f-86d5-edae725dc327',
//				api_notice_putShop: '773b1ef3-7bc3-41e7-b47b-d8407e75d549',
//				api_user_getEntityUser: '41d441f0-f6ff-4e4b-86ca-a2fd7f39126e',
//				api_user_getEntityUsers: '64c2226b-287f-4fec-8df4-6f1fba4cd547',
//				api_user_deleteEntityUser: '8b125b31-840b-42f2-bdeb-ffca6247f71c',
//				api_user_postEntityUser: '6e3bd2b2-5999-4647-9ae2-f6675f95f1d9',
//				api_user_putEntityUserCapability: '526ca35c-0ab4-4295-875d-d8eee1b962b4',
				api_yahoo_putShop: '78105f35-4abf-421d-aa39-be73ddf07e58',
				yReview_system_read: '79363738-5a27-45f2-8327-0b5c21933557',
//					api_notice_getShop: '7c904b64-d209-4f58-b2dd-0199d6880a90',
					api_yahoo_checkLogin: 'ef61c937-486e-410b-9ecb-9ffd27d0c73e',
					api_yahoo_getLicenseExpiryDate: '1dd94f70-3ee3-412b-92fd-7a89aa5994d1',
					api_yahoo_getShop: '1bc4850b-9df3-47cd-b318-b788ae7688aa',
					api_yahoo_getShopTokenExpiration: '86b8c6f9-fdc4-4e37-8427-7da0ace60b7b',
	pending: 'b9862004-4407-437f-af77-615a570d6871',
	review_analysis_read: '9dcc5b33-0840-4a93-be96-2269469628d0',
	yReview_analysis_read: '1dd84f51-77da-46ad-ad60-855281b2a834',
} as const;
export default capabilityIdMap;

export const MASTER_ENTITY_ID = '3ef26543-4bb6-493a-9faf-f33a99e8d0b6';

export type CapabilityType = keyof typeof capabilityIdMap;
