import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: s.object({
		user: s.object({
			name: s.string(),
			mail: s.string()
		})
	})
};

export const ChangeUserResponseStruct = s.boolean();
export type ChangeUserResponse = s.Infer<typeof ChangeUserResponseStruct>;

export type ChangeUserMethodParams = s.Infer<typeof ApiParamStructs.body>;

export function changeUser(params: ChangeUserMethodParams): APIRequest {
	return createAPIRequest(
		'put',
		uri`/api/user/user`,
		{
			body: params,
		},
		ApiParamStructs
	);
}
