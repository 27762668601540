import * as s from 'superstruct';

const bodyElementType = [
	'header',
	'free_text',
	'campaign_info',
	'review_period',
	'campaign_url',
	'how_to_write_review_url',
	'apply_form_url',
	'apply_deadline_date',
	'coupon_url',
] as const;
const mailCategory = ['review_request','review_completed','application_completed'] as const;

export const bodyElementStruct = s.object({
	type: s.enums(bodyElementType),
	content: s.string(),
});

export const MailTemplateStruct = s.type({
	id: s.string(),
	name: s.string(),
	category: s.enums(mailCategory),
	body: s.array(bodyElementStruct),
	signature: s.string(),
	isFavorite: s.boolean(),
});