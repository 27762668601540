import { Campaign } from './Campaign';
import { ItemGroup } from './ItemGroup';
import { MailNotCovered } from './MailNotCovered';
import { MailTemplate } from './MailTemplate';
import { Orderer } from './Orderer';
import { OrderItem } from './OrderItem';
import { RakutenOrder } from './RakutenOrder';
import { Review } from './Review';
import { ReviewMail } from './ReviewMail';
import { CouponReward, ItemReward } from './Reward';
import { RewardRequest } from './RewardRequest';
import { RewardRequestDetail } from './RewardRequestDetail';
import { RewardTarget } from './RewardTarget';
import { ShopEntity } from './ShopEntity';
import { ShopStatusHistory } from './ShopStatusHistory';

export * from './BodyElement';
export * from './Campaign';
export * from './ItemGroup';
export * from './MailNotCovered';
export * from './MailTemplate';
export * from './Orderer';
export * from './OrderItem';
export * from './RakutenOrder';
export * from './Review';
export * from './ReviewMail';
export * from './Reward';
export * from './RewardRequest';
export * from './RewardRequestDetail';
export * from './RewardTarget';
export * from './ShopEntity';
export * from './ShopStatusHistory';

export const reviewConstructors = {
	Campaign,
	ItemGroup,
	MailNotCovered,
	MailTemplate,
	Orderer,
	OrderItem,
	RakutenOrder,
	Review,
	ReviewMail,
	ItemReward,
	CouponReward,
	RewardRequest,
	RewardRequestDetail,
	RewardTarget,
	ShopEntity,
	ShopStatusHistory,
};
