export const entityIdMap = {
	'cron.execution': '4e850106-228c-416d-8a95-87f44456622d',
	'cron.job': 'dfdce5c5-417e-4769-87df-63ec03227a1d',
	'help.article': 'c3b17bce-ae35-403f-8713-22142d4534ea',
	'help.term': '6e059a30-821b-4696-8c44-15dcaad8ae6e',
	'mail.mail': '819b9223-08dd-46f9-ae18-1c225aa6dfd6',
	'mail.shop_entity': 'f6c596eb-77d4-4117-9746-8df1262759c3',
	'notice.content': 'f5e14f48-bbd0-497d-b79d-79946ec47a9d',
	'notice.shop_entity': '2f844469-ace0-451e-9292-0115b14ead69',
	'rakuten.shop': '5c5ae466-7b9c-43c7-8452-51db8764ae18',
	'rakuten.rakutenMail': 'a7d7ef72-dff5-4eb5-9483-26762d4cfd2f',
	'review.campaign': '31664e27-12d0-4473-b413-1d37650d3363',
	'review.itemGroup': 'f975240c-6fb7-4390-a304-290c700da07a',
	'review.not_covered': '5f56fae6-7a5b-4602-b574-b6cea8da911c',
	'review.template': 'db07bad5-bf08-415d-878c-9c74e60e8d79',
	'review.order': 'b7fbc418-1dc6-485e-be5e-a98ec87955cd',
	'review.orderer': 'fff676d4-4bba-4649-9cd9-cf2f4bcce96e',
	'review.orderItem': '83e176ce-bf5c-40e2-b981-2df8627a9d44',
	'review.review': '50cf9b10-307d-478c-9290-8be8d14e41fb',
	'review.reviewMail': '0dc34823-df45-4233-9d25-addaf0ddf5da',
	'review.reward': 'c14b0574-f826-4dd6-948e-3123deab1c7e',
	'review.rewardRequest': '0e60d966-ab21-4d6d-ab96-a2afaac220e9',
	'review.rewardRequestDetail': '69acc587-4f20-4ec8-879b-9883ebe8f8c3',
	'review.rewardTarget': '3259f388-740d-4939-bc51-42b6c87d05de',
	'review.shop_entity': '2681f4e1-1bd8-42fa-9e2a-d38cf5c61e1d',
	'review.shop_status_history': 'b3648828-61af-44d1-bf8f-fec776566c1b',
	'sasagase.purchaseOrder': 'f823ceef-8397-453b-952a-080ed5dff84d',
	'sasagase.purchaseOrderItem': 'd159bf9c-709d-4cd0-ad7d-60aa14ed2744',
	'trade.client': '0407df67-da12-47fc-823e-eb648803bc0d',
	'trade.payment': 'c425d538-3bbb-4b2b-9f5c-ed5cd524376f',
	'user.capability': '48981fa4-b2b9-4cde-9c72-626db2465408',
	'user.session': 'f6a5d00b-1193-445f-9d43-b09a4f606d68',
	'user.entityType': 'fb0385a3-da08-4a17-aaf1-9c0d956b37d6',
	'user.token': '32665cee-17a2-4dbf-89aa-cdd1ad8e0cca',
	'user.user': '2bed4231-5ce7-430a-bdff-cfc9df114420',
	'user.template': '42fea992-1844-41e8-a3c8-95f8949a2051',
	'yReview.shop': '8b0b6e9d-8006-4208-97d7-e3de8342d367',
	'yReview.campaign': '9eb5eb14-dae4-4ced-9f19-31fddf9533b3',
	'yReview.reward': '47dd1f8d-6466-4152-ac16-42b68e575c77',
	'yReview.shop_entity': '5f60954e-6605-42d1-b1cb-d6253810b141',
	'yahoo.shop': '641c2509-ae1e-49cb-adb4-d9e6511682c5',
} as const;
export default entityIdMap;
export type EntityKind = keyof typeof entityIdMap;

export function isEntityKind(type: unknown): type is EntityKind {
	if (typeof type != 'string') {
		return false;
	}
	return type in entityIdMap;
}
