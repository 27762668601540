import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
		rewardId: s.string(),
	}),
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const DeleteRewardResponseStruct = CommandResultStruct;
export type DeleteRewardResponse = s.Infer<typeof DeleteRewardResponseStruct>;

export type DeleteRewardMethodParams = {
	shopId: string;
	rewardId: string;
};

export function deleteReward(params: DeleteRewardMethodParams): APIRequest {
	const { shopId, rewardId } = params;
	return createAPIRequest(
		'delete',
		uri`/api/y-review/${shopId}/reward/${rewardId}`,
		{
			path: { shopId, rewardId },
		},
		ApiParamStructs
	);
}
