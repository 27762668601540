import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { CommandResultStruct, EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		shopId: s.string(),
		orderNumber: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.object({
		canSendingMail: s.boolean()
	})
};

export const PutOrderCanSendingMailResponseStruct = CommandResultStruct;
export type PutOrderCanSendingMailResponse = s.Infer<typeof PutOrderCanSendingMailResponseStruct>;

export type PutOrderCanSendingMailMethodParams = {
	shopId: string;
	orderNumber: string;
	canSendingMail: boolean;
};

export function putOrderCanSendingMail(params: PutOrderCanSendingMailMethodParams): APIRequest {
	const { shopId, orderNumber, canSendingMail } = params;
	return createAPIRequest(
		'put',
		uri`/api/y-review/${shopId}/order/${orderNumber}/canSendingMail`,
		{
			path: { shopId, orderNumber },
			body: { canSendingMail }
		},
		ApiParamStructs
	);
}
