export const bodyElementType = [
	'header',
	'free_text',
	'campaign_info',
	'review_period',
	'campaign_url',
	'how_to_write_review_url',
	'apply_form_url',
	'apply_deadline_date',
	'coupon_url',
] as const;
export type BodyElementType = typeof bodyElementType[number];
export type BodyElementTypeNames = Record<BodyElementType, string>;
export const bodyElementTypeNames: BodyElementTypeNames = {
	header: '',
	free_text: 'フリーテキスト',
	campaign_info: 'キャンペーン情報',	// キャンペーン名,キャンペーン期間,レビュー受付期間,対象条件など
	review_period: 'レビュー受付期間',
	campaign_url: 'キャンペーンページ URL',
	how_to_write_review_url: 'レビューの書き方 URL',
	apply_form_url: '申込みフォーム URL',
	apply_deadline_date: '申込受付期間',
	coupon_url: 'クーポン URL',
};
export type BodyElement = {
	type: BodyElementType;
	content: string;
};
export function bodyElementValidator(bodyElement: Record<string, unknown>): bodyElement is BodyElement {
	const type = typeof bodyElement.type == "string" && (bodyElementType as readonly string[]).includes(bodyElement.type);
	const content = typeof bodyElement.content == 'string';
	return type && content;
}
