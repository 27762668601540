import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.type({
		state: s.string(),
	}),
	query: EmptyObjectStruct,
	body: s.object({
		code: s.string(),
	}),
};

export const FinishOAuthResponseStruct = s.unknown();
export type FinishOAuthResponse = s.Infer<typeof FinishOAuthResponseStruct>;

export type FinishOAuthMethodParams =
		s.Infer<typeof ApiParamStructs.path> &
		s.Infer<typeof ApiParamStructs.body>;

export function finishOAuth(params: FinishOAuthMethodParams): APIRequest {
	const { state, code } = params;
	return createAPIRequest(
		'post',
		uri`/api/yahoo/oauth/${state}`,
		{
			path: { state },
			body: { code },
		},
		ApiParamStructs,
	);
}
export default finishOAuth;
