interface IllustInfo {
	id: string;
	type: 'illust' | 'kamon' | 'maru';
	name: string;
	sku: string;
}

export const ILLUST_INFO: readonly IllustInfo[] = [
	{ type: 'illust', id: "MG1", name: "[デザイン:桜]", sku: "00026038" },
	{ type: 'illust', id: "MG2", name: "[デザイン:梅]", sku: "00026037" },
	{ type: 'illust', id: "MG3", name: "[デザイン:まる-雛-]", sku: "00025997" },
	{ type: 'illust', id: "MG4", name: "[デザイン:まる-端午-]", sku: "00025997" },
	{ type: 'illust', id: "MG5", name: "[デザイン:まる-無地-]", sku: "00025997" },
	{ type: 'illust', id: "MG6", name: "[デザイン:しかく-雛-]", sku: "00026036" },
	{ type: 'illust', id: "MG7", name: "[デザイン:しかく-端午-]", sku: "00026036" },
	{ type: 'illust', id: "MG8", name: "[デザイン:しかく-無地-]", sku: "00026036" },
] as const;

export function findIllust(id: string): IllustInfo | undefined {
	return ILLUST_INFO.find((illust) => illust.id === id);
}
