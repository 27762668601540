import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: s.object({
		shopId: s.string(),
	}),
	query: s.object({
		itemName: s.string(),
		itemPriceFrom: s.optional(s.number()),
		itemPriceTo: s.optional(s.number()),
	}),
	body: EmptyObjectStruct
};

const SearchItemsResponseValueStruct = s.type({
	manageNumber: s.string(),
	itemNumber: s.optional(s.string()),
	title: s.string(),
});
export const SearchItemsResponseStruct = s.array(
	SearchItemsResponseValueStruct
);
export type SearchItemsResponse = s.Infer<typeof SearchItemsResponseStruct>;
export type SearchItemsResponseValues = s.Infer<typeof SearchItemsResponseValueStruct>;

export type SearchItemsMethodParams = {
	shopId: string;
	itemName: string;
	itemPriceFrom?: number;
	itemPriceTo?: number;
};

export function searchItems(params: SearchItemsMethodParams): APIRequest {
	const { shopId, ...query } = params;
	return createAPIRequest(
		'get',
		uri`/api/yahoo/${shopId}/items`,
		{
			path: { shopId },
			query: { ...query },
		},
		ApiParamStructs
	);
}
