import * as s from 'superstruct';
import { APIRequest, createAPIRequest } from '../api';
import { EmptyObjectStruct, UserStruct } from '../structs';
import { uri } from '../uri';

const ApiParamStructs = {
	path: EmptyObjectStruct,
	query: EmptyObjectStruct,
	body: EmptyObjectStruct
};

export const GetTwoFactorAuthResponseStruct = s.boolean();
export type GetTwoFactorAuthResponse = s.Infer<typeof GetTwoFactorAuthResponseStruct>;
export type GetTwoFactorAuthResponseValues = s.Infer<typeof UserStruct>;

export type GetTwoFactorAuthMethodParams = null;

export function getTwoFactorAuth(): APIRequest {
	return createAPIRequest(
		'get',
		uri`/api/user/two-factor-auth`,
		{},
		ApiParamStructs
	);
}
