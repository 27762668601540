export interface PaginatedList<T> {
	pageInfo: PageInfo;
	data: T[];
}

export interface PageInfo {
	page: number;	// current page
	pages: number;	// total number of pages
	perPage: number;
	recordsTotal: number;	// full data set length
	hasNextPage: boolean;
	hasPrevPage: boolean;
}

export function getPageInfo(perPage: number, page: number, total: number): PageInfo {
	const pages =  Math.ceil(total / perPage);
	const next = page + 1;
	const prev = page - 1;
	return {
		page: page,
		pages,
		perPage: perPage,
		recordsTotal: total,
		hasNextPage: 1 < next && next <= pages,
		hasPrevPage: 1 <= prev && prev < pages,
	}
}

export function getPageInfoAll(total: number): PageInfo {
	return getPageInfo(Infinity, 1, total);
}

export function getPageInfoEmpty(perPage: number): PageInfo {
	return getPageInfo(perPage, 1, 0);
}

export type PageParams = {
	pagination?: number;	// true(1) or false(undefined)
	page?: number;
	perPage?: number;
}
