import Model from "./Model";
import { validate, NIL } from 'uuid';
import ObjectAssert from "../ObjectAssert";
import { UUID } from "../interface";

function uuidValidator(uuid: string): uuid is UUID {
	return validate(uuid);
}

type EntityConstructor<T extends Entity> = {
	new(obj: unknown): T;
};

export default abstract class Entity extends Model {
	static typeId: UUID = NIL;

	static create<T extends Entity>(this: EntityConstructor<T>, obj: unknown): T {
		// TODO: typeIdで判定して適切なコンストラクタを実行する
		return new this(obj);
	}

	#id: string;

	constructor(obj: unknown) {
		super();

		const assert = new ObjectAssert(obj);
		this.#id = assert.get('id', { isMandatory:true, type: 'string', validator: uuidValidator });
	}

	get id(): string {
		return this.#id;
	}

	get typeId(): UUID {
		return (this.constructor as typeof Entity).typeId;
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			id: this.#id,
		});
	}
}