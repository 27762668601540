import { entityIdMap } from "../../const/entity-id-map";
import { ExcludeMethod } from '../../lib';
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export class Orderer extends Entity {
	static typeId = entityIdMap['review.orderer'];

	ordererMail!: string;
	ordererName!: string;
	ordererSex?: string;
	ordererAge?: number;
	ordererPref!: string;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			ordererMail: { isMandatory: true, type: 'string' },
			ordererName: { isMandatory: true, type: 'string' },
			ordererSex: { type: 'string' },
			ordererAge: { type: 'number' },
			ordererPref: { isMandatory: true, type: 'string' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			ordererMail: this.ordererMail,
			ordererName: this.ordererName,
			ordererSex: this.ordererSex,
			ordererAge: this.ordererAge,
			ordererPref: this.ordererPref,
		});
	}
}
export type OrdererAttr = ExcludeMethod<Orderer>;
