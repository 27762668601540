import { Infer, literal, record, string } from "superstruct";
import { ItemGroup } from '../../';
import { entityIdMap } from "../../const/entity-id-map";
import { isRecord } from "../../isRecord";
import keyValidator from "../../keyValidator";
import { ExcludeMethod, throwExp, validator } from '../../lib';
import ObjectAssert from "../../ObjectAssert";
import Entity from "../Entity";

export abstract class Reward extends Entity {
	static typeId = entityIdMap['review.reward'];

	static create(obj: { isCoupon: true }): CouponReward;
	static create(obj: { isCoupon?: false }): ItemReward;
	static create(obj: unknown): CouponReward | ItemReward;
	static create(obj: unknown): CouponReward | ItemReward {
		if (!isRecord(obj)) {
			throw null;
		}
		if (obj.isCoupon) {
			return new CouponReward(obj);
		} else {
			return new ItemReward(obj);
		}
	}

	name!: string;
	description!: string;
	isCoupon!: boolean;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			name: { isMandatory: true, type: 'string' },
			description: { isMandatory: true, type: 'string' },
			isCoupon: { isMandatory: true, type: 'boolean' },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			name: this.name,
			description: this.description,
			isCoupon: this.isCoupon,
		});
	}

	isAvailable(): boolean {
		return true;
	}
}

const csvUserSettingsStruct = record(string(), record(string(), string()));
const isCsvClientType = keyValidator(['orderer', 'user']);

export class ItemReward extends Reward {
	image!: string;
	quantity?: number;
	isUnlimited!: boolean;
	csvFormat!: string;
	/**
	 * 元の注文で注文者と送り先が異なるとき、送り状CSVの発送元に記載する内容
	 * orderer => 元の注文の注文者
	 * user => ユーザ設定値(店舗住所等を設定しているはず)
	 */
	csvClientType!: string;
	csvUserSettings!: Infer<typeof csvUserSettingsStruct>;
	optionAttributes?: Record<string, unknown>;

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			isCoupon: { isMandatory: true, validator: validator(literal(false)) },
			image: { isMandatory: true, type: 'string' },
			quantity: { isMandatory: false, type: 'number' },
			isUnlimited: { isMandatory: true, type: 'boolean' },
			csvFormat: { isMandatory: true, type: 'string' },
			csvClientType: {isMandatory: true, validator: isCsvClientType },
			csvUserSettings: { isMandatory: true, validator: validator(csvUserSettingsStruct) },
			optionAttributes: { validator: isRecord },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			isCoupon: this.isCoupon,
			image: this.image,
			quantity: this.quantity,
			isUnlimited: this.isUnlimited,
			csvFormat: this.csvFormat,
			csvClientType: this.csvClientType,
			csvUserSettings: this.csvUserSettings,
			optionAttributes: this.optionAttributes,
		});
	}

	isAvailable(): boolean {
		return this.isUnlimited || (this.quantity ?? 0) > 0;
	}
}

export const VALID_PERIOD_MIN: Record<string, number> = {
	'individual': 1,
	'daily': 2,
	'weekly': 8,
	'monthly': 32,
};

export class CouponReward extends Reward {
	issueFreq!: string;
	discountType!: string;
	discountPrice?: number;
	discountRate?: number;
	validPeriod!: string;
	validDays!: number;
	isDisplay!: boolean;
	couponImageType!: string;
	template?: string;
	userCouponImage?: string;
	cabinetImageUrl?: string;
	isUsingUnlimited!: boolean;
	usingLimit?: number;
	isIssueUnlimited!: boolean;
	issueLimit?: number;
	canCombineUse!: boolean;
	conditionType!: string;
	conditionPrice?: number;
	conditionAmount?: number;
	couponItemGroup?: ItemGroup;
	conditionRankType!: string;
	conditionRankCond?: [];
	purchaseHistoryCond!: string;
	dynamicPeriod?: number;
	purchaseCountLower?: number;
	purchaseCountUpper?: number;
	genderCond!: string;
	ageRangeCond!: string;
	ageRangeCondLower?: number;
	ageRangeCondUpper?: number;
	birthMonthCond!: string;
	birthMonth?: number;
	multiPrefectureCond!: string;
	prefectureCond?: [];

	constructor(obj: unknown) {
		super(obj);

		const assert = new ObjectAssert(obj);
		assert.assign(this, {
			isCoupon: { isMandatory: true, validator: validator(literal(true)) },
			issueFreq: { isMandatory: true, type: 'string',
				validator: keyValidator(['individual', 'daily', 'weekly', 'monthly'])
			},
			discountType: { isMandatory: true, type: 'string' },
			discountPrice: { isMandatory: false, type: 'number' },
			discountRate: { isMandatory: false, type: 'number' },
			validPeriod: { isMandatory: true, type: 'string',
				validator: keyValidator(['days', 'nextMonth', 'secondNextMonth'])
			},
			validDays: { isMandatory: true, type: 'number', validator: (val: number, obj: Record<string, unknown>): val is number => {
				if (obj.validPeriod !== 'days') {
					return true;
				}
				const issueFreq = String(obj.issueFreq);
				return val >= (VALID_PERIOD_MIN[issueFreq] ?? throwExp(`不明なissueFreq(${issueFreq})`));
			}},
			isDisplay: { isMandatory: true, type: 'boolean' },
			couponImageType: { isMandatory: true, type: 'string' },
			template: { isMandatory: false, type: 'string' },
			userCouponImage: { isMandatory: false, type: 'string' },
			cabinetImageUrl: { isMandatory: false, type: 'string' },
			isUsingUnlimited: { isMandatory: true, type: 'boolean' },
			usingLimit: { isMandatory: false, type: 'number' },
			isIssueUnlimited: { isMandatory: true, type: 'boolean' },
			issueLimit: { isMandatory: false, type: 'number' },
			canCombineUse: { isMandatory: true, type: 'boolean' },
			conditionType: { isMandatory: true, type: 'string' },
			conditionPrice: { isMandatory: false, type: 'number' },
			conditionAmount: { isMandatory: false, type: 'number' },
			couponItemGroup: { isMandatory: false, type: 'object' },
			conditionRankType: { isMandatory: true, type: 'string' },
			conditionRankCond: { isMandatory: false, type: 'object' },
			purchaseHistoryCond: { isMandatory: true, type: 'string' },
			dynamicPeriod: { isMandatory: false, type: 'number' },
			purchaseCountLower: { isMandatory: false, type: 'number' },
			purchaseCountUpper: { isMandatory: false, type: 'number' },
			genderCond: { isMandatory: true, type: 'string' },
			ageRangeCond: { isMandatory: true, type: 'string' },
			ageRangeCondLower: { isMandatory: false, type: 'number' },
			ageRangeCondUpper: { isMandatory: false, type: 'number' },
			birthMonthCond: { isMandatory: true, type: 'string' },
			birthMonth: { isMandatory: false, type: 'number' },
			multiPrefectureCond: { isMandatory: true, type: 'string' },
			prefectureCond: { isMandatory: false, type: 'string', isArray: true },
		});
	}

	toJSON(): Record<string, unknown> {
		return Object.assign(super.toJSON(), {
			isCoupon: this.isCoupon,
			issueFreq: this.issueFreq,
			discountType: this.discountType,
			discountPrice: this.discountPrice,
			discountRate: this.discountRate,
			validPeriod: this.validPeriod,
			validDays: this.validDays,
			isDisplay: this.isDisplay,
			couponImageType: this.couponImageType,
			template: this.template,
			userCouponImage: this.userCouponImage,
			cabinetImageUrl: this.cabinetImageUrl,
			isUsingUnlimited: this.isUsingUnlimited,
			usingLimit: this.usingLimit,
			isIssueUnlimited: this.isIssueUnlimited,
			issueLimit: this.issueLimit,
			canCombineUse: this.canCombineUse,
			conditionType: this.conditionType,
			conditionPrice: this.conditionPrice,
			conditionAmount: this.conditionAmount,
			couponItemGroup: this.couponItemGroup,
			conditionRankType: this.conditionRankType,
			conditionRankCond: this.conditionRankCond,
			purchaseHistoryCond: this.purchaseHistoryCond,
			dynamicPeriod: this.dynamicPeriod,
			purchaseCountLower: this.purchaseCountLower,
			purchaseCountUpper: this.purchaseCountUpper,
			genderCond: this.genderCond,
			ageRangeCond: this.ageRangeCond,
			ageRangeCondLower: this.ageRangeCondLower,
			ageRangeCondUpper: this.ageRangeCondUpper,
			birthMonthCond: this.birthMonthCond,
			birthMonth: this.birthMonth,
			multiPrefectureCond: this.multiPrefectureCond,
			prefectureCond: this.prefectureCond
		});
	}
}

export type RewardAttr = ExcludeMethod<Reward>;
